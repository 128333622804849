//Imports
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//Import Actions
import { updateUser, readUser, deleteUser } from '../actions/users';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import EditUserForm from '../components/Forms/EditUserForm';

const UpdateTechnician = ({match}) => {
    
    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
    });

    const { name, email, password, role } = values;

    const history = useHistory();

    useEffect(() => {
        loadRequest();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readUser(token, match.params.userId);
        setValues({ ...values, ...res.data[0] });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                name: name,
                email: email,
                password: password,
                role: role,
            }

            const res = await updateUser(token,match.params.userId, data);

            console.log("User Updated", res);

            history.push(`/technicians`);

        } catch (err) {
            console.log(err);
        }
    }

    const handleDelete = async () => {
        try {
            if (!window.confirm('Are you sure?')) return;
            let res = await deleteUser(token, match.params.userId);
            console.log("User Deleted:", res);

            history.push('/technicians');

        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleSelect = (value) => {
        setValues({ ...values, role: value });
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`Update Technician ${values.name}`} />
                        <EditUserForm values={values} handleChange={handleChange} handleSubmit={handleSubmit} handleSelect={handleSelect} handleDelete={handleDelete} />
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default UpdateTechnician;