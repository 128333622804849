//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//Import Actions
import { readStores } from '../actions/stores';
import { readUsers } from '../actions/users';
import {updateCarrierReport, readCarrierReport} from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import EditCarrierForm from '../components/Forms/EditCarrierForm';

const UpdateReportCarrier = ({match}) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [values, setValues] = useState({
        pelatis: "",
        diefthinsi: "",
        thl: "",
        kwdikosKatastimatos: "",
        entoliPelati: "",
        onomatepwnymo: "",
        thlefwno: "",
        ypokatastima: "",
        kentriko: "",
        proforiki: "",
        thlefwnikh: "",
        grapth: "",
        arGnostopoihshs: "",
        arMitroou: "",
        entoliAgoras: "",
        wraKlhshs: "",
        wraMetavashs: "",
        wraAfikshs: "",
        diarkeiaErgasias: "",
        wraAnaxwrhshs: "",
        wraEpistrofhs: "",
        kmMetavashs: "",
        kmEpistrofhs: "",
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        eggyhsh: "",
        xrewsh: "",
        aneuXrewshs: "",
        periodikoService: "",
        symvolaio: "",
        egkatastash: "",
        egkPwlhsh: "",
        anakainish: "",
        loipesErgasies: "",
        perigrafiVlavis: "",
        astoxiaSwlhna: "",
        xalarosSyndesmos: "",
        diatrhtosEksatmisths: "",
        diatrhtosSymphknwths: "",
        astoxiaSyggolhshs: "",
        kakhSterewsh: "",
        vraxykyklwmenhAntistash: "",
        vraxykyklwmenosAnemisthras: "",
        vlavhH_Arruthmistos: "",
        kamenesAsfaleies: "",
        VraxykyklwmenoRele: "",
        thermostathsXwrou: "",
        hlektronikosThermostaths: "",
        magnhtikhValvida: "",
        ektonwtikhValvida: "",
        thermostathsAsfaleias: "",
        klixonPortas: "",
        epidrashApoKlimatismo: "",
        epidrashApoReuma: "",
        apoxeteushKleisth: "",
        antistashApoxeteushs: "",
        pesmenhH_Xalasmenh: "",
        apwleiaFashs: "",
        ptwshH_Ypervash: "",
        thermomagnhtikos: "",
        vraxykyklwmenoRele2: "",
        hl_Prostasia: "",
        piessostaths: "",
        piessostaths2: "",
        antliaLadiou: "",
        elleipshLadiou: "",
        valvidaAforths: "",
        akatharisto: "",
        condenser: "",
        vraxykyklSymp: "",
        antikatastSymp: "",
        provlhmaDiktyo: "",
        provlhmaPLC: "",
        piesYpshlhs: "",
        piesXamhlhs: "",
        elleipshFreon3: "",
        elleipshLadiou3: "",
        akathElaio: "",
        akathCondenser: "",
        vraxyAnem: "",
        dysmeneis: "",
        allo0: "",
        perigrafiErgasion: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    const [users, setUsers] = useState([]);
    const [stores, setStores] = useState([]);

    const { pelatis, diefthinsi, thl, kwdikosKatastimatos, entoliPelati, onomatepwnymo, thlefwno, ypokatastima, kentriko, proforiki, thlefwnikh, grapth, arGnostopoihshs, arMitroou, entoliAgoras, wraKlhshs, wraMetavashs, wraAfikshs, diarkeiaErgasias, wraAnaxwrhshs, wraEpistrofhs, kmMetavashs, kmEpistrofhs, ergasia1, ergasia2, ergasia3, eggyhsh, xrewsh, aneuXrewshs, periodikoService, symvolaio, egkatastash, egkPwlhsh, anakainish, loipesErgasies, perigrafiVlavis, astoxiaSwlhna, xalarosSyndesmos, diatrhtosEksatmisths, diatrhtosSymphknwths, astoxiaSyggolhshs, kakhSterewsh, vraxykyklwmenhAntistash, vraxykyklwmenosAnemisthras, vlavhH_Arruthmistos, kamenesAsfaleies, VraxykyklwmenoRele, thermostathsXwrou, hlektronikosThermostaths, magnhtikhValvida, ektonwtikhValvida, thermostathsAsfaleias, klixonPortas, epidrashApoKlimatismo, epidrashApoReuma, apoxeteushKleisth, antistashApoxeteushs, pesmenhH_Xalasmenh, apwleiaFashs, ptwshH_Ypervash, thermomagnhtikos, vraxykyklwmenoRele2, hl_Prostasia, piessostaths, piessostaths2, antliaLadiou, elleipshLadiou, valvidaAforths, akatharisto, condenser, vraxykyklSymp, antikatastSymp, provlhmaDiktyo, provlhmaPLC, piesYpshlhs, piesXamhlhs, elleipshFreon3, elleipshLadiou3, akathElaio, akathCondenser, vraxyAnem, dysmeneis, allo0, perigrafiErgasion, date, status, ypografi1, ypografi2, ypografi3 } = values;

    const history = useHistory();

    useEffect(() => {
        const loadAllStores = async () => {
            let res = await readStores(token);
            setStores(res.data);
        };

        loadAllStores();
        loadRequest();
        loadAllUsers();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readCarrierReport(match.params.reportId);
        setValues({ ...values, ...res.data[0] });
    }

    const loadAllUsers = async () => {
        let res = await readUsers(token);
        setUsers(res.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const data = {
                pelatis: pelatis,
                diefthinsi: diefthinsi,
                thl: thl,
                kwdikosKatastimatos: kwdikosKatastimatos,
                entoliPelati: entoliPelati,
                onomatepwnymo: onomatepwnymo,
                thlefwno: thlefwno,
                ypokatastima: ypokatastima,
                kentriko: kentriko,
                proforiki: proforiki,
                thlefwnikh: thlefwnikh,
                grapth: grapth,
                arGnostopoihshs: arGnostopoihshs,
                arMitroou: arMitroou,                
                entoliAgoras: entoliAgoras,                
                wraKlhshs: wraKlhshs,
                wraMetavashs: wraMetavashs,
                wraAfikshs: wraAfikshs,
                diarkeiaErgasias: diarkeiaErgasias,
                wraAnaxwrhshs: wraAnaxwrhshs,
                wraEpistrofhs: wraEpistrofhs,
                kmMetavashs: kmMetavashs,
                kmEpistrofhs: kmEpistrofhs,
                ergasia1: ergasia1,
                ergasia2: ergasia2,
                ergasia3: ergasia3,
                eggyhsh: eggyhsh,
                xrewsh: xrewsh,
                aneuXrewshs: aneuXrewshs,
                periodikoService: periodikoService,
                symvolaio: symvolaio,
                egkatastash: egkatastash,
                egkPwlhsh: egkPwlhsh,
                anakainish: anakainish,
                loipesErgasies: loipesErgasies,
                perigrafiVlavis: perigrafiVlavis,
                astoxiaSwlhna: astoxiaSwlhna,
                xalarosSyndesmos: xalarosSyndesmos,
                diatrhtosEksatmisths: diatrhtosEksatmisths,
                diatrhtosSymphknwths: diatrhtosSymphknwths,
                astoxiaSyggolhshs: astoxiaSyggolhshs,
                kakhSterewsh: kakhSterewsh,
                vraxykyklwmenhAntistash: vraxykyklwmenhAntistash,
                vraxykyklwmenosAnemisthras: vraxykyklwmenosAnemisthras,
                vlavhH_Arruthmistos: vlavhH_Arruthmistos,
                kamenesAsfaleies: kamenesAsfaleies,
                VraxykyklwmenoRele: VraxykyklwmenoRele,
                thermostathsXwrou: thermostathsXwrou,
                hlektronikosThermostaths: hlektronikosThermostaths,
                magnhtikhValvida: magnhtikhValvida,
                ektonwtikhValvida: ektonwtikhValvida,
                thermostathsAsfaleias: thermostathsAsfaleias,
                klixonPortas: klixonPortas,
                epidrashApoKlimatismo: epidrashApoKlimatismo,
                epidrashApoReuma: epidrashApoReuma,
                apoxeteushKleisth: apoxeteushKleisth,
                antistashApoxeteushs: antistashApoxeteushs,
                pesmenhH_Xalasmenh: pesmenhH_Xalasmenh,
                apwleiaFashs: apwleiaFashs,
                ptwshH_Ypervash: ptwshH_Ypervash,
                thermomagnhtikos: thermomagnhtikos,
                vraxykyklwmenoRele2: vraxykyklwmenoRele2,
                hl_Prostasia: hl_Prostasia,
                piessostaths: piessostaths,
                piessostaths2: piessostaths2,
                antliaLadiou: antliaLadiou,
                elleipshLadiou: elleipshLadiou,
                valvidaAforths: valvidaAforths,
                akatharisto: akatharisto,
                condenser: condenser,
                vraxykyklSymp: vraxykyklSymp,
                antikatastSymp: antikatastSymp,
                provlhmaDiktyo: provlhmaDiktyo,
                provlhmaPLC: provlhmaPLC,
                piesYpshlhs: piesYpshlhs,
                piesXamhlhs: piesXamhlhs,
                elleipshFreon3: elleipshFreon3,
                elleipshLadiou3: elleipshLadiou3,
                akathElaio: akathElaio,
                akathCondenser: akathCondenser,
                vraxyAnem: vraxyAnem,
                dysmeneis: dysmeneis,
                allo0: allo0,
                perigrafiErgasion: perigrafiErgasion,
                date: date,
                status: status,
                ypografi1: ypografi1,
                ypografi2: ypografi2,
                ypografi3: ypografi3,
            }

            const res = await updateCarrierReport(token, match.params.reportId, data);
            console.log("Carrier Report Updated", res);

            history.push(`/readCarrierReport/${match.params.reportId}`)
            // setTimeout(()=>{
            //     window.location.reload();
            // }, 2000);

        } catch (err) {
            console.log(err);
        }
    }
    const handleDate = (dates, dateStrings) => {
        setValues({ ...values, date: dateStrings });
    }
    const handleWraKlhshs = (time, timeString) => {
        setValues({ ...values, wraKlhshs: timeString });
    }
    const handleWraMetavashs = (time, timeString) => {
        setValues({ ...values, wraMetavashs: timeString });
    }
    const handleWraAfikshs = (time, timeString) => {
        setValues({ ...values, wraAfikshs: timeString });
    }
    const handleDiarkeiaErgasias = (time, timeString) => {
        setValues({ ...values, diarkeiaErgasias: timeString });
    }
    const handleWraAnaxwrhshs = (time, timeString) => {
        setValues({ ...values, wraAnaxwrhshs: timeString });
    }
    const handleWraEpistrofhs = (time, timeString) => {
        setValues({ ...values, wraEpistrofhs: timeString });
    }
    const handleSelect = (value) => {
        setValues({ ...values, status: value });
    }
    const handleSelectKatastima = (value) => {
        setValues({ ...values, pelatis: value });
    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    
    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`Update Report ID: ${match.params.reportId}`} />
                        <EditCarrierForm values={values} setValues={setValues} users={users} stores={stores} auth={auth}  handleSelectKatastima={handleSelectKatastima} handleChange={handleChange} handleWraKlhshs={handleWraKlhshs} handleWraMetavashs={handleWraMetavashs} handleWraAfikshs={handleWraAfikshs} handleDiarkeiaErgasias={handleDiarkeiaErgasias} handleWraAnaxwrhshs={handleWraAnaxwrhshs} handleWraEpistrofhs={handleWraEpistrofhs} handleSelect={handleSelect} handleDate={handleDate} handleSubmit={handleSubmit} match={match}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default UpdateReportCarrier;