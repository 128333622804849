//Imports
import carrierLogo from '../../dist/images/carrier.png';
import { TimePicker, DatePicker, Select } from 'antd';

const format = 'HH:mm';
const { Option } = Select;

const CarrierForm = (props) => {

    const { handleSubmit, handleDate, handleSelect, handleSelectKatastima, handleChange, handleWraKlhshs, handleWraMetavashs, handleWraAfikshs, handleDiarkeiaErgasias, handleWraAnaxwrhshs, handleWraEpistrofhs } = props;
    const { users, stores, auth, values, setValues } = props;
    return (
        <>
            <form onSubmit={handleSubmit} className="mt-3 p-3">

                <div className="row g-3 mb-5">
                    <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-nowrap">
                        <img className="formLogo" src={carrierLogo} alt="CarrierLogo" />
                        <h2 className="formTitle">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                    </div>
                </div>

                {/* ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row py-2 text-center">
                        <h3>ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ</h3>
                    </div>
                    <div className="row py-2">
                        <div className="col-xl-5">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Πελάτης:</label>
                                </div>
                                <div className="col-lg-7">
                                    <Select placeholder="Κατάστημα" style={{ width: '100%' }} onChange={handleSelectKatastima}>
                                        {stores.map(store => {
                                            return (
                                                <Option key={store.id} value={`${store.name}`}>{store.name}</Option>
                                            )
                                        })}
                                    </Select>
                                    {/* <input name="pelatis" type="text" className="form-control" placeholder="Πελάτης" onChange={handleChange} /> */}
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Διεύθηνση:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="diefthinsi" type="text" className="form-control" placeholder="Διεύθηνση" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Τηλ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="thl" type="text" className="form-control" placeholder="Τηλέφωνο" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Κωδ. Καταστήματος:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="kwdikosKatastimatos" type="text" className="form-control" placeholder="Κωδ. Καταστήματος" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Εντολή Πελάτη:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="entoliPelati" type="text" className="form-control" placeholder="Εντολή Πελάτη" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Ονοματεπώνυμο:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="onomatepwnymo" type="text" className="form-control" placeholder="Ονοματεπώνυμο" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">Τηλέφωνο:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="thlefwno" type="text" className="form-control" placeholder="Τηλέφωνο" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-12">
                                    <div className="form-check form-check-inline mx-5">
                                        <input className="form-check-input" name="ypokatastima" type="checkbox" onChange={handleChange}></input>
                                        <label className="form-check-label" >Υποκατάστημα</label>
                                    </div>
                                    <div className="form-check form-check-inline mx-5">
                                        <input className="form-check-input" name="kentriko" type="checkbox" onChange={handleChange}></input>
                                        <label className="form-check-label" >Κεντρικό</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 my-1">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="proforiki" type="checkbox" onChange={handleChange}></input>
                                        <label className="form-check-label" >Προφορική</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="thlefwnikh" type="checkbox" onChange={handleChange}></input>
                                        <label className="form-check-label" >Τηλεφωνική</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="grapth" type="checkbox" onChange={handleChange}></input>
                                        <label className="form-check-label" >Γραπτή</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                    <div className="row pt-2 pb-2 mb-5">
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="arGnostopoihshs" type="text" className="form-control" placeholder="Αρ. Γνωστοποίησης/Ανάθεσης" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-3">
                                    <label className="form-label">Αρ. Μητρώου:</label>
                                </div>
                                <div className="col-xl-9">
                                    <input name="arMitroou" type="text" className="form-control" placeholder="Αρ. Μητρώου" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Εντολή Αγοράς:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="entoliAgoras" type="text" className="form-control" placeholder="Εντολή Αγοράς" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Ώρα Κλήσης:</label>
                            <TimePicker name="wraKlhshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraKlhshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Ώρα Μετάβασης:</label>
                            <TimePicker name="wraMetavashs" placeholder="Επιλέξτε Ώρα" onChange={handleWraMetavashs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Ώρα Άφιξης:</label>
                            <TimePicker name="wraAfikshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraAfikshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Διάρκεια Εργασίας:</label>
                            <TimePicker name="diarkeiaErgasias" placeholder="Επιλέξτε Ώρα" onChange={handleDiarkeiaErgasias} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Ώρα Αναχώρησεις:</label>
                            <TimePicker name="wraAnaxwrhshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraAnaxwrhshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Ώρα Επιστροφής:</label>
                            <TimePicker name="wraEpistrofhs" placeholder="Επιλέξτε Ώρα" onChange={handleWraEpistrofhs} format={format} />
                        </div>
                        <div className="col-xxl-2 m-auto">
                            <label className="form-label m-3">Ημ/νία:</label>
                            <DatePicker name="date" placeholder="Επιλέξτε Ημ/νία" onChange={handleDate} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Km Μετάβασης:</label>
                            <input name="kmMetavashs" type="text" className="form-control mb-2" placeholder="Km Μετάβασης" onChange={handleChange} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Km Επιστροφής:</label>
                            <input name="kmEpistrofhs" type="text" className="form-control mb-2" placeholder="Km Επιστροφής" onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 1:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia1: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 2:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia2: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 3:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia3: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                    </div>


                    {/* CHECKBOXES FEATURE ONLY FOR ADMIN */}
                    {auth.role === 'Admin' &&
                        <div className="row my-2">
                            <div className="col-lg-12 text-center">
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="eggyhsh" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Εγγύηση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="xrewsh" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Χρέωση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="aneuXrewshs" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Άνευ Χρέωσης</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="periodikoService" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Περιοδικό Service</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="symvolaio" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Συμβόλαιο</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="egkatastash" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Εγκατάσταση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="egkPwlhsh" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Εγκ./Πώληση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="anakainish" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Ανακαίνιση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="loipesErgasies" type="checkbox" onChange={handleChange} ></input>
                                    <label className="form-check-label" >Λοιπές Εργασίες</label>
                                </div>
                            </div>
                        </div>
                    }

                    {/* ΤΥΠΟΣ ΨΥΓΕΙΟΥ 
                    <div className="row my-2 m-auto p-2 border border-1 border-dark">
                        <div className="col-lg-2 text-center">
                            <label className="form-check-label" >Τύπος Ψυγείου:</label>
                        </div>
                        <div className="col-lg-10 text-center">
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" name="zeta" ></input>
                                <label className="form-check-label" >ZETA</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ARES</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ZEUS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MESIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MONAXIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ARF</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ARM</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MAXIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >AIB</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >AIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >NACRE</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >THETIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MILAS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >WISALO</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >IRIOS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >TOPAZE</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ATR</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >VANTIS</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >VELANDO</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΚΡΕΟΠ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΠΑΡΑΣΚ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΣΥΝΤ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΚΑΤΑΨ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >OPTIMER</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΦΡΟΥΤ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >Θ. ΑΝΟΙΚΤ.</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ΠΑΓΚΟΣ</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ΠΑΓΚΟΣ ΦΕΤΑ</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ΕΛΙΕΡΑ</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MULTI (-)</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MULTI (+)</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >MULTI SAT</label>
                            </div>
                            <div className="form-check form-check-inline mx-2">
                                <input className="form-check-input" type="checkbox" ></input>
                                <label className="form-check-label" >ΛΟΙΠΑ:</label>
                            </div>
                        </div>
                    </div>
                    */}

                    {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ */}
                    <div className="row py-2 my-3">
                        <div className="col-xl-2 m-auto">
                            <label className="form-label">Περιγραφή Βλάβης:</label>
                        </div>
                        <div className="col-xl-10 m-auto">
                            <textarea name="perigrafiVlavis" type="text" className="form-control mb-2" placeholder="Περιγραφή Βλάβης" onChange={handleChange} />
                        </div>
                    </div>
                </div>

                {/* ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5">
                    <div className="row p-0 m-auto">
                        <div className="col-md-8 border border-1 border-dark text-center">
                            <label className="form-label">ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ</label>
                        </div>
                        <div className="col-md-4 border border-1 border-dark text-left">
                            <label className="form-label">Δ. Αποστολής</label>
                        </div>
                    </div>

                    {/* 10 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">10</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΕΛΛΕΙΨΗ ΦΡΕΟΝ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="astoxiaSwlhna" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αστοχία σωλήνα <br></br> ή εξαρτήματος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="xalarosSyndesmos" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Χαλαρός σύνδεσμος <br></br> ή εξαρτήματα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="diatrhtosEksatmisths" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Διάτρητος εξατμιστής</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="diatrhtosSymphknwths" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Διάτρητος συμπυκνωτής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="astoxiaSyggolhshs" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αστοχία συγκόλλησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="kakhSterewsh" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Κακή στερέωση δικτύου <br></br> ή εξαρτήματος</label>
                        </div>
                    </div>

                    {/* 20 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">20</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΠΑΓΩΜΕΝΟΣ ΕΞΑΤΜΙΣΤΗΣ ή ΕΞΑΤΜΙΣΤΗΣ ΧΩΡΙΣ ΨΥΞΗ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenhAntistash" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένη αντίσταση</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenosAnemisthras" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένος <br></br> ανεμιστήρας εξατμιστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vlavhH_Arruthmistos" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βλάβη ή αρρύθμιστος <br></br> χρονοδιακόπτης</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="kamenesAsfaleies" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Καμένες ασφάλειες <br></br> αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="VraxykyklwmenoRele" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένο <br></br> ρελέ αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermostathsXwrou" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Θερμοστάτης χώρου <br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="hlektronikosThermostaths" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Ηλεκτρονικός Θερμοστάτης <br></br> χώρου χαλασμένος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="magnhtikhValvida" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Μαγνητική βαλβίδα <br></br> μόνιμα ανοικτή ή κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="ektonwtikhValvida" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Εκτονωτική βαλβίδα <br></br> αρρύθμιστη ή βλάβη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermostathsAsfaleias" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Θερμοστάτης ασφαλείας <br></br> ή κλίξον κόβουν νωρίς</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="klixonPortas" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Κλίξον πόρτας θαλάμου<br></br> χαλασμένο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="epidrashApoKlimatismo" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Επίδραση απο κλιματισμό</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="epidrashApoReuma" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Επίδραση από ρεύμα αέρα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="apoxeteushKleisth" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αποχέτευση κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antistashApoxeteushs" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αντίσταση αποχέτευσης <br></br> βραχ.</label>
                        </div>
                    </div>

                    {/* 30 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">30</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΣΥΜΠΙΕΣΤΗΣ ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πεσμένη ή χαλασμένη ασφάλεια βοηθ. ή κύριου κυκλώμ.</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="apwleiaFashs" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Απώλεια φάσης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="ptwshH_Ypervash" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πτώση ή υπέρβαση ρεύματος</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermomagnhtikos" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Θερμομαγνητικός διακόπτης<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenoRele2" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένο ρελέ<br></br> συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="hl_Prostasia" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Ηλ. προστασία (kriwan)<br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piessostaths" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πιεσσοστάτης υψηλής-χαμηλής<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piessostaths2" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πιεσσοστάτης λαδιού εκτός <br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antliaLadiou" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αντλία λαδιού χαλασμένη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshLadiou" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Έλλειψη λαδιού στο κάρτερ<br></br> του συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="valvidaAforths" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βαλβίδα άφορτης κλείστη</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akatharisto" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="condenser" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένος ανεμιστήρας condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklSymp" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένος συμπιεστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antikatastSymp" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Αντικατάσταση Συμπιεστή</label>
                        </div>
                    </div>

                    {/* 40 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">40</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">MULTI ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="provlhmaDiktyo" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πρόβλημα στο δίκτυο<br></br> ηλεκτροδότησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="provlhmaPLC" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πρόβλημα στο PLC</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piesYpshlhs" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πιεσσοστάτης υψηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piesXamhlhs" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Πιεσσοστάτης χαμηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshFreon3" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Έλλειψη φρέον</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshLadiou3" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Έλλειψη λαδιού στο κεντρικό<br></br> κάρτερ</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akathElaio" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Ακαθάριστος<br></br> ελαιοδιαχωριστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akathCondenser" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxyAnem" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Βραχυκυκλωμένος<br></br> ανεμιστήρας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="dysmeneis" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Δυσμενείς καιρικές συνθήκες</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="allo0" type="checkbox" onChange={handleChange} ></input>
                            <label className="form-label">Άλλο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <label className="form-label"></label>
                        </div>
                    </div>


                </div>


                {/* ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ - ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-2">
                        <label className="form-label">Περιγραφή Εργασιών - Παρατηρήσεις:</label>
                    </div>
                    <div className="col-lg-10">
                        <textarea name="perigrafiErgasion" type="text" className="form-control" placeholder="Περιγραφή Εργασιών - Παρατηρήσεις" onChange={handleChange} />
                    </div>
                </div>

                {/* ΕΙΣΠΡΑΞΗ ΜΕΤΡΗΤΑ ΣΗΜΕΡΑ ΕΥΡΩ
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-6">
                        <label className="form-check-label" >Είσπραξη Μετρητά Σήμερα Ευρώ:</label>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-check form-check-inline mx-2">
                            <label className="form-check-label" >Μετρητά</label>
                            <input className="form-check-input" type="checkbox" ></input>
                        </div>
                        <div className="form-check form-check-inline mx-2">
                            <label className="form-check-label" >Επι Πίστωσει</label>
                            <input className="form-check-input" type="checkbox" ></input>
                        </div>
                    </div>
                </div>
                */}

                <div className="row formBox my-4">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-4 text-center">
                        <label className="form-label">Status</label>
                    </div>
                    <div className="col-lg-2">
                        <Select placeholder="Select Status" style={{ width: 120 }} defaultValue="Active" onChange={handleSelect}>
                            <Option value="Active">Active</Option>
                            {auth.role === 'Admin' &&
                                <Option value="Completed">Completed</Option>
                            }
                            {auth.role === 'Admin' &&
                                <Option value="Invoiced">Invoiced</Option>
                            }
                        </Select>
                    </div>
                </div>
                <div className="row formBox my-4">
                    <div className="col-lg-10"></div>
                    <div className="col-lg-2">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>


                {/* ΥΠΟΓΡΑΦΕΣ 
                <div className="row formBox py-2 g-3 mb-5">
                    <div className="col-md-6 m-auto text-center">
                        <label className="form-label">Υπογραφή Τεχνικού</label>
                    </div>
                    <div className="col-md-6 m-auto text-center">
                        <label className="form-label">Υπογραφή Πελάτου / Σφραγίδα</label>
                    </div>
                </div>

                <div className="row formBox py-2 g-3 mb-5">
                    <div className="col-md-6 m-auto">
                        <label className="form-label">Σήμερα εισπράξαμε το παραπάνω ποσό. Το σχετικό τιμολόγιο θα σας σταλεί ταχυδρομικά εντός μηνός.</label>
                    </div>
                    <div className="col-md-6 m-auto text-center">
                        <label className="form-label">Ο Λαβών</label>
                    </div>
                </div>
                */}

            </form>
        </>
    )
}

export default CarrierForm;