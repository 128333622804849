import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';


const PrivateLogin = ({...rest}) => {

    const {auth} = useSelector((state) => ({...state}));

    return auth && auth.token ?  <Redirect to="/dashboard"/> : <Route {...rest} />  ;
};

export default PrivateLogin;