//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//import {readCarrierABReport, readCarrierReport, readCarrierABReport, readDelphisReport} from '../actions/reports';
import { readCarrierABReport, deleteCarrierABReport } from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import CarrierABReport from '../components/admin/CarrierABReport';

const SingleCarrierABReport = ({ match }) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const history = useHistory();

    const [values, setValues] = useState({
        katastima: "",
        arGnostopoihshs: "", 
        arMitroou: "",
        entoliAgoras: "",
        perigrafiVlavis: "",
        iliko1: "",
        iliko2: "",
        iliko3: "",
        kostosMonadas1: "",
        kostosMonadas2: "",
        kostosMonadas3: "",
        epimetrisi1: "",
        epimetrisi2: "",
        epimetrisi3: "",
        kostos1: "",
        kostos2: "",
        kostos3: "",
        sunolikoKostosIlikon: "",
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        kostosAnaOra1: "",
        kostosAnaOra2: "",
        kostosAnaOra3: "",
        oresErgasias1: "",
        oresErgasias2: "",
        oresErgasias3: "",
        kostosErgasias1: "",
        kostosErgasias2: "",
        kostosErgasias3: "",
        sunolikoKostosErgasias: "",
        alles1: "",
        alles2: "",
        alles3: "",
        allesKostos1: "",
        allesKostos2: "",
        allesKostos3: "",
        allesSinolikoKostos: "",
        sinolikoKostosProFpa: "",
        apo: "",
        eos: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    useEffect(() => {
        loadRequest();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readCarrierABReport(match.params.reportId);
        setValues({ ...values, ...res.data[0] });
    }

    const handleDelete = async () => {
        try {
            if (!window.confirm('Are you sure?')) return;
            let res = await deleteCarrierABReport(token, match.params.reportId);
            console.log("Report Deleted RES:", res);

            setTimeout(() => {
                history.push('/dashboard');
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`CarrierAB Report ID: ${match.params.reportId}`} />
                        <CarrierABReport values={values} match={match} handleDelete={handleDelete}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default SingleCarrierABReport;