import React from 'react'


const Footer = () => {

    let copyYear = new Date().getFullYear();
    return (
        <footer className="footer w-100 py-3 bg_primary">
            <div className="container-fluid h-100 d-flex justify-content-center align-items-center text-center">
                <div className="copyright h-100">
                    <div>Copyright &copy; {copyYear}</div>
                    <div className="">KBS SERVICE S/N: 906801506021</div>
                </div>
            </div>
        </footer>
    );
};

export default Footer