//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//Import Actions
import { readStores } from '../actions/stores';
import { readUsers } from '../actions/users';
import {updateRecoReport, readRecoReport} from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import EditRecoForm from '../components/Forms/EditRecoForm';

const UpdateReportReco = ({match}) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [values, setValues] = useState({
        katastima: "",
        arGnostopoihshs: "",
        arMitroou: "",
        entoliAgoras: "",
        perigrafiVlavis: "",
        iliko1: "",
        iliko2: "",
        iliko3: "",
        kostosMonadas1: 0,
        kostosMonadas2: 0,
        kostosMonadas3: 0,
        epimetrisi1: 0,
        epimetrisi2: 0,
        epimetrisi3: 0,
        kostos1: 0,
        kostos2: 0,
        kostos3: 0,
        sunolikoKostosIlikon: 0,
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        kostosAnaOra1: 0,
        kostosAnaOra2: 0,
        kostosAnaOra3: 0,
        oresErgasias1: 0,
        oresErgasias2: 0,
        oresErgasias3: 0,
        kostosErgasias1: 0,
        kostosErgasias2: 0,
        kostosErgasias3: 0,
        sunolikoKostosErgasias: 0,
        alles1: "",
        alles2: "",
        alles3: "",
        allesKostos1: 0,
        allesKostos2: 0,
        allesKostos3: 0,
        allesSinolikoKostos: 0,
        sinolikoKostosProFpa: 0,
        apo: "",
        eos: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    const [users, setUsers] = useState([]);
    const [stores, setStores] = useState([]);

    const { katastima, arGnostopoihshs, arMitroou, entoliAgoras, perigrafiVlavis, iliko1, iliko2, iliko3, kostosMonadas1, kostosMonadas2, kostosMonadas3, epimetrisi1, epimetrisi2, epimetrisi3, kostos1, kostos2, kostos3, sunolikoKostosIlikon, ergasia1, ergasia2, ergasia3, kostosAnaOra1, kostosAnaOra2, kostosAnaOra3, oresErgasias1, oresErgasias2, oresErgasias3, kostosErgasias1, kostosErgasias2, kostosErgasias3, sunolikoKostosErgasias, alles1, alles2, alles3, allesKostos1, allesKostos2, allesKostos3, allesSinolikoKostos, sinolikoKostosProFpa, apo, eos, date, status, ypografi1, ypografi2, ypografi3 } = values;

    const history = useHistory();

    useEffect(() => {
        const loadAllStores = async () => {
            let res = await readStores(token);
            setStores(res.data);
        };

        loadAllStores();
        loadRequest();
        loadAllUsers();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readRecoReport(match.params.reportId);
        setValues({ ...values, ...res.data[0] });
    }

    const loadAllUsers = async () => {
        let res = await readUsers(token);
        setUsers(res.data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const data = {
                katastima: katastima,
                arGnostopoihshs: arGnostopoihshs, 
                arMitroou: arMitroou,
                entoliAgoras: entoliAgoras,
                perigrafiVlavis: perigrafiVlavis,
                iliko1: iliko1,
                iliko2: iliko2,
                iliko3: iliko3,
                kostosMonadas1: kostosMonadas1,
                kostosMonadas2: kostosMonadas2,
                kostosMonadas3: kostosMonadas3,
                epimetrisi1: epimetrisi1,
                epimetrisi2: epimetrisi2,
                epimetrisi3: epimetrisi3,
                kostos1: kostos1,
                kostos2: kostos2,
                kostos3: kostos3,
                sunolikoKostosIlikon: sunolikoKostosIlikon,
                ergasia1: ergasia1,
                ergasia2: ergasia2,
                ergasia3: ergasia3,
                kostosAnaOra1: kostosAnaOra1,
                kostosAnaOra2: kostosAnaOra2,
                kostosAnaOra3: kostosAnaOra3,
                oresErgasias1: oresErgasias1,
                oresErgasias2: oresErgasias2,
                oresErgasias3: oresErgasias3,
                kostosErgasias1: kostosErgasias1,
                kostosErgasias2: kostosErgasias2,
                kostosErgasias3: kostosErgasias3,
                sunolikoKostosErgasias: sunolikoKostosErgasias,
                alles1: alles1,
                alles2: alles2,
                alles3: alles3,
                allesKostos1: allesKostos1,
                allesKostos2: allesKostos2,
                allesKostos3: allesKostos3,
                allesSinolikoKostos: allesSinolikoKostos,
                sinolikoKostosProFpa: sinolikoKostosProFpa,
                apo: apo,
                eos: eos,
                date: date,
                status: status,
                ypografi1: ypografi1,
                ypografi2: ypografi2,
                ypografi3: ypografi3,
            }

            const res = await updateRecoReport(token, match.params.reportId, data);
            console.log("Reco Report Updated", res);

            history.push(`/readRecoReport/${match.params.reportId}`)
            // setTimeout(()=>{
            //     window.location.reload();
            // }, 2000);

        } catch (err) {
            console.log(err);
        }
    }
    const handleDate = (dates, dateStrings) => {
        setValues({ ...values, date: dateStrings });
    }
    const handleApo = (time, timeString) => {
        setValues({ ...values, apo: timeString });
    }
    const handleEos = (time, timeString) => {
        setValues({ ...values, eos: timeString });
    }
    const handleSelect = (value) => {
        setValues({ ...values, status: value });
    }
    const handleSelectKatastima = (value) => {
        setValues({ ...values, katastima: value });
    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    
    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`Update Report ID: ${match.params.reportId}`} />
                        <EditRecoForm values={values} setValues={setValues} users={users} stores={stores} auth={auth}  handleSelectKatastima={handleSelectKatastima} handleChange={handleChange} handleApo={handleApo} handleEos={handleEos} handleSelect={handleSelect} handleDate={handleDate} handleSubmit={handleSubmit} match={match}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default UpdateReportReco;