//Imports
import axios from 'axios';

//CREATE
export const createStore = async (token, store) =>
    await axios.post(`${process.env.REACT_APP_API}/createstore`, store, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//READ ALL
export const readStores = async (token) =>
    await axios.get(`${process.env.REACT_APP_API}/readstores`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//READ 1
export const readStore = async (token, storeId) =>
    await axios.get(`${process.env.REACT_APP_API}/readstore/${storeId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//UPDATE
export const updateStore = async (token, storeId, store) =>
    await axios.post(`${process.env.REACT_APP_API}/store/edit/${storeId}`, store, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });


//DELETE STORE
export const deleteStore = async (token, storeId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deletestore/${storeId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });