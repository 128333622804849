//Imports
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//Import Actions
import { createUser } from '../actions/users';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import RegisterUserForm from '../components/Forms/RegisterUserForm';

const CreateTechnician = () => {
    
    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;
    const history = useHistory();

    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        role: "",
    });

    const { name, email, password, role } = values;

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = {
                name: name,
                email: email,
                password: password,
                role: role,
            }

            const res = await createUser(token, data);

            console.log("User Created", res);

            setTimeout(() => {
                history.push('/technicians');
                //window.location.reload();
            }, 500);

        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    const handleSelect = (value) => {
        setValues({ ...values, role: value });
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Create Technician" />
                        <RegisterUserForm handleChange={handleChange} handleSubmit={handleSubmit} handleSelect={handleSelect} />
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default CreateTechnician;