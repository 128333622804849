//Imports
import { TimePicker, DatePicker, Select } from 'antd';
//import moment from 'moment';
import recoLogo from '../../dist/images/reco.png';

const format = 'HH:mm';
const { Option } = Select;

const RecoForm = (props) => {
    const { handleSubmit, handleDate, handleSelect, handleSelectKatastima, handleChange, handleApo, handleEos, users, values, setValues, auth,stores } = props;
    const { kostos1, kostos2, kostos3, kostosMonadas1, kostosMonadas2, kostosMonadas3, epimetrisi1, epimetrisi2, epimetrisi3, sunolikoKostosIlikon, sinolikoKostosProFpa } = values;
    const { kostosAnaOra1, kostosAnaOra2, kostosAnaOra3, oresErgasias1, oresErgasias2, oresErgasias3, kostosErgasias1, kostosErgasias2, kostosErgasias3, sunolikoKostosErgasias } = values;
    const { allesKostos1, allesKostos2, allesKostos3, allesSinolikoKostos } = values;


    return (
        <>
            <>
                <form onSubmit={handleSubmit} className="mt-3 p-3">

                    <div className="row g-3 mb-5">
                        <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-nowrap">
                            <img className="formLogo my-2" src={recoLogo} alt="RecoLogo" />
                            <h2 className="formTitle my-2">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                        </div>
                    </div>

                    {/* ΕΝΤΟΛΗ ΣΥΝΤΗΡΗΣΗΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-xl-5">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Κατάστημα:</label>
                                </div>
                                <div className="col-xl-8">
                                    <Select placeholder="Κατάστημα" style={{ width: '100%' }} onChange={handleSelectKatastima}>
                                        {stores.map(store => {
                                            return (
                                                <Option key={store.id} value={`${store.name}`}>{store.name}</Option>
                                            )
                                        })}
                                    </Select>
                                    {/* <input name="katastima" type="text" className="form-control" placeholder="Κατάστημα" onChange={handleChange} /> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-2">
                            <label className="form-label mx-2">Από:</label>
                            <TimePicker name="apo" placeholder="Επιλέξτε Ώρα" onChange={handleApo} format={format} />
                        </div>
                        <div className="col-xl-2">
                            <label className="form-label mx-2">Έως:</label>
                            <TimePicker name="eos" placeholder="Επιλέξτε Ώρα" onChange={handleEos} format={format} />
                        </div>
                        <div className="col-xl-3">
                            <label className="form-label mx-2">Ημ/νια:</label>
                            <DatePicker name="date" placeholder="Επιλέξτε Ημ/νία" onChange={handleDate} />
                        </div>
                    </div>

                    {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="arGnostopoihshs" type="text" className="form-control" placeholder="Αρ. Γνωστοποίησης/Ανάθεσης" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-3">
                                    <label className="form-label">Αρ. Μητρώου:</label>
                                </div>
                                <div className="col-xl-9">
                                    <input name="arMitroou" type="text" className="form-control" placeholder="Αρ. Μητρώου" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Εντολή Αγοράς:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="entoliAgoras" type="text" className="form-control" placeholder="Εντολή Αγοράς" onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <label className="form-label">Περιγραφή Βλάβης / Εργασίας *:</label>
                                </div>
                                <div className="col-lg-9">
                                    <textarea name="perigrafiVlavis" type="text" className="form-control" placeholder="Περιγραφή Βλάβης / Εργασίας" onChange={handleChange} />
                                    <label className="fw-lighter text-muted">* αναφέρατε αριθμό παγίου (ψυγείου / μηχανήματος / συμπυκνωτή)</label>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="form-label">Χρησιμοποιηθέντα Υλικά</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Κόστος Μονάδας</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Επιμέτρηση</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Κόστος</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko1" type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas1" type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas1: e.target.value, kostos1: epimetrisi1 * e.target.value, sunolikoKostosIlikon: kostos2 + kostos3 + (epimetrisi1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos2 + kostos3 + (epimetrisi1 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi1" type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi1: e.target.value, kostos1: kostosMonadas1 * e.target.value, sunolikoKostosIlikon: kostos2 + kostos3 + (kostosMonadas1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos2 + kostos3 + (kostosMonadas1 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos1" type="text" className="form-control mb-2" placeholder="" value={kostos1} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko2" type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas2" type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas2: e.target.value, kostos2: epimetrisi2 * e.target.value, sunolikoKostosIlikon: kostos1 + kostos3 + (epimetrisi2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos1 + kostos3 + (epimetrisi2 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi2" type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi2: e.target.value, kostos2: kostosMonadas2 * e.target.value, sunolikoKostosIlikon: kostos1 + kostos3 + (kostosMonadas2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos1 + kostos3 + (kostosMonadas2 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos2" type="text" className="form-control mb-2" placeholder="" value={kostos2} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko3" type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas3" type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas3: e.target.value, kostos3: epimetrisi3 * e.target.value, sunolikoKostosIlikon: kostos2 + kostos1 + (epimetrisi3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos2 + kostos1 + (epimetrisi3 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi3" type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi3: e.target.value, kostos3: kostosMonadas3 * e.target.value, sunolikoKostosIlikon: kostos2 + kostos1 + (kostosMonadas3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosErgasias + allesSinolikoKostos + (kostos2 + kostos1 + (kostosMonadas3 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos3" type="text" className="form-control mb-2" placeholder="" value={kostos3} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Σύνολο</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sunolikoKostosIlikon" type="text" className="form-control mb-2" placeholder="" value={sunolikoKostosIlikon} readOnly />
                            </div>
                        </div>



                    </div>

                    {/* ΕΡΓΑΣΙΑ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="form-label">Εργασία</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Κόστος/Ώρα</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Ώρες Εργ.</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Κόστος</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia1: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                                {/* <input name="ergasia1" type="text" className="form-control mb-2" placeholder="ΟΝΟΜΑ ΤΕΧΝΙΚΟΥ" onChange={handleChange} /> */}
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra1" type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra1: e.target.value, kostosErgasias1: oresErgasias1 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 + kostosErgasias3 + (oresErgasias1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias2 + kostosErgasias3 + (oresErgasias1 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias1" type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias1: e.target.value, kostosErgasias1: kostosAnaOra1 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 + kostosErgasias3 + (kostosAnaOra1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias2 + kostosErgasias3 + (kostosAnaOra1 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias1" type="text" className="form-control mb-2" placeholder="" value={kostosErgasias1} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia2: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra2" type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra2: e.target.value, kostosErgasias2: oresErgasias2 * e.target.value, sunolikoKostosErgasias: kostosErgasias1 + kostosErgasias3 + (oresErgasias2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias1 + kostosErgasias3 + (oresErgasias2 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias2" type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias2: e.target.value, kostosErgasias2: kostosAnaOra2 * e.target.value, sunolikoKostosErgasias: kostosErgasias1 + kostosErgasias3 + (kostosAnaOra2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias1 + kostosErgasias3 + (kostosAnaOra2 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias2" type="text" className="form-control mb-2" placeholder="" value={kostosErgasias2} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" onChange={(value) => { setValues({ ...values, ergasia3: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra3" type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra3: e.target.value, kostosErgasias3: oresErgasias3 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 + kostosErgasias1 + (oresErgasias3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias2 + kostosErgasias1 + (oresErgasias3 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias3" type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias3: e.target.value, kostosErgasias3: kostosAnaOra3 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 + kostosErgasias1 + (kostosAnaOra3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon + allesSinolikoKostos + (kostosErgasias2 + kostosErgasias1 + (kostosAnaOra3 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias3" type="text" className="form-control mb-2" placeholder="" value={kostosErgasias3} readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Σύνολο</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sunolikoKostosErgasias" type="text" className="form-control mb-2" placeholder="" value={sunolikoKostosErgasias} readOnly />
                            </div>
                        </div>



                    </div>

                    {/* ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-10">
                                <label className="form-label">Άλλες Χρεώσεις / Παρατηρήσεις</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Κόστος</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles1" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos1" type="text" className="form-control mb-2" placeholder="Κόστος" onChange={(e) => { setValues({ ...values, allesKostos1: e.target.value, allesSinolikoKostos: (allesKostos2 * 1 + allesKostos3 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon + sunolikoKostosErgasias + (allesKostos2 * 1 + allesKostos3 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles2" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos2" type="text" className="form-control mb-2" placeholder="Κόστος" onChange={(e) => { setValues({ ...values, allesKostos2: e.target.value, allesSinolikoKostos: (allesKostos1 * 1 + allesKostos3 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon + sunolikoKostosErgasias + (allesKostos1 * 1 + allesKostos3 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles3" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos3" type="text" className="form-control mb-2" placeholder="Κόστος" onChange={(e) => { setValues({ ...values, allesKostos3: e.target.value, allesSinolikoKostos: (allesKostos2 * 1 + allesKostos1 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon + sunolikoKostosErgasias + (allesKostos2 * 1 + allesKostos1 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">Σύνολο</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="allesSinolikoKostos" type="text" className="form-control mb-2" placeholder="" value={allesSinolikoKostos} readOnly />
                            </div>
                        </div>
                    </div>

                    {/* ΤΕΛΙΚΟ ΣΥΝΟΛΟ */}
                    <div className="row formBox py-2 g-3 mb-5">
                        <div className="row">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4 text-center">
                                <label className="form-label">Τελικό Σύνολο Προ Φ.Π.Α</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sinolikoKostosProFpa" type="text" className="form-control mb-2" placeholder="" value={sinolikoKostosProFpa} readOnly />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4 text-center">
                                <label className="form-label">Status</label>
                            </div>
                            <div className="col-lg-2">
                                <Select placeholder="Select Status" style={{ width: 120 }} defaultValue="Active" onChange={handleSelect}>
                                    <Option value="Active">Active</Option>
                                    {auth.role === 'Admin' &&
                                        <Option value="Completed">Completed</Option>
                                    }
                                    {auth.role === 'Admin' &&
                                        <Option value="Invoiced">Invoiced</Option>
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4">
                            </div>
                            <div className="col-lg-2">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>

                    </div>
                </form>
            </>
        </>
    )
}

export default RecoForm;