//Imports
import axios from 'axios';


//CREATE REPORT
export const createRecoReport = async (token, data) =>
    await axios.post(`${process.env.REACT_APP_API}/createRecoReport`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const createCarrierReport = async (token, data) =>
    await axios.post(`${process.env.REACT_APP_API}/createCarrierReport`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const createCarrierABReport = async (token, data) =>
    await axios.post(`${process.env.REACT_APP_API}/createCarrierABReport`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const createDelphisReport = async (token, data) =>
    await axios.post(`${process.env.REACT_APP_API}/createDelphisReport`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });


//GET REPORTS
export const readRecoReports = async (data) =>
    await axios.get(`${process.env.REACT_APP_API}/recoReports`, data);

export const readCarrierReports = async (data) =>
    await axios.get(`${process.env.REACT_APP_API}/carrierReports`, data);

export const readCarrierABReports = async (data) =>
    await axios.get(`${process.env.REACT_APP_API}/carrierABReports`, data);

export const readDelphisReports = async (data) =>
    await axios.get(`${process.env.REACT_APP_API}/delphisReports`, data);


//GET 1 REPORT
export const readRecoReport = async (reportId) =>
    await axios.get(`${process.env.REACT_APP_API}/readRecoReport/${reportId}`);

export const readCarrierReport = async (reportId) =>
    await axios.get(`${process.env.REACT_APP_API}/readCarrierReport/${reportId}`);

export const readCarrierABReport = async (reportId) =>
    await axios.get(`${process.env.REACT_APP_API}/readCarrierABReport/${reportId}`);

export const readDelphisReport = async (reportId) =>
    await axios.get(`${process.env.REACT_APP_API}/readDelphisReport/${reportId}`);


//Update 1 Report
export const updateRecoReport = async (token, reportId, data) =>
    await axios.post(`${process.env.REACT_APP_API}/recoReport/edit/${reportId}`,data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const updateCarrierReport = async (token, reportId, data) =>
    await axios.post(`${process.env.REACT_APP_API}/carrierReport/edit/${reportId}`,data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const updateCarrierABReport = async (token, reportId, data) =>
    await axios.post(`${process.env.REACT_APP_API}/carrierABReport/edit/${reportId}`,data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const updateDelphisReport = async (token, reportId, data) =>
    await axios.post(`${process.env.REACT_APP_API}/delphisReport/edit/${reportId}`,data, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });



//Delete Reports
export const deleteRecoReport = async (token, reportId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deleteRecoReport/${reportId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const deleteCarrierReport = async (token, reportId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deleteCarrierReport/${reportId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const deleteCarrierABReport = async (token, reportId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deleteCarrierABReport/${reportId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

export const deleteDelphisReport = async (token, reportId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deleteDelphisReport/${reportId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });