//Imports
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//Import Actions
import { readRecoReports, readCarrierReports, readCarrierABReports, readDelphisReports } from '../actions/reports';
import { updateRecoReport, updateCarrierABReport, updateCarrierReport, updateDelphisReport } from '../actions/reports';


//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import FilterReports from '../components/admin/FilterReports';
import CompletedRecoReports from '../components/admin/CompletedRecoReports';
import CompletedCarrierABReports from '../components/admin/CompletedCarrierABReports';
import CompletedCarrierReports from '../components/admin/CompletedCarrierReports';
import CompletedDelphisReports from '../components/admin/CompletedDelphisReports';


const CompletedReports = ({match}) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [recoReports, setRecoReports] = useState([]);
    const [carrierReports, setCarrierReports] = useState([]);
    const [carrierABReports, setCarrierABReports] = useState([]);
    const [delphisReports, setDelphisReports] = useState([]);

    const [dateFilter, setDateFilter] = useState(["", ""]);
    const [katastimaFilter, setKatastimaFilter] = useState("");
    const [texnikosFilter, setTexnikosFilter] = useState("");

    const handleDateFilter = (value, dateString) => {
        setDateFilter(dateString);
    }

    const handleKatastimaSearchFilter = (value) => {
        setKatastimaFilter(value);
    }

    const handleTexnikosSearchFilter = (value) => {
        setTexnikosFilter(value);
    }

    const handleClear = () => {
        setDateFilter(["", ""]);
        setKatastimaFilter("");
        setTexnikosFilter("");
    }

    useEffect(() => {
        const loadAllRecoReports = async () => {
            let res = await readRecoReports();
            setRecoReports(res.data);
        }

        const loadAllCarrierReports = async () => {
            let res = await readCarrierReports();
            setCarrierReports(res.data);
        }

        const loadAllCarrierABReports = async () => {
            let res = await readCarrierABReports();
            setCarrierABReports(res.data);
        }

        const loadAllDelphisReports = async () => {
            let res = await readDelphisReports();
            setDelphisReports(res.data);
        }

        loadAllDelphisReports();
        loadAllCarrierABReports();
        loadAllRecoReports();
        loadAllCarrierReports();
    }, []);

    const handleStatusReco = async (report) => {
        try {

            const data = {
                katastima: report.katastima,
                arGnostopoihshs: report.arGnostopoihshs, 
                arMitroou: report.arMitroou,
                perigrafiVlavis: report.perigrafiVlavis,
                iliko1: report.iliko1,
                iliko2: report.iliko2,
                iliko3: report.iliko3,
                kostosMonadas1: report.kostosMonadas1,
                kostosMonadas2: report.kostosMonadas2,
                kostosMonadas3: report.kostosMonadas3,
                epimetrisi1: report.epimetrisi1,
                epimetrisi2: report.epimetrisi2,
                epimetrisi3: report.epimetrisi3,
                kostos1: report.kostos1,
                kostos2: report.kostos2,
                kostos3: report.kostos3,
                sunolikoKostosIlikon: report.sunolikoKostosIlikon,
                ergasia1: report.ergasia1,
                ergasia2: report.ergasia2,
                ergasia3: report.ergasia3,
                kostosAnaOra1: report.kostosAnaOra1,
                kostosAnaOra2: report.kostosAnaOra2,
                kostosAnaOra3: report.kostosAnaOra3,
                oresErgasias1: report.oresErgasias1,
                oresErgasias2: report.oresErgasias2,
                oresErgasias3: report.oresErgasias3,
                kostosErgasias1: report.kostosErgasias1,
                kostosErgasias2: report.kostosErgasias2,
                kostosErgasias3: report.kostosErgasias3,
                sunolikoKostosErgasias: report.sunolikoKostosErgasias,
                alles1: report.alles1,
                alles2: report.alles2,
                alles3: report.alles3,
                allesKostos1: report.allesKostos1,
                allesKostos2: report.allesKostos2,
                allesKostos3: report.allesKostos3,
                allesSinolikoKostos: report.allesSinolikoKostos,
                sinolikoKostosProFpa: report.sinolikoKostosProFpa,
                apo: report.apo,
                eos: report.eos,
                date: report.date,
                status: "Invoiced",
            }

            const res = await updateRecoReport(token, report.id, data);
            console.log("Reco Report Updated", res);

            //history.push(`/readRecoReport/${match.params.reportId}`)
            setTimeout(()=>{
                window.location.reload();
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }
    
    const handleStatusCarrierAB = async (report) => {
        try {

            const data = {
                katastima: report.katastima,
                arGnostopoihshs: report.arGnostopoihshs,
                arMitroou: report.arMitroou,
                entoliAgoras: report.entoliAgoras,
                perigrafiVlavis: report.perigrafiVlavis,
                iliko1: report.iliko1,
                iliko2: report.iliko2,
                iliko3: report.iliko3,
                kostosMonadas1: report.kostosMonadas1,
                kostosMonadas2: report.kostosMonadas2,
                kostosMonadas3: report.kostosMonadas3,
                epimetrisi1: report.epimetrisi1,
                epimetrisi2: report.epimetrisi2,
                epimetrisi3: report.epimetrisi3,
                kostos1: report.kostos1,
                kostos2: report.kostos2,
                kostos3: report.kostos3,
                sunolikoKostosIlikon: report.sunolikoKostosIlikon,
                ergasia1: report.ergasia1,
                ergasia2: report.ergasia2,
                ergasia3: report.ergasia3,
                kostosAnaOra1: report.kostosAnaOra1,
                kostosAnaOra2: report.kostosAnaOra2,
                kostosAnaOra3: report.kostosAnaOra3,
                oresErgasias1: report.oresErgasias1,
                oresErgasias2: report.oresErgasias2,
                oresErgasias3: report.oresErgasias3,
                kostosErgasias1: report.kostosErgasias1,
                kostosErgasias2: report.kostosErgasias2,
                kostosErgasias3: report.kostosErgasias3,
                sunolikoKostosErgasias: report.sunolikoKostosErgasias,
                alles1: report.alles1,
                alles2: report.alles2,
                alles3: report.alles3,
                allesKostos1: report.allesKostos1,
                allesKostos2: report.allesKostos2,
                allesKostos3: report.allesKostos3,
                allesSinolikoKostos: report.allesSinolikoKostos,
                sinolikoKostosProFpa: report.sinolikoKostosProFpa,
                apo: report.apo,
                eos: report.eos,
                date: report.date,
                status: "Invoiced",
                ypografi1: report.ypografi1,
                ypografi2: report.ypografi2,
                ypografi3: report.ypografi3,
            }

            const res = await updateCarrierABReport(token, report.id, data);
            console.log("CarrierAB Report Updated", res);

            //history.push(`/readCarrierABReport/${match.params.reportId}`)
            setTimeout(()=>{
                window.location.reload();
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }

    
    const handleStatusCarrier = async (report) => {
        try {

            const data = {
                pelatis: report.pelatis,
                diefthinsi: report.diefthinsi,
                thl: report.thl,
                kwdikosKatastimatos: report.kwdikosKatastimatos,
                entoliPelati: report.entoliPelati,
                onomatepwnymo: report.onomatepwnymo,
                thlefwno: report.thlefwno,
                ypokatastima: report.ypokatastima,
                kentriko: report.kentriko,
                proforiki: report.proforiki,
                thlefwnikh: report.thlefwnikh,
                grapth: report.grapth,
                arGnostopoihshs: report.arGnostopoihshs,
                arMitroou: report.arMitroou,                
                entoliAgoras: report.entoliAgoras,                
                wraKlhshs: report.wraKlhshs,
                wraMetavashs: report.wraMetavashs,
                wraAfikshs: report.wraAfikshs,
                diarkeiaErgasias: report.diarkeiaErgasias,
                wraAnaxwrhshs: report.wraAnaxwrhshs,
                wraEpistrofhs: report.wraEpistrofhs,
                kmMetavashs: report.kmMetavashs,
                kmEpistrofhs: report.kmEpistrofhs,
                ergasia1: report.ergasia1,
                ergasia2: report.ergasia2,
                ergasia3: report.ergasia3,
                eggyhsh: report.eggyhsh,
                xrewsh: report.xrewsh,
                aneuXrewshs: report.aneuXrewshs,
                periodikoService: report.periodikoService,
                symvolaio: report.symvolaio,
                egkatastash: report.egkatastash,
                egkPwlhsh: report.egkPwlhsh,
                anakainish: report.anakainish,
                loipesErgasies: report.loipesErgasies,
                perigrafiVlavis: report.perigrafiVlavis,
                astoxiaSwlhna: report.astoxiaSwlhna,
                xalarosSyndesmos: report.xalarosSyndesmos,
                diatrhtosEksatmisths: report.diatrhtosEksatmisths,
                diatrhtosSymphknwths: report.diatrhtosSymphknwths,
                astoxiaSyggolhshs: report.astoxiaSyggolhshs,
                kakhSterewsh: report.kakhSterewsh,
                vraxykyklwmenhAntistash: report.vraxykyklwmenhAntistash,
                vraxykyklwmenosAnemisthras: report.vraxykyklwmenosAnemisthras,
                vlavhH_Arruthmistos: report.vlavhH_Arruthmistos,
                kamenesAsfaleies: report.kamenesAsfaleies,
                VraxykyklwmenoRele: report.VraxykyklwmenoRele,
                thermostathsXwrou: report.thermostathsXwrou,
                hlektronikosThermostaths: report.hlektronikosThermostaths,
                magnhtikhValvida: report.magnhtikhValvida,
                ektonwtikhValvida: report.ektonwtikhValvida,
                thermostathsAsfaleias: report.thermostathsAsfaleias,
                klixonPortas: report.klixonPortas,
                epidrashApoKlimatismo: report.epidrashApoKlimatismo,
                epidrashApoReuma: report.epidrashApoReuma,
                apoxeteushKleisth: report.apoxeteushKleisth,
                antistashApoxeteushs: report.antistashApoxeteushs,
                pesmenhH_Xalasmenh: report.pesmenhH_Xalasmenh,
                apwleiaFashs: report.apwleiaFashs,
                ptwshH_Ypervash: report.ptwshH_Ypervash,
                thermomagnhtikos: report.thermomagnhtikos,
                vraxykyklwmenoRele2: report.vraxykyklwmenoRele2,
                hl_Prostasia: report.hl_Prostasia,
                piessostaths: report.piessostaths,
                piessostaths2: report.piessostaths2,
                antliaLadiou: report.antliaLadiou,
                elleipshLadiou: report.elleipshLadiou,
                valvidaAforths: report.valvidaAforths,
                akatharisto: report.akatharisto,
                condenser: report.condenser,
                vraxykyklSymp: report.vraxykyklSymp,
                antikatastSymp: report.antikatastSymp,
                provlhmaDiktyo: report.provlhmaDiktyo,
                provlhmaPLC: report.provlhmaPLC,
                piesYpshlhs: report.piesYpshlhs,
                piesXamhlhs: report.piesXamhlhs,
                elleipshFreon3: report.elleipshFreon3,
                elleipshLadiou3: report.elleipshLadiou3,
                akathElaio: report.akathElaio,
                akathCondenser: report.akathCondenser,
                vraxyAnem: report.vraxyAnem,
                dysmeneis: report.dysmeneis,
                allo0: report.allo0,
                perigrafiErgasion: report.perigrafiErgasion,
                date: report.date,
                status: "Invoiced",
                ypografi1: report.ypografi1,
                ypografi2: report.ypografi2,
                ypografi3: report.ypografi3,
            }

            const res = await updateCarrierReport(token, report.id, data);
            console.log("Carrier Report Updated", res);

            //history.push(`/readCarrierReport/${match.params.reportId}`)
            setTimeout(()=>{
                window.location.reload();
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }

    
    const handleStatusDelphis = async (report) => {
        try {

            const data = {
                ekgkatastash: report.ekgkatastash, 
                synthrhsh: report.synthrhsh, 
                ektakth: report.ektakth, 
                episkeuh: report.episkeuh,
                katastima: report.katastima,
                arGnostopoihshs: report.arGnostopoihshs,
                arMitroou: report.arMitroou,
                entoliAgoras: report.entoliAgoras,
                perigrafiVlavis: report.perigrafiVlavis,
                iliko1: report.iliko1,
                iliko2: report.iliko2,
                iliko3: report.iliko3,
                kostosMonadas1: report.kostosMonadas1,
                kostosMonadas2: report.kostosMonadas2,
                kostosMonadas3: report.kostosMonadas3,
                epimetrisi1: report.epimetrisi1,
                epimetrisi2: report.epimetrisi2,
                epimetrisi3: report.epimetrisi3,
                kostos1: report.kostos1,
                kostos2: report.kostos2,
                kostos3: report.kostos3,
                sunolikoKostosIlikon: report.sunolikoKostosIlikon,
                ergasia1: report.ergasia1,
                ergasia2: report.ergasia2,
                ergasia3: report.ergasia3,
                kostosAnaOra1: report.kostosAnaOra1,
                kostosAnaOra2: report.kostosAnaOra2,
                kostosAnaOra3: report.kostosAnaOra3,
                oresErgasias1: report.oresErgasias1,
                oresErgasias2: report.oresErgasias2,
                oresErgasias3: report.oresErgasias3,
                kostosErgasias1: report.kostosErgasias1,
                kostosErgasias2: report.kostosErgasias2,
                kostosErgasias3: report.kostosErgasias3,
                sunolikoKostosErgasias: report.sunolikoKostosErgasias,
                alles1: report.alles1,
                alles2: report.alles2,
                alles3: report.alles3,
                allesKostos1: report.allesKostos1,
                allesKostos2: report.allesKostos2,
                allesKostos3: report.allesKostos3,
                allesSinolikoKostos: report.allesSinolikoKostos,
                sinolikoKostosProFpa: report.sinolikoKostosProFpa,
                apo: report.apo,
                eos: report.eos,
                date: report.date,
                status: "Invoiced",
                ypografi1: report.ypografi1,
                ypografi2: report.ypografi2,
                ypografi3: report.ypografi3,
            }

            const res = await updateDelphisReport(token, report.id, data);
            console.log("Delphis Report Updated", res);

            //history.push(`/readDelphisReport/${match.params.reportId}`)
            setTimeout(()=>{
                window.location.reload();
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Completed Reports" />
                        <FilterReports dateFilter={dateFilter} katastimaFilter={katastimaFilter} setKatastimaFilter={setKatastimaFilter} texnikosFilter={texnikosFilter} setTexnikosFilter={setTexnikosFilter} handleDateFilter={handleDateFilter} handleKatastimaSearchFilter={handleKatastimaSearchFilter} handleTexnikosSearchFilter={handleTexnikosSearchFilter} handleClear={handleClear}/>
                        <CompletedRecoReports recoReports={recoReports} handleStatusReco={handleStatusReco} match={match} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <CompletedCarrierABReports carrierABReports={carrierABReports} handleStatusCarrierAB={handleStatusCarrierAB} match={match} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <CompletedCarrierReports carrierReports={carrierReports} handleStatusCarrier={handleStatusCarrier} match={match} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <CompletedDelphisReports delphisReports={delphisReports} handleStatusDelphis={handleStatusDelphis} match={match} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default CompletedReports;