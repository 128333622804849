//Imports
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Import Actions
import { readUsers } from '../actions/users';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import TechniciansDisplay from '../components/admin/TechniciansDisplay';

const Technicians = () => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [users, setUsers] = useState([]);

    useEffect(() => {

        const loadAllUsers = async () => {
            let res = await readUsers(token);
            setUsers(res.data);
        };


        loadAllUsers();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Technicians" />
                        <TechniciansDisplay users={users} />
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default Technicians;