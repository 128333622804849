

const InnerHeader = (props) => {

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ps-3 pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">{props.title}</h1>
            </div>
        </>
    );
};

export default InnerHeader;