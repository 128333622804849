//Imports
import { TimePicker, DatePicker, Select } from 'antd';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Popup from 'reactjs-popup';

//import moment from 'moment';
import DelphisLogo from '../../dist/images/Delphis.png';

const format = 'HH:mm';
const { Option } = Select;

const EditDelphisForm = (props) => {

    const { handleSubmit, handleDate, handleSelect, handleSelectKatastima, handleChange, handleApo, handleEos, users, values, setValues, match, auth, stores } = props;

    const { ekgkatastash, synthrhsh, ektakth, episkeuh, katastima, arGnostopoihshs, arMitroou, entoliAgoras, perigrafiVlavis, iliko1, iliko2, iliko3, kostosMonadas1, kostosMonadas2, kostosMonadas3, epimetrisi1, epimetrisi2, epimetrisi3, kostos1, kostos2, kostos3, sunolikoKostosIlikon, ergasia1, ergasia2, ergasia3, kostosAnaOra1, kostosAnaOra2, kostosAnaOra3, oresErgasias1, oresErgasias2, oresErgasias3, kostosErgasias1, kostosErgasias2, kostosErgasias3, sunolikoKostosErgasias, alles1, alles2, alles3, allesKostos1, allesKostos2, allesKostos3, allesSinolikoKostos, sinolikoKostosProFpa, status, ypografi1, ypografi2, ypografi3 } = values;
    const history = useHistory();

    if (ekgkatastash === 'on') document.getElementById("ekgkatastash").checked = true;
    if (synthrhsh === 'on') document.getElementById("synthrhsh").checked = true;
    if (ektakth === 'on') document.getElementById("ektakth").checked = true;
    if (episkeuh === 'on') document.getElementById("episkeuh").checked = true;

    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const save1 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        console.log(temp);
        setValues({ ...values, ypografi1: temp });
    }

    const save2 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setValues({ ...values, ypografi2: temp });
    }

    const save3 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setValues({ ...values, ypografi3: temp });
    }

    const handleC = (e) => {
        if (e.target.value === 'on') {
            document.getElementById(`${e.target.id}`).unchecked = true;
            setValues({ ...values, [e.target.name]: 'off' });
        } else {
            document.getElementById(`${e.target.id}`).checked = true;
            setValues({ ...values, [e.target.name]: 'on' });
        }

    }

    return (
        <>
            <>
                <form onSubmit={handleSubmit} className="mt-3 p-3">

                    <div className="row g-3 mb-5">
                        <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-nowrap">
                            <img className="formLogo my-2" src={DelphisLogo} alt="DelphisLogo" />
                            <h2 className="formTitle my-2">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                        </div>
                    </div>

                    {/* Checkboxes */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5">
                        <div className="col-lg-3">
                            <div className="form-check form-check-inline mx-5">
                                <input className="form-check-input" name="ekgkatastash" id="ekgkatastash" type="checkbox" value={ekgkatastash} onChange={handleC}></input>
                                <label className="form-check-label">ΕΓΚΑΤΑΣΤΑΣΗ</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-check form-check-inline mx-5">
                                <input className="form-check-input" name="synthrhsh" id="synthrhsh" type="checkbox" value={synthrhsh} onChange={handleC}></input>
                                <label className="form-check-label">ΣΥΝΤΗΡΗΣΗ</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-check form-check-inline mx-5">
                                <input className="form-check-input" name="ektakth" id="ektakth" type="checkbox" value={ektakth} onChange={handleC}></input>
                                <label className="form-check-label">ΕΚΤΑΚΤΗ</label>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="form-check form-check-inline mx-5">
                                <input className="form-check-input" name="episkeuh" id="episkeuh" type="checkbox" value={episkeuh} onChange={handleC}></input>
                                <label className="form-check-label">ΕΠΙΣΚΕΥΗ</label>
                            </div>
                        </div>
                    </div>


                    {/* ΕΝΤΟΛΗ ΣΥΝΤΗΡΗΣΗΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-xl-5">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Κατάστημα:</label>
                                </div>
                                <div className="col-xl-8">
                                    <Select placeholder="Κατάστημα" style={{ width: '100%' }} value={katastima} onChange={handleSelectKatastima}>
                                        {stores.map(store => {
                                            return (
                                                <Option key={store.id} value={`${store.name}`}>{store.name}</Option>
                                            )
                                        })}
                                    </Select>
                                    {/* <input name="katastima" value={katastima} type="text" className="form-control" placeholder="" onChange={handleChange} /> */}
                                </div>
                            </div>

                        </div>
                        <div className="col-xl-2">
                            <label className="form-label mx-2">Από:</label>
                            <TimePicker name="apo" placeholder="Επιλέξτε Ώρα" onChange={handleApo} format={format} />
                        </div>
                        <div className="col-xl-2">
                            <label className="form-label mx-2">Έως:</label>
                            <TimePicker name="eos" placeholder="Επιλέξτε Ώρα" onChange={handleEos} format={format} />
                        </div>
                        <div className="col-xl-3">
                            <label className="form-label mx-2">Ημ/νια:</label>
                            <DatePicker name="date" placeholder="Επιλέξτε Ημ/νία" onChange={handleDate} />
                        </div>
                    </div>

                    {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="arGnostopoihshs" type="text" className="form-control" placeholder="" value={arGnostopoihshs} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-3">
                                    <label className="form-label">Αρ. Μητρώου:</label>
                                </div>
                                <div className="col-xl-9">
                                    <input name="arMitroou" type="text" className="form-control" placeholder="" value={arMitroou} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Εντολή Αγοράς:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="entoliAgoras" type="text" className="form-control" placeholder="" value={entoliAgoras} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <label className="form-label">ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ *:</label>
                                </div>
                                <div className="col-lg-9">
                                    <textarea name="perigrafiVlavis" value={perigrafiVlavis} type="text" className="form-control" placeholder="" onChange={handleChange} />
                                    <label className="fw-lighter text-muted">* αναφέρατε αριθμό παγίου (ψυγείου / μηχανήματος / συμπυκνωτή)</label>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="form-label">ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΚΟΣΤΟΣ ΜΟΝΑΔΑΣ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΕΠΙΜΕΤΡΗΣΗ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΚΟΣΤΟΣ</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko1" value={iliko1} type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas1" value={kostosMonadas1} type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas1: e.target.value * 1, kostos1: epimetrisi1 * e.target.value * 1, sunolikoKostosIlikon: kostos2 * 1 + kostos3 * 1 + (epimetrisi1 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos2 * 1 + kostos3 * 1 + (epimetrisi1 * e.target.value * 1)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi1" value={epimetrisi1} type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi1: e.target.value * 1, kostos1: kostosMonadas1 * e.target.value * 1, sunolikoKostosIlikon: kostos2 * 1 + kostos3 * 1 + (kostosMonadas1 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos2 * 1 + kostos3 * 1 + (kostosMonadas1 * e.target.value * 1)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos1" value={kostos1} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko2" value={iliko2} type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas2" value={kostosMonadas2} type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas2: e.target.value * 1, kostos2: epimetrisi2 * e.target.value * 1, sunolikoKostosIlikon: kostos1 * 1 + kostos3 * 1 + (epimetrisi2 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos1 * 1 + kostos3 * 1 + (epimetrisi2 * e.target.value * 1)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi2" value={epimetrisi2} type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi2: e.target.value * 1, kostos2: kostosMonadas2 * e.target.value * 1, sunolikoKostosIlikon: kostos1 * 1 + kostos3 * 1 + (kostosMonadas2 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos1 * 1 + kostos3 * 1 + (kostosMonadas2 * e.target.value * 1)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos2" value={kostos2} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <input name="iliko3" value={iliko3} type="text" className="form-control mb-2" placeholder="Υλικό" onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosMonadas3" value={kostosMonadas3} type="text" className="form-control mb-2" placeholder="Κόστος Μονάδας" onChange={(e) => { setValues({ ...values, kostosMonadas3: e.target.value * 1, kostos3: epimetrisi3 * e.target.value * 1, sunolikoKostosIlikon: kostos2 * 1 + kostos1 * 1 + (epimetrisi3 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos2 * 1 + kostos1 * 1 + (epimetrisi3 * e.target.value * 1)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="epimetrisi3" value={epimetrisi3} type="text" className="form-control mb-2" placeholder="Επιμέτρηση" onChange={(e) => { setValues({ ...values, epimetrisi3: e.target.value * 1, kostos3: kostosMonadas3 * e.target.value * 1, sunolikoKostosIlikon: kostos2 * 1 + kostos1 * 1 + (kostosMonadas3 * e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosErgasias * 1 + allesSinolikoKostos * 1 + (kostos2 * 1 + kostos1 * 1 + (kostosMonadas3 * e.target.value * 1)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostos3" value={kostos3} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΣΥΝΟΛΟ</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sunolikoKostosIlikon" value={sunolikoKostosIlikon} type="number" className="form-control mb-2" placeholder="" onChange={handleChange} readOnly />
                            </div>
                        </div>



                    </div>

                    {/* ΕΡΓΑΣΙΑ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-6">
                                <label className="form-label">ΕΡΓΑΣΙΑ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΚΟΣΤΟΣ/ΩΡΑ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΩΡΕΣ ΕΡΓ.</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΚΟΣΤΟΣ</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia1} onChange={(value) => { setValues({ ...values, ergasia1: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra1" value={kostosAnaOra1} type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra1: e.target.value * 1, kostosErgasias1: oresErgasias1 * 1 * e.target.value * 1, sunolikoKostosErgasias: kostosErgasias2 * 1 + kostosErgasias3 * 1 + (oresErgasias1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias2 * 1 + kostosErgasias3 * 1 + (oresErgasias1 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias1" value={oresErgasias1} type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias1: e.target.value * 1, kostosErgasias1: kostosAnaOra1 * 1 * e.target.value * 1, sunolikoKostosErgasias: kostosErgasias2 * 1 + kostosErgasias3 * 1 + (kostosAnaOra1 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias2 * 1 + kostosErgasias3 * 1 + (kostosAnaOra1 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias1" value={kostosErgasias1} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia2} onChange={(value) => { setValues({ ...values, ergasia2: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra2" value={kostosAnaOra2} type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra2: e.target.value * 1, kostosErgasias2: oresErgasias2 * e.target.value, sunolikoKostosErgasias: kostosErgasias1 * 1 + kostosErgasias3 * 1 + (oresErgasias2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias1 * 1 + kostosErgasias3 * 1 + (oresErgasias2 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias2" value={oresErgasias2} type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias2: e.target.value * 1, kostosErgasias2: kostosAnaOra2 * e.target.value, sunolikoKostosErgasias: kostosErgasias1 * 1 + kostosErgasias3 * 1 + (kostosAnaOra2 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias1 * 1 + kostosErgasias3 * 1 + (kostosAnaOra2 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias2" value={kostosErgasias2} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 my-2">
                                <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia3} onChange={(value) => { setValues({ ...values, ergasia3: value }) }}>
                                    {users
                                        .filter(user => user.role === "Technician")
                                        .map(user => {
                                            return (
                                                <Option value={user.name}>{user.name}</Option>
                                            )
                                        })}
                                </Select>
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosAnaOra3" value={kostosAnaOra3} type="text" className="form-control mb-2" placeholder="Κόστος/Ώρα" onChange={(e) => { setValues({ ...values, kostosAnaOra3: e.target.value * 1, kostosErgasias3: oresErgasias3 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 * 1 + kostosErgasias1 * 1 + (oresErgasias3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias2 * 1 + kostosErgasias1 * 1 + (oresErgasias3 * e.target.value)) }) }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="oresErgasias3" value={oresErgasias3} type="text" className="form-control mb-2" placeholder="Ώρες Εργασίας" onChange={(e) => { setValues({ ...values, oresErgasias3: e.target.value * 1, kostosErgasias3: kostosAnaOra3 * e.target.value, sunolikoKostosErgasias: kostosErgasias2 * 1 + kostosErgasias1 * 1 + (kostosAnaOra3 * e.target.value), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + allesSinolikoKostos * 1 + (kostosErgasias2 * 1 + kostosErgasias1 * 1 + (kostosAnaOra3 * e.target.value)) }); }} />
                            </div>
                            <div className="col-lg-2">
                                <input name="kostosErgasias3" value={kostosErgasias3} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΣΥΝΟΛΟ</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sunolikoKostosErgasias" type="text" className="form-control mb-2" placeholder="" value={sunolikoKostosErgasias} readOnly />
                            </div>
                        </div>
                    </div>

                    {/* ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                    <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                        <div className="row">
                            <div className="col-lg-10">
                                <label className="form-label">ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ</label>
                            </div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΚΟΣΤΟΣ</label>

                            </div>
                        </div>

                        {/* Dublicate the following row for extra fields */}
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles1" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" value={alles1} onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos1" type="text" className="form-control mb-2" placeholder="Κόστος" value={allesKostos1} onChange={(e) => { setValues({ ...values, allesKostos1: e.target.value * 1, allesSinolikoKostos: (allesKostos2 * 1 + allesKostos3 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + sunolikoKostosErgasias * 1 + (allesKostos2 * 1 + allesKostos3 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles2" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" value={alles2} onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos2" type="text" className="form-control mb-2" placeholder="Κόστος" value={allesKostos2} onChange={(e) => { setValues({ ...values, allesKostos2: e.target.value * 1, allesSinolikoKostos: (allesKostos1 * 1 + allesKostos3 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + sunolikoKostosErgasias * 1 + (allesKostos1 * 1 + allesKostos3 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10">
                                <input name="alles3" type="text" className="form-control mb-2" placeholder="Άλλες Χρεώσεις / Παρατηρήσεις" value={alles3} onChange={handleChange} />
                            </div>
                            <div className="col-lg-2">
                                <input name="allesKostos3" type="text" className="form-control mb-2" placeholder="Κόστος" value={allesKostos3} onChange={(e) => { setValues({ ...values, allesKostos3: e.target.value * 1, allesSinolikoKostos: (allesKostos2 * 1 + allesKostos1 * 1 + e.target.value * 1), sinolikoKostosProFpa: sunolikoKostosIlikon * 1 + sunolikoKostosErgasias * 1 + (allesKostos2 * 1 + allesKostos1 * 1 + e.target.value * 1) }) }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2 text-center">
                                <label className="form-label">ΣΥΝΟΛΟ</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="allesSinolikoKostos" type="text" className="form-control mb-2" placeholder="" value={allesSinolikoKostos} readOnly />
                            </div>
                        </div>
                    </div>

                    {/* ΤΕΛΙΚΟ ΣΥΝΟΛΟ */}
                    <div className="row formBox py-2 g-3 mb-5">
                        <div className="row">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4 text-center">
                                <label className="form-label">ΤΕΛΙΚΟ ΣΥΝΟΛΟ ΠΡΟ Φ.Π.Α</label>
                            </div>
                            <div className="col-lg-2">
                                <input name="sinolikoKostosProFpa" value={sinolikoKostosProFpa} type="text" className="form-control mb-2" placeholder="" readOnly />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4 text-center">
                                <label className="form-label">Status</label>
                            </div>
                            <div className="col-lg-2">
                                <Select placeholder="Select Status" style={{ width: 120 }} value={status} onChange={handleSelect}>
                                    <Option value="Active">Active</Option>
                                    {auth.role === 'Admin' &&
                                        <Option value="Completed">Completed</Option>
                                    }
                                    {auth.role === 'Admin' &&
                                        <Option value="Invoiced">Invoiced</Option>
                                    }
                                </Select>
                            </div>
                        </div>

                        {/* ΥΠΟΓΡΑΦΕΣ */}
                        <div className="row formBox py-2 g-3 my-5">
                            <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                                <label className="form-label mb-5">ΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ</label>
                                {ypografi1 ?
                                    <img src={ypografi1} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                                    : <div className="sigImage"></div>
                                }
                                <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                                    closeOnDocumentClick={false}
                                >
                                    {close => (
                                        <>
                                            <SignatureCanvas penColor='black' ref={sigCanvas}
                                                canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                            <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={() => { save1(); close(); }}>Αποθήκευση</button>
                                        </>
                                    )}
                                </Popup>
                            </div>
                            <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                                <label className="form-label mb-5">ΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ</label>
                                {ypografi2 ?
                                    <img src={ypografi2} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                                    : <div className="sigImage"></div>
                                }
                                <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                                    closeOnDocumentClick={false}
                                >
                                    {close => (
                                        <>
                                            <SignatureCanvas penColor='black' ref={sigCanvas}
                                                canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                            <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={() => { save2(); close(); }}>Αποθήκευση</button>
                                        </>
                                    )}
                                </Popup>
                            </div>
                            <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                                <label className="form-label mb-5">ΕΛΕΓΧΟΣ</label>
                                {ypografi3 ?
                                    <img src={ypografi3} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                                    : <div className="sigImage"></div>
                                }
                                <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                                    closeOnDocumentClick={false}
                                >
                                    {close => (
                                        <>
                                            <SignatureCanvas penColor='black' ref={sigCanvas}
                                                canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                            <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                            <button type="button" className="btn btn-info mx-2" onClick={() => { save3(); close(); }}>Αποθήκευση</button>
                                        </>
                                    )}
                                </Popup>
                            </div>
                        </div>


                        <div className="row my-2">
                            <div className="col-lg-6"></div>
                            <div className="col-lg-4">
                            </div>
                            <div className="col-lg-2">
                                <button type="submit" className="btn btn-primary my-2 me-3">Save</button>
                                <button type="button" className="btn btn-secondary my-2" onClick={() => { history.push(`/readDelphisReport/${match.params.reportId}`) }}>Cancel</button>
                            </div>
                        </div>
                    </div>

                </form>
            </>
        </>
    )
}

export default EditDelphisForm;