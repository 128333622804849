import { Select } from 'antd';

const { Option } = Select;

const RegisterUserForm = ({ handleChange, handleSubmit, handleSelect }) => {

    return (
        <>
            <div className="w-100">
                <div className="row justify-content-center my-5 mx-0">
                    <div className="col-md-3">
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label name="name" className="form-label">Name</label>
                                <input name="name" type="name" className="form-control" id="name" aria-describedby="nameHelp" onChange={handleChange} required></input>
                            </div>
                            <div className="mb-3">
                                <label name="email" className="form-label">Email address</label>
                                <input name="email" type="email" className="form-control" id="email" aria-describedby="emailHelp" onChange={handleChange} required></input>
                            </div>
                            <div className="mb-3">
                                <label name="password" className="form-label">Password</label>
                                <input name="password" type="password" className="form-control" id="password" onChange={handleChange} required></input>
                            </div>
                            <div className="form-group mb-3">
                                <label className="form-label">Role</label>
                                <Select onChange={handleSelect} placeholder="Select Role" className="w-100 shadow" required>
                                    <Option key="Technician">Technician</Option>
                                    <Option key="Admin">Admin</Option>
                                </Select>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterUserForm;