import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';


const PrivateAdmin = ({...rest}) => {

    const {auth} = useSelector((state) => ({...state}));

    if(auth && auth.token){
        if(auth.role === 'Admin'){
            return <Route {...rest} />;
        }else{
            return <Redirect to="/dashboard"/>;
        }

    }else{
        return <Redirect to="/"/>;
    }

};

export default PrivateAdmin;