//Imports
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { login } from '../actions/users';

const Login = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [loginError, setLoginError] = useState('');

    const { email, password } = values;


    const handleSubmit = async (e) => {
        e.preventDefault();
        document.querySelector(".hideThis").setAttribute("style", "display: inline-block");

        try {
            const data = {
                email: email,
                password: password,
            }
            const res = await login(data);

            if (res.data) {
                console.log("Save User Res in REDUX and Local Storage: ", res.data[0]);

                window.localStorage.setItem('auth', JSON.stringify(res.data[0]));

                dispatch({
                    type: 'LOGGED_IN_USER',
                    payload: res.data[0],
                })

                history.push("/dashboard");
            }
        } catch (err) {
            console.log(err);
            document.querySelector(".hideThis").setAttribute("style", "display: none");
            if (err.response.status === 400) {
                console.log(err.response.data);
                setLoginError(err.response.data);
            };
            if (err.response.status === 401) {
                console.log(err.response.data);
                setLoginError(err.response.data);
            };
        }
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }
    return (
        <>
            <div className="loginPageWrapper bg_primary">{loginError &&
                <div className="alert alert-danger" role="alert">
                    <h4>{loginError}</h4>
                </div>
            }
                <form method="POST" onSubmit={handleSubmit} className="loginPageForm">
                    <h1 className="my-2 text-white">Damage Report</h1>
                    <div className="form-group w-100 my-2">
                        <label htmlFor="exampleInputEmail1">Email address</label>
                        <input type="email" name="email" className="form-control" id="email" aria-describedby="email" placeholder="Enter email" value={email} onChange={handleChange}></input>
                    </div>
                    <div className="form-group w-100 my-2">
                        <label htmlFor="exampleInputPassword1">Password</label>
                        <input type="password" name="password" className="form-control" id="password" placeholder="Password" value={password} onChange={handleChange}></input>
                    </div>
                    <button type="submit" className="btn btn-primary my-4 align-self-end"><i className="fa fa-spinner fa-spin hideThis me-2"></i>Login</button>
                </form>
            </div>
        </>
    )
}

export default Login;