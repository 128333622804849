

const Stats = (props) => {

    const {recoReports, carrierABReports, carrierReports, delphisReports} = props;
    let a=0;
    let c=0;
    let i=0;
    recoReports.map((report) => {
        if(report.status==="Active"){
            a=a+1;
        }
        if(report.status==="Completed"){
            c=c+1;
        }
        if(report.status==="Invoiced"){
            i=i+1;
        }
        return true;
    });
    carrierABReports.map((report) => {
        if(report.status==="Active"){
            a=a+1;
        }
        if(report.status==="Completed"){
            c=c+1;
        }
        if(report.status==="Invoiced"){
            i=i+1;
        }
        return true;
    });
    carrierReports.map((report) => {
        if(report.status==="Active"){
            a=a+1;
        }
        if(report.status==="Completed"){
            c=c+1;
        }
        if(report.status==="Invoiced"){
            i=i+1;
        }
        return true;
    });
    delphisReports.map((report) => {
        if(report.status==="Active"){
            a=a+1;
        }
        if(report.status==="Completed"){
            c=c+1;
        }
        if(report.status==="Invoiced"){
            i=i+1;
        }
        return true;
    });
    return (
        <>
            <div className="row mt-5">
                <div className="col-md-3 m-auto text-center card shadow my-2">
                    <h2>Active Reports</h2>
                    <h4>{a}</h4>
                </div>
                <div className="col-md-3 m-auto text-center card shadow my-2">
                    <h2>Completed Reports</h2>
                    <h4>{c}</h4>
                </div>
                <div className="col-md-3 m-auto text-center card shadow my-2">
                    <h2>Invoiced Reports</h2>
                    <h4>{i}</h4>
                </div>
            </div>
        </>
    )
}

export default Stats;