//Imports
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SideBarMenu = () => {
    const { auth } = useSelector((state) => ({ ...state }));

    const active = window.location.pathname;

    return (
        <>
            <div className="bg_primary border-right" id="sidebar-wrapper">
                <div className="sidebar-heading m-2 text-center">
                    <h4 className="text-nowrap text_light my-0">{auth.name}</h4>
                </div>
                <div className="list-group list-group-flush my-3 text_font">
                    <Link className={`list-group-item list-group-item-action bg_primary ${active === "/dashboard" && "active"}`} to="/dashboard"><i className="fas fa-tachometer-alt fa-fw mx-2 sidebar_icons"></i>Dashboard</Link>

                    {/* CREATE */}
                    <Link className={`list-group-item list-group-item-action bg_primary ${active === "/createreport/delphis" && "active"} ${active === "/createreport/carrier" && "active"} ${active === "/createreport/carrierab" && "active"}`} to="/createreport/delphis"><i className="fas fa-plus-square fa-fw mx-2 sidebar_icons"></i>Create Report</Link>
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/createstore" && "active"}`} to="/createstore"><i className="fas fa-plus-circle fa-fw mx-2 sidebar_icons"></i>Create Store</Link>
                    }
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/createtechnician" && "active"}`} to="/createtechnician"><i className="fas fa-user-plus fa-fw mx-2 sidebar_icons"></i>Create Technician</Link>
                    }

                    {/* REPORTS */}
                    <Link className={`list-group-item list-group-item-action bg_primary ${active === "/activereports" && "active"}`} to="/activereports"><i className="fas fa-envelope-open fa-fw mx-2 sidebar_icons"></i>Active Reports</Link>
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/completedreports" && "active"}`} to="/completedreports"><i className="fas fa-envelope fa-fw mx-2 sidebar_icons"></i>Completed Reports</Link>
                    }
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/invoicedreports" && "active"}`} to="/invoicedreports"><i className="fas fa-file-invoice-dollar fa-fw mx-2 sidebar_icons"></i>Invoiced Reports</Link>
                    }

                    {/* STORE */}
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/stores" && "active"}`} to="/stores"><i className="fas fa-store fa-fw mx-2 sidebar_icons"></i>Stores</Link>
                    }
                    {/* TECHS */}
                    {auth && auth.role === 'Admin' &&
                        <Link className={`list-group-item list-group-item-action bg_primary ${active === "/technicians" && "active"}`} to="/technicians"><i className="fas fa-users-cog fa-fw mx-2 sidebar_icons"></i>Technicians</Link>
                    }
                    {/* <Link className={`list-group-item list-group-item-action bg_primary ${active === "/settings" && "active"}`} to="/settings"><i className="fas fa-cog fa-fw mx-2 sidebar_icons"></i>Settings</Link> */}
                </div>
            </div>

        </>
    )
}

export default SideBarMenu;