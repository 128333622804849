import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import carrierLogo from '../../dist/images/carrier.png';
import carrierIMG from '../../dist/images/base64/carrier';
import checkIMG from '../../dist/images/base64/check';
import blankIMG from '../../dist/images/base64/blank';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CarrierReport = ({ values, match, handleDelete }) => {

    const { auth } = useSelector((state) => ({ ...state }));

    const { pelatis, diefthinsi, thl, kwdikosKatastimatos, entoliPelati, onomatepwnymo, thlefwno, ypokatastima, kentriko, proforiki, thlefwnikh, grapth, arGnostopoihshs, arMitroou, entoliAgoras, wraKlhshs, wraMetavashs, wraAfikshs, diarkeiaErgasias, wraAnaxwrhshs, wraEpistrofhs, kmMetavashs, kmEpistrofhs, ergasia1, ergasia2, ergasia3, eggyhsh, xrewsh, aneuXrewshs, periodikoService, symvolaio, egkatastash, egkPwlhsh, anakainish, loipesErgasies, perigrafiVlavis, astoxiaSwlhna, xalarosSyndesmos, diatrhtosEksatmisths, diatrhtosSymphknwths, astoxiaSyggolhshs, kakhSterewsh, vraxykyklwmenhAntistash, vraxykyklwmenosAnemisthras, vlavhH_Arruthmistos, kamenesAsfaleies, VraxykyklwmenoRele, thermostathsXwrou, hlektronikosThermostaths, magnhtikhValvida, ektonwtikhValvida, thermostathsAsfaleias, klixonPortas, epidrashApoKlimatismo, epidrashApoReuma, apoxeteushKleisth, antistashApoxeteushs, pesmenhH_Xalasmenh, apwleiaFashs, ptwshH_Ypervash, thermomagnhtikos, vraxykyklwmenoRele2, hl_Prostasia, piessostaths, piessostaths2, antliaLadiou, elleipshLadiou, valvidaAforths, akatharisto, condenser, vraxykyklSymp, antikatastSymp, provlhmaDiktyo, provlhmaPLC, piesYpshlhs, piesXamhlhs, elleipshFreon3, elleipshLadiou3, akathElaio, akathCondenser, vraxyAnem, dysmeneis, allo0, perigrafiErgasion, date, status, ypografi1, ypografi2, ypografi3 } = values;

    const history = useHistory();

    let ypo1 = '';
    let ypo2 = '';
    let ypo3 = '';
    let proforikiIMG = '';
    let tilefonikiIMG = '';
    let graptiIMG = '';
    let ipokatastimaIMG = '';
    let kentrikoIMG = '';
    let eggyhshIMG = '';
    let xrewshIMG = '';
    let aneuXrewshsIMG = '';
    let periodikoServiceIMG = '';
    let symvolaioIMG = '';
    let egkatastashIMG = '';
    let egkPwlhshIMG = '';
    let anakainishIMG = '';
    let loipesErgasiesIMG = '';

    let astoxiaSwlhnaIMG = '';
    let xalarosSyndesmosIMG = '';
    let diatrhtosEksatmisthsIMG = '';
    let diatrhtosSymphknwthsIMG = '';
    let astoxiaSyggolhshsIMG = '';
    let kakhSterewshIMG = '';
    let vraxykyklwmenhAntistashIMG = '';
    let vraxykyklwmenosAnemisthrasIMG = '';
    let vlavhH_ArruthmistosIMG = '';
    let kamenesAsfaleiesIMG = '';
    let VraxykyklwmenoReleIMG = '';
    let thermostathsXwrouIMG = '';
    let hlektronikosThermostathsIMG = '';
    let magnhtikhValvidaIMG = '';
    let ektonwtikhValvidaIMG = '';
    let thermostathsAsfaleiasIMG = '';
    let klixonPortasIMG = '';
    let epidrashApoKlimatismoIMG = '';
    let epidrashApoReumaIMG = '';
    let apoxeteushKleisthIMG = '';
    let antistashApoxeteushsIMG = '';
    let pesmenhH_XalasmenhIMG = '';
    let apwleiaFashsIMG = '';
    let ptwshH_YpervashIMG = '';
    let thermomagnhtikosIMG = '';
    let vraxykyklwmenoRele2IMG = '';
    let hl_ProstasiaIMG = '';
    let piessostathsIMG = '';
    let piessostaths2IMG = '';
    let antliaLadiouIMG = '';
    let elleipshLadiouIMG = '';
    let valvidaAforthsIMG = '';
    let akatharistoIMG = '';
    let condenserIMG = '';
    let vraxykyklSympIMG = '';
    let antikatastSympIMG = '';
    let provlhmaDiktyoIMG = '';
    let provlhmaPLCIMG = '';
    let piesYpshlhsIMG = '';
    let piesXamhlhsIMG = '';
    let elleipshFreon3IMG = '';
    let elleipshLadiou3IMG = '';
    let akathElaioIMG = '';
    let akathCondenserIMG = '';
    let vraxyAnemIMG = '';
    let dysmeneisIMG = '';
    let allo0IMG = '';


    if (proforiki === 'on') {
        proforikiIMG = checkIMG;
    } else {
        proforikiIMG = blankIMG;
    }

    if (thlefwnikh === 'on') {
        tilefonikiIMG = checkIMG;
    } else {
        tilefonikiIMG = blankIMG;
    }

    if (grapth === 'on') {
        graptiIMG = checkIMG;
    } else {
        graptiIMG = blankIMG;
    }

    if (ypokatastima === 'on') {
        ipokatastimaIMG = checkIMG;
    } else {
        ipokatastimaIMG = blankIMG;
    }

    if (kentriko === 'on') {
        kentrikoIMG = checkIMG;
    } else {
        kentrikoIMG = blankIMG;
    }

    if (eggyhsh === 'on') {
        eggyhshIMG = checkIMG;
    } else {
        eggyhshIMG = blankIMG;
    }
    if (xrewsh === 'on') {
        xrewshIMG = checkIMG;
    } else {
        xrewshIMG = blankIMG;
    }
    if (aneuXrewshs === 'on') {
        aneuXrewshsIMG = checkIMG;
    } else {
        aneuXrewshsIMG = blankIMG;
    }
    if (periodikoService === 'on') {
        periodikoServiceIMG = checkIMG;
    } else {
        periodikoServiceIMG = blankIMG;
    }
    if (symvolaio === 'on') {
        symvolaioIMG = checkIMG;
    } else {
        symvolaioIMG = blankIMG;
    }
    if (egkatastash === 'on') {
        egkatastashIMG = checkIMG;
    } else {
        egkatastashIMG = blankIMG;
    }
    if (egkPwlhsh === 'on') {
        egkPwlhshIMG = checkIMG;
    } else {
        egkPwlhshIMG = blankIMG;
    }
    if (anakainish === 'on') {
        anakainishIMG = checkIMG;
    } else {
        anakainishIMG = blankIMG;
    }
    if (loipesErgasies === 'on') {
        loipesErgasiesIMG = checkIMG;
    } else {
        loipesErgasiesIMG = blankIMG;
    }

    if (astoxiaSwlhna === 'on') {
        astoxiaSwlhnaIMG = checkIMG;
    } else {
        astoxiaSwlhnaIMG = blankIMG;
    }
    if (xalarosSyndesmos === 'on') {
        xalarosSyndesmosIMG = checkIMG;
    } else {
        xalarosSyndesmosIMG = blankIMG;
    }
    if (diatrhtosEksatmisths === 'on') {
        diatrhtosEksatmisthsIMG = checkIMG;
    } else {
        diatrhtosEksatmisthsIMG = blankIMG;
    }
    if (diatrhtosSymphknwths === 'on') {
        diatrhtosSymphknwthsIMG = checkIMG;
    } else {
        diatrhtosSymphknwthsIMG = blankIMG;
    }
    if (astoxiaSyggolhshs === 'on') {
        astoxiaSyggolhshsIMG = checkIMG;
    } else {
        astoxiaSyggolhshsIMG = blankIMG;
    }
    if (kakhSterewsh === 'on') {
        kakhSterewshIMG = checkIMG;
    } else {
        kakhSterewshIMG = blankIMG;
    }
    if (vraxykyklwmenhAntistash === 'on') {
        vraxykyklwmenhAntistashIMG = checkIMG;
    } else {
        vraxykyklwmenhAntistashIMG = blankIMG;
    }
    if (vraxykyklwmenosAnemisthras === 'on') {
        vraxykyklwmenosAnemisthrasIMG = checkIMG;
    } else {
        vraxykyklwmenosAnemisthrasIMG = blankIMG;
    }
    if (vlavhH_Arruthmistos === 'on') {
        vlavhH_ArruthmistosIMG = checkIMG;
    } else {
        vlavhH_ArruthmistosIMG = blankIMG;
    }
    if (kamenesAsfaleies === 'on') {
        kamenesAsfaleiesIMG = checkIMG;
    } else {
        kamenesAsfaleiesIMG = blankIMG;
    }
    if (VraxykyklwmenoRele === 'on') {
        VraxykyklwmenoReleIMG = checkIMG;
    } else {
        VraxykyklwmenoReleIMG = blankIMG;
    }
    if (thermostathsXwrou === 'on') {
        thermostathsXwrouIMG = checkIMG;
    } else {
        thermostathsXwrouIMG = blankIMG;
    }
    if (hlektronikosThermostaths === 'on') {
        hlektronikosThermostathsIMG = checkIMG;
    } else {
        hlektronikosThermostathsIMG = blankIMG;
    }
    if (magnhtikhValvida === 'on') {
        magnhtikhValvidaIMG = checkIMG;
    } else {
        magnhtikhValvidaIMG = blankIMG;
    }
    if (ektonwtikhValvida === 'on') {
        ektonwtikhValvidaIMG = checkIMG;
    } else {
        ektonwtikhValvidaIMG = blankIMG;
    }
    if (thermostathsAsfaleias === 'on') {
        thermostathsAsfaleiasIMG = checkIMG;
    } else {
        thermostathsAsfaleiasIMG = blankIMG;
    }
    if (klixonPortas === 'on') {
        klixonPortasIMG = checkIMG;
    } else {
        klixonPortasIMG = blankIMG;
    }
    if (epidrashApoKlimatismo === 'on') {
        epidrashApoKlimatismoIMG = checkIMG;
    } else {
        epidrashApoKlimatismoIMG = blankIMG;
    }
    if (epidrashApoReuma === 'on') {
        epidrashApoReumaIMG = checkIMG;
    } else {
        epidrashApoReumaIMG = blankIMG;
    }
    if (apoxeteushKleisth === 'on') {
        apoxeteushKleisthIMG = checkIMG;
    } else {
        apoxeteushKleisthIMG = blankIMG;
    }
    if (antistashApoxeteushs === 'on') {
        antistashApoxeteushsIMG = checkIMG;
    } else {
        antistashApoxeteushsIMG = blankIMG;
    }
    if (pesmenhH_Xalasmenh === 'on') {
        pesmenhH_XalasmenhIMG = checkIMG;
    } else {
        pesmenhH_XalasmenhIMG = blankIMG;
    }
    if (apwleiaFashs === 'on') {
        apwleiaFashsIMG = checkIMG;
    } else {
        apwleiaFashsIMG = blankIMG;
    }
    if (ptwshH_Ypervash === 'on') {
        ptwshH_YpervashIMG = checkIMG;
    } else {
        ptwshH_YpervashIMG = blankIMG;
    }
    if (thermomagnhtikos === 'on') {
        thermomagnhtikosIMG = checkIMG;
    } else {
        thermomagnhtikosIMG = blankIMG;
    }
    if (vraxykyklwmenoRele2 === 'on') {
        vraxykyklwmenoRele2IMG = checkIMG;
    } else {
        vraxykyklwmenoRele2IMG = blankIMG;
    }
    if (hl_Prostasia === 'on') {
        hl_ProstasiaIMG = checkIMG;
    } else {
        hl_ProstasiaIMG = blankIMG;
    }
    if (piessostaths === 'on') {
        piessostathsIMG = checkIMG;
    } else {
        piessostathsIMG = blankIMG;
    }
    if (piessostaths2 === 'on') {
        piessostaths2IMG = checkIMG;
    } else {
        piessostaths2IMG = blankIMG;
    }
    if (antliaLadiou === 'on') {
        antliaLadiouIMG = checkIMG;
    } else {
        antliaLadiouIMG = blankIMG;
    }
    if (elleipshLadiou === 'on') {
        elleipshLadiouIMG = checkIMG;
    } else {
        elleipshLadiouIMG = blankIMG;
    }
    if (valvidaAforths === 'on') {
        valvidaAforthsIMG = checkIMG;
    } else {
        valvidaAforthsIMG = blankIMG;
    }
    if (akatharisto === 'on') {
        akatharistoIMG = checkIMG;
    } else {
        akatharistoIMG = blankIMG;
    }
    if (condenser === 'on') {
        condenserIMG = checkIMG;
    } else {
        condenserIMG = blankIMG;
    }
    if (vraxykyklSymp === 'on') {
        vraxykyklSympIMG = checkIMG;
    } else {
        vraxykyklSympIMG = blankIMG;
    }
    if (antikatastSymp === 'on') {
        antikatastSympIMG = checkIMG;
    } else {
        antikatastSympIMG = blankIMG;
    }
    if (provlhmaDiktyo === 'on') {
        provlhmaDiktyoIMG = checkIMG;
    } else {
        provlhmaDiktyoIMG = blankIMG;
    }
    if (provlhmaPLC === 'on') {
        provlhmaPLCIMG = checkIMG;
    } else {
        provlhmaPLCIMG = blankIMG;
    }
    if (piesYpshlhs === 'on') {
        piesYpshlhsIMG = checkIMG;
    } else {
        piesYpshlhsIMG = blankIMG;
    }
    if (piesXamhlhs === 'on') {
        piesXamhlhsIMG = checkIMG;
    } else {
        piesXamhlhsIMG = blankIMG;
    }
    if (elleipshFreon3 === 'on') {
        elleipshFreon3IMG = checkIMG;
    } else {
        elleipshFreon3IMG = blankIMG;
    }
    if (elleipshLadiou3 === 'on') {
        elleipshLadiou3IMG = checkIMG;
    } else {
        elleipshLadiou3IMG = blankIMG;
    }
    if (akathElaio === 'on') {
        akathElaioIMG = checkIMG;
    } else {
        akathElaioIMG = blankIMG;
    }
    if (akathCondenser === 'on') {
        akathCondenserIMG = checkIMG;
    } else {
        akathCondenserIMG = blankIMG;
    }
    if (vraxyAnem === 'on') {
        vraxyAnemIMG = checkIMG;
    } else {
        vraxyAnemIMG = blankIMG;
    }
    if (dysmeneis === 'on') {
        dysmeneisIMG = checkIMG;
    } else {
        dysmeneisIMG = blankIMG;
    }
    if (allo0 === 'on') {
        allo0IMG = checkIMG;
    } else {
        allo0IMG = blankIMG;
    }



    if (ypografi1) {
        ypo1 = ypografi1;
    } else {
        ypo1 = blankIMG;
    }
    if (ypografi2) {
        ypo2 = ypografi2;
    } else {
        ypo2 = blankIMG;
    }
    if (ypografi3) {
        ypo3 = ypografi3;
    } else {
        ypo3 = blankIMG;
    }
    const dd = {
        content: [
            {
                image: carrierIMG,
                fit: [80, 80],
                alignment: 'center',
            },
            {
                text: '\nΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ\n',
                style: 'header',
                alignment: 'center',
            },
            {
                alignment: 'center',
                text: `\nΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ\n`
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΠελάτης: \n`
                    },
                    {
                        text: `\n${pelatis}\n`
                    },
                    {
                        text: `\nΕντολή Πελάτη: \n`,
                    },
                    {
                        text: `\n${entoliPelati}\n`
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΔιεύθυνση: \n`
                    },
                    {
                        text: `\n${diefthinsi}\n`
                    },
                    {
                        text: `\nΟνοματεπώνυμο: \n`
                    },
                    {
                        text: `\n${onomatepwnymo}\n`
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΤηλ: \n`
                    },
                    {
                        text: `\n${thl}\n`
                    },
                    {
                        text: `\nΤηλέφωνο: \n`
                    },
                    {
                        text: `\n${thlefwno}\n`
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΚωδ. Καταστήματος: \n`
                    },
                    {
                        text: `\n${kwdikosKatastimatos}\n`
                    },
                    {
                        text: `\n \n`
                    },
                    {
                        text: `\n \n`
                    }
                ]
            },
            `\n`,
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Υποκατάστημα: `,
                    },
                    {
                        alignment: 'left',
                        image: ipokatastimaIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Κεντρικό: `,
                    },
                    {
                        alignment: 'left',
                        image: kentrikoIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: ` `,
                    },
                    {
                        alignment: 'right',
                        text: ` `,
                    }
                ]
            },
            `\n`,
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Προφορική: `,
                    },
                    {
                        alignment: 'left',
                        image: proforikiIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Τηλεφωνική: `,
                    },
                    {
                        alignment: 'left',
                        image: tilefonikiIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Γραπτή: `,
                    },
                    {
                        alignment: 'left',
                        image: graptiIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    }
                ]
            },
            `\n`,
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΑρ. Γνωστοποίησης/Ανάθεσης: ${arGnostopoihshs}`
                    },
                    {
                        text: `\nΑρ. Μητρώου: ${arMitroou}`
                    },
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΕντολή Αγοράς: ${entoliAgoras}`
                    },
                ]
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        [`Ώρα Κλήσης: `, `${wraKlhshs}`, `Ώρα Μετάβασης: `, `${wraMetavashs}`, `Ώρα Άφιξης: `, `${wraAfikshs}`],
                        [`Διάρκεια Εργασίας: `, `${diarkeiaErgasias}`, `Ώρα Αναχώρησης: `, `${wraAnaxwrhshs}`, `Ώρα Επιστροφής: `, `${wraEpistrofhs}`],
                        [`Ημ/νία: `, `${moment(date).format('DD-MM-YYYY')}`, `Km Μετάβασης: `, `${kmMetavashs}`, `Km Επιστροφής: `, `${kmEpistrofhs}`],
                    ]
                },
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        [`Τεχνικός 1: `, `${ergasia1}`],
                        [`Τεχνικός 2: `, `${ergasia2}`],
                        [`Τεχνικός 3: `, `${ergasia3}`],
                    ]
                },
            },
            `\n`,
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Εγγύηση: `,
                    },
                    {
                        alignment: 'left',
                        image: eggyhshIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Χρέωση: `,
                    },
                    {
                        alignment: 'left',
                        image: xrewshIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Άνευ Χρέωσης: `,
                    },
                    {
                        alignment: 'left',
                        image: aneuXrewshsIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    }
                ]
            },
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Περιοδικό Service: `,
                    },
                    {
                        alignment: 'left',
                        image: periodikoServiceIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Συμβόλαιο: `,
                    },
                    {
                        alignment: 'left',
                        image: symvolaioIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Εγκατάσταση: `,
                    },
                    {
                        alignment: 'left',
                        image: egkatastashIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    }
                ]
            },
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Εγκ./Πώληση: `,
                    },
                    {
                        alignment: 'left',
                        image: egkPwlhshIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Ανακαίνιση: `,
                    },
                    {
                        alignment: 'left',
                        image: anakainishIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    },
                    {
                        alignment: 'right',
                        text: `Λοιπές Εργασίες: `,
                    },
                    {
                        alignment: 'left',
                        image: loipesErgasiesIMG,
                        fit: [10, 10],
                        margin: [5, 0, 0, 0]
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΠεριγραφή βλάβης: ${perigrafiVlavis}`
                    }
                ]
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        [`Ανάλυση Εργασιών`, `Δ. Αποστολής`, ``, ``, ``, ``, ``, ``, ``],
                        [`10`, `Έλλειψη Φρέον`, ``, ``, ``, ``, ``, ``, ``],
                        [`01`, `Αστοχία σωλήνα ή εξαρτήματος`, { image: astoxiaSwlhnaIMG, fit: [10, 10] }, `02`, `Χαλαρός σύνδεσμος ή εξαρτήματα`, { image: xalarosSyndesmosIMG, fit: [10, 10] }, `03`, `Διάτρητος εξατμιστής`, { image: diatrhtosEksatmisthsIMG, fit: [10, 10] }],
                        [`04`, `Διάτρητος συμπυκνωτής`, {image: diatrhtosSymphknwthsIMG, fit: [10, 10]}, `05`, `Αστοχία συγκόλλησης`, {image: astoxiaSyggolhshsIMG, fit: [10, 10]}, `06`, `Κακή στερέωση δικτύου ή εξαρτήματος`, {image: kakhSterewshIMG, fit: [10, 10]}],
                        [`20`, `Παγωμένος Εξατμιστής ή Εξατμιστής Χωρίς Ψύξη`, ``, ``, ``, ``, ``, ``, ``],
                        [`01`, `Βραχυκυκλωμένη αντίσταση`, {image: vraxykyklwmenhAntistashIMG, fit: [10, 10]}, `02`, `Βραχυκυκλωμένος ανεμιστήρας εξατμιστή`, {image: vraxykyklwmenosAnemisthrasIMG, fit: [10, 10]}, `03`, `Βλάβη ή αρρύθμιστος χρονοδιακόπτης`, {image: vlavhH_ArruthmistosIMG, fit: [10, 10]}],
                        [`04`, `Καμένες ασφάλειες αντιστάσεων`, {image: kamenesAsfaleiesIMG, fit: [10, 10]}, `05`, `Βραχυκυκλωμένο ρελέ αντιστάσεων`, {image: VraxykyklwmenoReleIMG, fit: [10, 10]}, `06`, `Θερμοστάτης χώρου εκτός λειτουργίας`, {image: thermostathsXwrouIMG, fit: [10, 10]}],
                        [`07`, `Ηλεκτρονικός Θερμοστάτης χώρου χαλασμένος`, {image: hlektronikosThermostathsIMG, fit: [10, 10]}, `08`, `Μαγνητική βαλβίδα μόνιμα ανοικτή ή κλειστή`, {image: magnhtikhValvidaIMG, fit: [10, 10]}, `09`, `Εκτονωτική βαλβίδα αρρύθμιστη ή βλάβη`, {image: ektonwtikhValvidaIMG, fit: [10, 10]}],
                        [`10`, `Θερμοστάτης ασφαλείας ή κλίξον κόβουν νωρίς`, {image: thermostathsAsfaleiasIMG, fit: [10, 10]}, `11`, `Κλίξον πόρτας θαλάμου χαλασμένο`, {image: klixonPortasIMG, fit: [10, 10]}, `12`, `Επίδραση απο κλιματισμό`, {image: epidrashApoKlimatismoIMG, fit: [10, 10]}],
                        [`13`, `Επίδραση από ρεύμα αέρα`, {image: epidrashApoReumaIMG, fit: [10, 10]}, `14`, `Αποχέτευση κλειστή`, {image: apoxeteushKleisthIMG, fit: [10, 10]}, `15`, `Αντίσταση αποχέτευσης βραχ.`, {image: antistashApoxeteushsIMG, fit: [10, 10]}],
                        [`30`, `Συμπιεστής Εκτός Λειτουργίας`, ``, ``, ``, ``, ``, ``, ``],
                        [`01`, `Πεσμένη ή χαλασμένη ασφάλεια βοηθ. ή κύριου κυκλώμ.`, {image: pesmenhH_XalasmenhIMG, fit: [10, 10]}, `02`, `Απώλεια φάσης`, {image: apwleiaFashsIMG, fit: [10, 10]}, `03`, `Πτώση ή υπέρβαση ρεύματος`, {image: ptwshH_YpervashIMG, fit: [10, 10]}],
                        [`04`, `Θερμομαγνητικός διακόπτης εκτός λειτουργίας`, {image: thermomagnhtikosIMG, fit: [10, 10]}, `05`, `Βραχυκυκλωμένο ρελέ συμπιεστή`, {image: vraxykyklwmenoRele2IMG, fit: [10, 10]}, `06`, `Ηλ. προστασία (kriwan) εκτός λειτουργίας`, {image: hl_ProstasiaIMG, fit: [10, 10]}],
                        [`07`, `Πιεσσοστάτης υψηλής-χαμηλής εκτός λειτουργίας`, {image: piessostathsIMG, fit: [10, 10]}, `08`, `Πιεσσοστάτης λαδιού εκτός λειτουργίας`, {image: piessostaths2IMG, fit: [10, 10]}, `09`, `Αντλία λαδιού χαλασμένη`, {image: antliaLadiouIMG, fit: [10, 10]}],
                        [`10`, `Έλλειψη λαδιού στο κάρτερ του συμπιεστή`, {image: elleipshLadiouIMG, fit: [10, 10]}, `11`, `Βαλβίδα άφορτης κλείστη`, {image: valvidaAforthsIMG, fit: [10, 10]}, `12`, `Ακαθάριστο condenser`, {image: akatharistoIMG, fit: [10, 10]}],
                        [`13`, `Βραχυκυκλωμένος ανεμιστήρας condenser`, {image: condenserIMG, fit: [10, 10]}, `14`, `Βραχυκυκλωμένος συμπιεστής`, {image: vraxykyklSympIMG, fit: [10, 10]}, `15`, `Αντικατάσταση Συμπιεστή`, {image: antikatastSympIMG, fit: [10, 10]}],
                        [`40`, `MULTI Εκτός Λειτουργίας`, ``, ``, ``, ``, ``, ``, ``],
                        [`01`, `Πρόβλημα στο δίκτυο ηλεκτροδότησης`, {image: provlhmaDiktyoIMG, fit: [10, 10]}, `02`, `Πρόβλημα στο PLC`, {image: provlhmaPLCIMG, fit: [10, 10]}, `03`, ` Πιεσσοστάτης υψηλής εκτός λειτουργίας`, {image: piesYpshlhsIMG, fit: [10, 10]}],
                        [`04`, ` Πιεσσοστάτης χαμηλής εκτός λειτουργίας`, {image: piesXamhlhsIMG, fit: [10, 10]}, `05`, `Έλλειψη φρέον`, {image: elleipshFreon3IMG, fit: [10, 10]}, `06`, `Έλλειψη λαδιού στο κεντρικό κάρτερ`, {image: elleipshLadiou3IMG, fit: [10, 10]}],
                        [`07`, `Ακαθάριστος ελαιοδιαχωριστής`, {image: akathElaioIMG, fit: [10, 10]}, `08`, `Ακαθάριστο condenser`, {image: akathCondenserIMG, fit: [10, 10]}, `09`, `Βραχυκυκλωμένος ανεμιστήρας`, {image: vraxyAnemIMG, fit: [10, 10]}],
                        [`10`, `Δυσμενείς καιρικές συνθήκες`, {image: dysmeneisIMG, fit: [10, 10]}, `11`, `Άλλο`, {image: allo0IMG, fit: [10, 10]},``,``,``],
                    ]
                },
            },
            `\n`,
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΠεριγραφή εργασιών - Παρατηρήσεις: ${perigrafiErgasion}`
                    }
                ]
            },
            `\n`,
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\n\nΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ\n\n`,
                        alignment: 'center'
                    },
                    {
                        text: `\n\nΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ\n\n`,
                        alignment: 'center'
                    },
                    {
                        text: `\n\nΕΛΕΓΧΟΣ\n\n`,
                        alignment: 'center'
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        image: ypo1,
                        fit: [100, 100],
                        alignment: 'center',
                    },
                    {
                        image: ypo2,
                        fit: [100, 100],
                        alignment: 'center',
                    },
                    {
                        image: ypo3,
                        fit: [100, 100],
                        alignment: 'center',
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }
    }

    const downloadPDF = () => {
        pdfMake.createPdf(dd).download();
    }

    return (
        <>
            <div>
                <div className="row g-3 mb-5">
                    <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-center text-nowrap">
                        <img className="formLogo my-3" src={carrierLogo} alt="CarrierLogo" />
                        <h2 className="formTitle my-3">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                    </div>
                </div>

                {/* ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row py-2 text-center">
                        <h3>ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ</h3>
                    </div>
                    <div className="row py-2 border-bottom m-0">
                        <div className="col-xl-6">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΠΕΛΑΤΗΣ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{pelatis}</label>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΔΙΕΥΘΗΝΣΗ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{diefthinsi}</label>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΤΗΛ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{thl}</label>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΚΩΔ ΚΑΤΑΣΤΗΜΑΤΟΣ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{kwdikosKatastimatos}</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΕΝΤΟΛΗ ΠΕΛΑΤΗ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{entoliPelati}</label>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΟΝΟΜΑΤΕΠΩΝΥΜΟ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{onomatepwnymo}</label>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΤΗΛΕΦΩΝΟ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <label className="form-label">{thlefwno}</label>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row my-2">
                        <div className="col-lg-auto">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" >ΥΠΟΚΑΤΑΣΤΗΜΑ</label>
                                {ypokatastima === 'on' &&
                                    <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                                }
                            </div>
                        </div>
                        <div className="col-lg-auto">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" >ΚΕΝΤΡΙΚΟ</label>
                                {kentriko === 'on' &&
                                    <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row my-2 border-bottom m-0">
                        <div className="col-lg-4">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" >ΠΡΟΦΟΡΙΚΗ</label>
                                {proforiki === 'on' &&
                                    <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" >ΤΗΛΕΦΩΝΙΚΗ</label>
                                {thlefwnikh === 'on' &&
                                    <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                                }
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-check form-check-inline">
                                <label className="form-check-label" >ΓΡΑΠΤΗ</label>
                                {grapth === 'on' &&
                                    <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                                }
                            </div>
                        </div>
                    </div>


                    {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                    <div className="row pt-2 pb-2 mb-5 border-bottom m-0">
                        <div className="col-xl-6">
                            <label className="form-label mx-2">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                            <label className="form-label mx-2">{arGnostopoihshs}</label>
                        </div>
                        <div className="col-xl-6">
                            <label className="form-label mx-2">Αρ. Μητρώου:</label>
                            <label className="form-label mx-2">{arMitroou}</label>
                        </div>
                        <div className="col-xl-6">
                            <label className="form-label mx-2">Εντολή Αγοράς:</label>
                            <label className="form-label mx-2">{entoliAgoras}</label>
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΩΡΑ ΚΛΗΣΗΣ:</label>
                            <label className="form-label">{wraKlhshs}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΩΡΑ ΜΕΤΑΒΑΣΗΣ:</label>
                            <label className="form-label">{wraMetavashs}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΩΡΑ ΑΦΙΞΗΣ:</label>
                            <label className="form-label">{wraAfikshs}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΔΙΑΡΚΕΙΑ ΕΡΓΑΣΙΑΣ:</label>
                            <label className="form-label">{diarkeiaErgasias}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΩΡΑ ΑΝΑΧΩΡΗΣΗΣ:</label>
                            <label className="form-label">{wraAnaxwrhshs}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">ΩΡΑ ΕΠΙΣΤΡΟΦΗΣ:</label>
                            <label className="form-label">{wraEpistrofhs}</label>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-4">Ημ/νια:</label>
                            <label className="form-label">{moment(date).format('DD-MM-YYYY')}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">Km ΜΕΤΑΒΑΣΗΣ:</label>
                            <label className="form-label">{kmMetavashs}</label>
                        </div>
                        <div className="col-lg-auto m-auto">
                            <label className="form-label m-3">Km ΕΠΙΣΤΡΟΦΗΣ:</label>
                            <label className="form-label">{kmEpistrofhs}</label>
                        </div>
                    </div>

                    <div className="row py-2 border-bottom m-0">
                        <div className="col-lg-4 my-2">
                            <label className="form-label m-2">Τεχνικός 1:</label>
                            <label className="form-label">{ergasia1}</label>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label m-2">Τεχνικός 2:</label>
                            <label className="form-label">{ergasia2}</label>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label m-2">Τεχνικός 3:</label>
                            <label className="form-label">{ergasia3}</label>
                        </div>
                    </div>


                    {/* CHECKBOXES FEATURE ONLY FOR ADMIN */}
                    {auth.role === 'Admin' &&
                        <div className="row my-2">
                            <div className="col-lg-12 text-center">
                                <div className="form-check form-check-inline mx-2">
                                    {eggyhsh === 'on' &&
                                        <input className="form-check-input" name="eggyhsh" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Εγγύηση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {xrewsh === 'on' &&
                                        <input className="form-check-input" name="xrewsh" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Χρέωση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {aneuXrewshs === 'on' &&
                                        <input className="form-check-input" name="aneuXrewshs" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Άνευ Χρέωσης</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {periodikoService === 'on' &&
                                        <input className="form-check-input" name="periodikoService" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Περιοδικό Service</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {symvolaio === 'on' &&
                                        <input className="form-check-input" name="symvolaio" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Συμβόλαιο</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {egkatastash === 'on' &&
                                        <input className="form-check-input" name="egkatastash" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Εγκατάσταση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {egkPwlhsh === 'on' &&
                                        <input className="form-check-input" name="egkPwlhsh" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Εγκ./Πώληση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {anakainish === 'on' &&
                                        <input className="form-check-input" name="anakainish" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Ανακαίνιση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    {loipesErgasies === 'on' &&
                                        <input className="form-check-input" name="loipesErgasies" type="checkbox" checked ></input>
                                    }
                                    <label className="form-check-label" >Λοιπές Εργασίες</label>
                                </div>
                            </div>
                        </div>
                    }

                    {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ */}
                    <div className="row py-2 my-3">
                        <div className="col-xl-2 m-auto">
                            <label className="form-label">Περιγραφή Βλάβης:</label>
                        </div>
                        <div className="col-xl-10 m-auto">
                            <label className="form-label">{perigrafiVlavis}</label>
                        </div>
                    </div>
                </div>

                {/* ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5">
                    <div className="row p-0 m-auto">
                        <div className="col-md-8 border border-1 border-dark text-center">
                            <label className="form-label">ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ</label>
                        </div>
                        <div className="col-md-4 border border-1 border-dark text-left">
                            <label className="form-label">Δ. Αποστολής</label>
                        </div>
                    </div>

                    {/* 10 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">10</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΕΛΛΕΙΨΗ ΦΡΕΟΝ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {astoxiaSwlhna === 'on' &&
                                <input className="form-check-input" name="astoxiaSwlhna" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αστοχία σωλήνα <br></br> ή εξαρτήματος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {xalarosSyndesmos === 'on' &&
                                <input className="form-check-input" name="xalarosSyndesmos" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Χαλαρός σύνδεσμος <br></br> ή εξαρτήματα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {diatrhtosEksatmisths === 'on' &&
                                <input className="form-check-input" name="diatrhtosEksatmisths" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Διάτρητος εξατμιστής</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {diatrhtosSymphknwths === 'on' &&
                                <input className="form-check-input" name="diatrhtosSymphknwths" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Διάτρητος συμπυκνωτής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {astoxiaSyggolhshs === 'on' &&
                                <input className="form-check-input" name="astoxiaSyggolhshs" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αστοχία συγκόλλησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {kakhSterewsh === 'on' &&
                                <input className="form-check-input" name="kakhSterewsh" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Κακή στερέωση δικτύου <br></br> ή εξαρτήματος</label>
                        </div>
                    </div>

                    {/* 20 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">20</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΠΑΓΩΜΕΝΟΣ ΕΞΑΤΜΙΣΤΗΣ ή ΕΞΑΤΜΙΣΤΗΣ ΧΩΡΙΣ ΨΥΞΗ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vraxykyklwmenhAntistash === 'on' &&
                                <input className="form-check-input" name="vraxykyklwmenhAntistash" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένη αντίσταση</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vraxykyklwmenosAnemisthras === 'on' &&
                                <input className="form-check-input" name="vraxykyklwmenosAnemisthras" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένος <br></br> ανεμιστήρας εξατμιστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vlavhH_Arruthmistos === 'on' &&
                                <input className="form-check-input" name="vlavhH_Arruthmistos" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βλάβη ή αρρύθμιστος <br></br> χρονοδιακόπτης</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {kamenesAsfaleies === 'on' &&
                                <input className="form-check-input" name="kamenesAsfaleies" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Καμένες ασφάλειες <br></br> αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {VraxykyklwmenoRele === 'on' &&
                                <input className="form-check-input" name="VraxykyklwmenoRele" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένο <br></br> ρελέ αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {thermostathsXwrou === 'on' &&
                                <input className="form-check-input" name="thermostathsXwrou" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Θερμοστάτης χώρου <br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {hlektronikosThermostaths === 'on' &&
                                <input className="form-check-input" name="hlektronikosThermostaths" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Ηλεκτρονικός Θερμοστάτης <br></br> χώρου χαλασμένος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {magnhtikhValvida === 'on' &&
                                <input className="form-check-input" name="magnhtikhValvida" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Μαγνητική βαλβίδα <br></br> μόνιμα ανοικτή ή κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {ektonwtikhValvida === 'on' &&
                                <input className="form-check-input" name="ektonwtikhValvida" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Εκτονωτική βαλβίδα <br></br> αρρύθμιστη ή βλάβη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {thermostathsAsfaleias === 'on' &&
                                <input className="form-check-input" name="thermostathsAsfaleias" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Θερμοστάτης ασφαλείας <br></br> ή κλίξον κόβουν νωρίς</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {klixonPortas === 'on' &&
                                <input className="form-check-input" name="klixonPortas" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Κλίξον πόρτας θαλάμου<br></br> χαλασμένο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {epidrashApoKlimatismo === 'on' &&
                                <input className="form-check-input" name="epidrashApoKlimatismo" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Επίδραση απο κλιματισμό</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {epidrashApoReuma === 'on' &&
                                <input className="form-check-input" name="epidrashApoReuma" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Επίδραση από ρεύμα αέρα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {apoxeteushKleisth === 'on' &&
                                <input className="form-check-input" name="apoxeteushKleisth" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αποχέτευση κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {antistashApoxeteushs === 'on' &&
                                <input className="form-check-input" name="antistashApoxeteushs" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αντίσταση αποχέτευσης <br></br> βραχ.</label>
                        </div>
                    </div>

                    {/* 30 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">30</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΣΥΜΠΙΕΣΤΗΣ ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {pesmenhH_Xalasmenh === 'on' &&
                                <input className="form-check-input" name="pesmenhH_Xalasmenh" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πεσμένη ή χαλασμένη ασφάλεια βοηθ. ή κύριου κυκλώμ.</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {apwleiaFashs === 'on' &&
                                <input className="form-check-input" name="apwleiaFashs" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Απώλεια φάσης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {ptwshH_Ypervash === 'on' &&
                                <input className="form-check-input" name="ptwshH_Ypervash" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πτώση ή υπέρβαση ρεύματος</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {thermomagnhtikos === 'on' &&
                                <input className="form-check-input" name="thermomagnhtikos" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Θερμομαγνητικός διακόπτης<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vraxykyklwmenoRele2 === 'on' &&
                                <input className="form-check-input" name="vraxykyklwmenoRele2" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένο ρελέ<br></br> συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {hl_Prostasia === 'on' &&
                                <input className="form-check-input" name="hl_Prostasia" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Ηλ. προστασία (kriwan)<br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {piessostaths === 'on' &&
                                <input className="form-check-input" name="piessostaths" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πιεσσοστάτης υψηλής-χαμηλής<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {piessostaths2 === 'on' &&
                                <input className="form-check-input" name="piessostaths2" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πιεσσοστάτης λαδιού εκτός <br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {antliaLadiou === 'on' &&
                                <input className="form-check-input" name="antliaLadiou" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αντλία λαδιού χαλασμένη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {elleipshLadiou === 'on' &&
                                <input className="form-check-input" name="elleipshLadiou" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Έλλειψη λαδιού στο κάρτερ<br></br> του συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {valvidaAforths === 'on' &&
                                <input className="form-check-input" name="valvidaAforths" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βαλβίδα άφορτης κλείστη</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {akatharisto === 'on' &&
                                <input className="form-check-input" name="akatharisto" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {condenser === 'on' &&
                                <input className="form-check-input" name="condenser" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένος ανεμιστήρας condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vraxykyklSymp === 'on' &&
                                <input className="form-check-input" name="vraxykyklSymp" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένος συμπιεστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {antikatastSymp === 'on' &&
                                <input className="form-check-input" name="antikatastSymp" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Αντικατάσταση Συμπιεστή</label>
                        </div>
                    </div>

                    {/* 40 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">40</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">MULTI ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {provlhmaDiktyo === 'on' &&
                                <input className="form-check-input" name="provlhmaDiktyo" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πρόβλημα στο δίκτυο<br></br> ηλεκτροδότησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {provlhmaPLC === 'on' &&
                                <input className="form-check-input" name="provlhmaPLC" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πρόβλημα στο PLC</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {piesYpshlhs === 'on' &&
                                <input className="form-check-input" name="piesYpshlhs" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πιεσσοστάτης υψηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {piesXamhlhs === 'on' &&
                                <input className="form-check-input" name="piesXamhlhs" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Πιεσσοστάτης χαμηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {elleipshFreon3 === 'on' &&
                                <input className="form-check-input" name="elleipshFreon3" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Έλλειψη φρέον</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {elleipshLadiou3 === 'on' &&
                                <input className="form-check-input" name="elleipshLadiou3" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Έλλειψη λαδιού στο κεντρικό<br></br> κάρτερ</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {akathElaio === 'on' &&
                                <input className="form-check-input" name="akathElaio" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Ακαθάριστος<br></br> ελαιοδιαχωριστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {akathCondenser === 'on' &&
                                <input className="form-check-input" name="akathCondenser" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {vraxyAnem === 'on' &&
                                <input className="form-check-input" name="vraxyAnem" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Βραχυκυκλωμένος<br></br> ανεμιστήρας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {dysmeneis === 'on' &&
                                <input className="form-check-input" name="dysmeneis" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Δυσμενείς καιρικές συνθήκες</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            {allo0 === 'on' &&
                                <input className="form-check-input" name="allo0" type="checkbox" checked ></input>
                            }
                            <label className="form-label">Άλλο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <label className="form-label"></label>
                        </div>
                    </div>


                </div>


                {/* ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ - ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-12">
                        <label className="form-label mx-2">Περιγραφή Εργασιών - Παρατηρήσεις:</label>
                        <label className="form-label mx-2">{perigrafiErgasion}</label>
                    </div>
                </div>

                {/* ΤΕΛΙΚΟ ΣΥΝΟΛΟ */}
                <div className="row formBox py-2 g-3 mb-5">
                    <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-4 text-center">
                            <label className="form-label">Status</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{status}</label>
                        </div>
                    </div>
                </div>

                {/* ΥΠΟΓΡΑΦΕΣ */}
                <div className="row formBox py-2 g-3 mb-5">
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ</label>
                        {ypografi1 ?
                            <img src={ypografi1} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ</label>
                        {ypografi2 ?
                            <img src={ypografi2} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΕΛΕΓΧΟΣ</label>
                        {ypografi3 ?
                            <img src={ypografi3} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                </div>

            </div>
            <div className="row formBox my-5 ">
                <div className="d-flex justify-content-end p-0">
                    {/* PDF GENERATOR DOWNLOAD */}
                    <button className="btn btn-warning mx-2" onClick={downloadPDF}><i className="fas fa-file-download"></i></button>

                    <button className="btn btn-primary ms-2" onClick={() => history.push(`/carrierReport/edit/${match.params.reportId}`)}>Edit</button>

                    {/* DELETE FEATURE ONLY FOR ADMIN */}
                    {auth.role === 'Admin' &&
                        <button type="button" className="btn btn-primary ms-4 btn-danger" onClick={handleDelete}>Delete</button>
                    }
                </div>
            </div>
        </>
    )
};

export default CarrierReport;