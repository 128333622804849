//Imports
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import $ from 'jquery';

const Header = () => {

    //const { auth } = useSelector((state) => ({ ...state }));
    //const {user} = auth;

    const dispatch = useDispatch();
    const history = useHistory();

    const logout = () => {
        dispatch({
            type: "LOGOUT",
            payload: null,
        });
        window.localStorage.removeItem("auth");
        history.push("/");
    }


    const handleToggle = () => {
        $("#wrapper").toggleClass("toggled");
    }
    return (
        <>
            <header className="navbar bg_primary sticky-top p-0 shadow d-flex align-items-center">
                    <div className="w-25 d-flex align-items-center">
                        <button className="btn" id="menu-toggle" onClick={handleToggle}><i className="fas fa-bars fa-fw sidebar_icons"></i></button>
                    </div>
                    <div className="w-50 text-center d-flex align-items-center justify-content-center">
                        <Link to="#" className="navbar-brand mx-3 text_light">Damage Report</Link>
                    </div>
                    <div className="w-25 d-flex align-items-center justify-content-end">
                        <button className="nav-link btn text_light" onClick={logout}>Sign out</button>
                    </div>
            </header>

            {/* 
            <header className="navbar bg_primary sticky-top flex-md-nowrap p-0 shadow">
                <button className="btn" id="menu-toggle" onClick={handleToggle}><i className="fas fa-bars fa-fw sidebar_icons"></i></button>
                <div className="w-100"></div>
                <Link to="#" className="navbar-brand col-md-3 col-lg-2 mx-3 text_light">Damage Report</Link>
                <div className="w-100"></div>
                <h4 className="text-nowrap text_light my-0">{user}</h4>
                <ul className="navbar-nav px-3">
                    <li className="nav-item text-nowrap">
                        <button className="nav-link btn text_light" onClick={logout}>Sign out</button>
                    </li>
                </ul>
            </header>
             */}
        </>
    )
}

export default Header;