import {Link} from 'react-router-dom';


const FormsHeader = () => {

    const active = window.location.pathname;
    return (

        <ul  className="nav nav-tabs">
            <li  className="nav-item">
                <Link className={`nav-link ${active === "/createreport/delphis" && "active"}`} to="/createreport/delphis">Delphis</Link>
            </li>
            <li className="nav-item">
                <Link className={`nav-link ${active === "/createreport/carrier" && "active"}`} to="/createreport/carrier">Carrier</Link>
            </li>
            <li className="nav-item">
                <Link className={`nav-link ${active === "/createreport/carrierab" && "active"}`} to="/createreport/carrierab">Carrier AB</Link>
            </li>
            <li className="nav-item">
                <Link className={`nav-link ${active === "/createreport/reco" && "active"}`} to="/createreport/reco">Reco</Link>
            </li>
        </ul>

    );
};

export default FormsHeader;