//Imports
import React, { useEffect, useState } from 'react';

//Import Compontents
import Footer from '../components/admin/Footer';
import Header from '../components/admin/Header';
import InnerHeader from '../components/admin/InnerHeader';
import SideBarMenu from '../components/admin/SideBarMenu';
import Stats from '../components/admin/Stats';

//Import Actions
import { readRecoReports, readCarrierReports, readCarrierABReports, readDelphisReports } from '../actions/reports';

const Dashboard = () => {

    const [recoReports, setRecoReports] = useState([]);
    const [carrierReports, setCarrierReports] = useState([]);
    const [carrierABReports, setCarrierABReports] = useState([]);
    const [delphisReports, setDelphisReports] = useState([]);

    useEffect(() => {
        const loadAllRecoReports = async () => {
            let res = await readRecoReports();
            setRecoReports(res.data);
        }

        const loadAllCarrierReports = async () => {
            let res = await readCarrierReports();
            setCarrierReports(res.data);
        }

        const loadAllCarrierABReports = async () => {
            let res = await readCarrierABReports();
            setCarrierABReports(res.data);
        }

        const loadAllDelphisReports = async () => {
            let res = await readDelphisReports();
            setDelphisReports(res.data);
        }

        loadAllDelphisReports();
        loadAllCarrierABReports();
        loadAllRecoReports();
        loadAllCarrierReports();
    }, []);

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Dashboard" />
                        <Stats recoReports={recoReports} carrierReports={carrierReports} carrierABReports={carrierABReports} delphisReports={delphisReports}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default Dashboard;