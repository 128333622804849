//Imports
import React, { useEffect, useState } from 'react';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import FilterReports from '../components/admin/FilterReports';
import ActiveRecoReports from '../components/admin/ActiveRecoReports';
import ActiveCarrierABReports from '../components/admin/ActiveCarrierABReports';
import ActiveCarrierReports from '../components/admin/ActiveCarrierReports';
import ActiveDelphisReports from '../components/admin/ActiveDelphisReports';

//Import Actions
import { readRecoReports, readCarrierReports, readCarrierABReports, readDelphisReports } from '../actions/reports';


const ActiveReports = () => {

    const [recoReports, setRecoReports] = useState([]);
    const [carrierReports, setCarrierReports] = useState([]);
    const [carrierABReports, setCarrierABReports] = useState([]);
    const [delphisReports, setDelphisReports] = useState([]);

    const [dateFilter, setDateFilter] = useState(["", ""]);
    const [katastimaFilter, setKatastimaFilter] = useState("");
    const [texnikosFilter, setTexnikosFilter] = useState("");

    const handleDateFilter = (value, dateString) => {
        setDateFilter(dateString);
    }

    const handleKatastimaSearchFilter = (value) => {
        setKatastimaFilter(value);
    }

    const handleTexnikosSearchFilter = (value) => {
        setTexnikosFilter(value);
    }

    const handleClear = () => {
        setDateFilter(["", ""]);
        setKatastimaFilter("");
        setTexnikosFilter("");
    }

    useEffect(() => {
        const loadAllRecoReports = async () => {
            let res = await readRecoReports();
            setRecoReports(res.data);
        }

        const loadAllCarrierReports = async () => {
            let res = await readCarrierReports();
            setCarrierReports(res.data);
        }

        const loadAllCarrierABReports = async () => {
            let res = await readCarrierABReports();
            setCarrierABReports(res.data);
        }

        const loadAllDelphisReports = async () => {
            let res = await readDelphisReports();
            setDelphisReports(res.data);
        }

        loadAllDelphisReports();
        loadAllCarrierABReports();
        loadAllRecoReports();
        loadAllCarrierReports();
    }, []);

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Active Reports" />
                        <FilterReports dateFilter={dateFilter} katastimaFilter={katastimaFilter} setKatastimaFilter={setKatastimaFilter} texnikosFilter={texnikosFilter} setTexnikosFilter={setTexnikosFilter} handleDateFilter={handleDateFilter} handleKatastimaSearchFilter={handleKatastimaSearchFilter} handleTexnikosSearchFilter={handleTexnikosSearchFilter} handleClear={handleClear}/>
                        <ActiveRecoReports recoReports={recoReports} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <ActiveCarrierABReports carrierABReports={carrierABReports} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <ActiveDelphisReports delphisReports={delphisReports} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                        <ActiveCarrierReports carrierReports={carrierReports} dateFilter={dateFilter} katastimaFilter={katastimaFilter} texnikosFilter={texnikosFilter}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default ActiveReports;