//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//import {readDelphisReport, readDelphisReport, readDelphisReport, readDelphisReport} from '../actions/reports';
import { readDelphisReport, deleteDelphisReport } from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import DelphisReport from '../components/admin/DelphisReport';

const SingleDelphisReport = ({ match }) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const history = useHistory();

    const [values, setValues] = useState({
        ekgkatastash: "",
        synthrhsh: "",
        ektakth: "",
        episkeuh: "",
        katastima: "",
        arGnostopoihshs: "",
        arMitroou: "",
        entoliAgoras: "",
        perigrafiVlavis: "",
        iliko1: "",
        iliko2: "",
        iliko3: "",
        kostosMonadas1: 0,
        kostosMonadas2: 0,
        kostosMonadas3: 0,
        epimetrisi1: 0,
        epimetrisi2: 0,
        epimetrisi3: 0,
        kostos1: 0,
        kostos2: 0,
        kostos3: 0,
        sunolikoKostosIlikon: 0,
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        kostosAnaOra1: 0,
        kostosAnaOra2: 0,
        kostosAnaOra3: 0,
        oresErgasias1: 0,
        oresErgasias2: 0,
        oresErgasias3: 0,
        kostosErgasias1: 0,
        kostosErgasias2: 0,
        kostosErgasias3: 0,
        sunolikoKostosErgasias: 0,
        alles1: "",
        alles2: "",
        alles3: "",
        allesKostos1: 0,
        allesKostos2: 0,
        allesKostos3: 0,
        allesSinolikoKostos: 0,
        sinolikoKostosProFpa: 0,
        apo: "",
        eos: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    useEffect(() => {
        loadRequest();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readDelphisReport(match.params.reportId);
        setValues({ ...values, ...res.data[0] });
    }

    const handleDelete = async () => {
        try {
            if (!window.confirm('Are you sure?')) return;
            let res = await deleteDelphisReport(token, match.params.reportId);
            console.log("Report Deleted RES:", res);

            setTimeout(() => {
                history.push('/dashboard');
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`Delphis Report ID: ${match.params.reportId}`} />
                        <DelphisReport values={values} match={match} handleDelete={handleDelete}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default SingleDelphisReport;