//Imports
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//Import Actions
import { readStores, deleteStore } from '../actions/stores';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import StoresDisplay from '../components/admin/StoresDisplay';

const Stores = () => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const [stores, setStores] = useState([]);

    useEffect(() => {

        const loadAllStores = async () => {
            let res = await readStores(token);
            setStores(res.data);
        };


        loadAllStores();
        // eslint-disable-next-line
    }, []);

    const handleDelete = async (store) => {
        try {
            if (!window.confirm('Are you sure?')) return;
            let res = await deleteStore(token, store.id);
            console.log("Store Deleted RES:", res);

            setTimeout(() => {
                window.location.reload();
            }, 500);

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Stores" />
                        <StoresDisplay stores={stores} handleDelete={handleDelete}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default Stores;