
const RegisterStoreForm = ({ handleChange, handleSubmit }) => {

    return (
        <>
            <div className="w-100">
                <div className="row justify-content-center my-5 mx-0">
                    <div className="col-md-3">
                        <form method="POST" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label name="name" className="form-label">Store</label>
                                <input name="name" type="name" className="form-control" id="name" aria-describedby="nameHelp" onChange={handleChange} required></input>
                            </div>
                            <button type="submit" className="btn btn-primary">Add</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterStoreForm;