//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//import {readCarrierReport, readCarrierReport, readCarrierReport, readDelphisReport} from '../actions/reports';
import { readCarrierReport, deleteCarrierReport } from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import CarrierReport from '../components/admin/CarrierReport';

const SingleCarrierReport = ({ match }) => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;

    const history = useHistory();

    const [values, setValues] = useState({
        pelatis: "",
        diefthinsi: "",
        thl: "",
        kwdikosKatastimatos: "",
        entoliPelati: "",
        onomatepwnymo: "",
        thlefwno: "",
        ypokatastima: "",
        kentriko: "",
        proforiki: "",
        thlefwnikh: "",
        grapth: "",
        arGnostopoihshs: "",
        arMitroou: "",
        entoliAgoras: "",
        wraKlhshs: "",
        wraMetavashs: "",
        wraAfikshs: "",
        diarkeiaErgasias: "",
        wraAnaxwrhshs: "",
        wraEpistrofhs: "",
        kmMetavashs: "",
        kmEpistrofhs: "",
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        eggyhsh: "",
        xrewsh: "",
        aneuXrewshs: "",
        periodikoService: "",
        symvolaio: "",
        egkatastash: "",
        egkPwlhsh: "",
        anakainish: "",
        loipesErgasies: "",
        perigrafiVlavis: "",
        astoxiaSwlhna: "",
        xalarosSyndesmos: "",
        diatrhtosEksatmisths: "",
        diatrhtosSymphknwths: "",
        astoxiaSyggolhshs: "",
        kakhSterewsh: "",
        vraxykyklwmenhAntistash: "",
        vraxykyklwmenosAnemisthras: "",
        vlavhH_Arruthmistos: "",
        kamenesAsfaleies: "",
        VraxykyklwmenoRele: "",
        thermostathsXwrou: "",
        hlektronikosThermostaths: "",
        magnhtikhValvida: "",
        ektonwtikhValvida: "",
        thermostathsAsfaleias: "",
        klixonPortas: "",
        epidrashApoKlimatismo: "",
        epidrashApoReuma: "",
        apoxeteushKleisth: "",
        antistashApoxeteushs: "",
        pesmenhH_Xalasmenh: "",
        apwleiaFashs: "",
        ptwshH_Ypervash: "",
        thermomagnhtikos: "",
        vraxykyklwmenoRele2: "",
        hl_Prostasia: "",
        piessostaths: "",
        piessostaths2: "",
        antliaLadiou: "",
        elleipshLadiou: "",
        valvidaAforths: "",
        akatharisto: "",
        condenser: "",
        vraxykyklSymp: "",
        antikatastSymp: "",
        provlhmaDiktyo: "",
        provlhmaPLC: "",
        piesYpshlhs: "",
        piesXamhlhs: "",
        elleipshFreon3: "",
        elleipshLadiou3: "",
        akathElaio: "",
        akathCondenser: "",
        vraxyAnem: "",
        dysmeneis: "",
        allo0: "",
        perigrafiErgasion: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    useEffect(() => {
        loadRequest();

        // eslint-disable-next-line
    }, []);

    const loadRequest = async () => {
        let res = await readCarrierReport(match.params.reportId);
        setValues({ ...values, ...res.data[0] });
    }

    const handleDelete = async () => {
        try {
            if (!window.confirm('Are you sure?')) return;
            let res = await deleteCarrierReport(token, match.params.reportId);
            console.log("Report Deleted RES:", res);

            setTimeout(() => {
                history.push('/dashboard');
            }, 1000);

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title={`Carrier Report ID: ${match.params.reportId}`} />
                        <CarrierReport values={values} match={match} handleDelete={handleDelete}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default SingleCarrierReport;