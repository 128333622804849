import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

//Material UI
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
//import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

function createData(id, arGnostopoihshs, arMitroou, ergasia1, katastima, sinolikoKostosProFpa, date, status, action) {
    return { id, arGnostopoihshs, arMitroou, ergasia1, katastima, sinolikoKostosProFpa, date, status, action };
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
    { id: 'arGnostopoihshs', numeric: true, disablePadding: false, label: 'Αρ. Γνωστοποίησης' },
    { id: 'arMitroou', numeric: true, disablePadding: false, label: 'Αρ. Μητρώου' },
    { id: 'ergasia1', numeric: false, disablePadding: false, label: 'Τεχνικός' },
    { id: 'katastima', numeric: false, disablePadding: false, label: 'Κατάστημα' },
    { id: 'sinolikoKostosProFpa', numeric: false, disablePadding: false, label: 'Κόστος' },
    { id: 'date', numeric: true, disablePadding: false, label: 'Ημ/νία' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'action', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    //const { onSelectAllClick, numSelected, rowCount } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                    Nutrition
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 100,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const InvoicedCarrierABReports = (props) => {

    const { dateFilter, katastimaFilter, texnikosFilter, } = props;
    const { carrierABReports } = props;
    const history = useHistory();

    const dateFormat = 'DD-MM-YYYY';

    let rows = [];

    carrierABReports.map((report, i) => {
        if (report.status === "Invoiced") {
            if (dateFilter[0] && dateFilter[1]) {
                if (moment(report.date).format(dateFormat) >= dateFilter[0] && moment(report.date).format(dateFormat) <= dateFilter[1]) {
                    if (katastimaFilter && texnikosFilter) {
                        if (report.katastima.toLowerCase().includes(katastimaFilter) && report.ergasia1.toLowerCase().includes(katastimaFilter)) {
                            let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                            rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                        }
                    } else if (katastimaFilter) {
                        if (report.katastima.toLowerCase().includes(katastimaFilter)) {
                            let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                            rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                        }
                    } else if (texnikosFilter) {
                        if (report.ergasia1.toLowerCase().includes(texnikosFilter)) {
                            let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                            rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                        }
                    } else {
                        let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                        rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                    }
                }
            } else if (katastimaFilter && texnikosFilter) {
                if (report.katastima.toLowerCase().includes(katastimaFilter) && report.ergasia1.toLowerCase().includes(texnikosFilter)) {
                    let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                    rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                }
            } else if (katastimaFilter) {
                console.log(report.katastima);
                if (report.katastima.toLowerCase().includes(katastimaFilter)) {
                    let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                    rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                }
            } else if (texnikosFilter) {
                if (report.ergasia1.toLowerCase().includes(texnikosFilter)) {
                    let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                    rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
                }
            } else {
                let temp = <button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button>;
                rows.push(createData(report.id, report.arGnostopoihshs, report.arMitroou, report.ergasia1, report.katastima, report.sinolikoKostosProFpa, report.date, report.status, temp),);
            }
        }
        return true;
    });

    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    // eslint-disable-next-line
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // eslint-disable-next-line
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // const handleSelectAllClick = (event) => {
    //     if (event.target.checked) {
    //         const newSelecteds = rows.map((n) => n.name);
    //         setSelected(newSelecteds);
    //         return;
    //     }
    //     setSelected([]);
    // };

    // const handleClick = (event, name) => {
    //     const selectedIndex = selected.indexOf(name);
    //     let newSelected = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, name);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(
    //             selected.slice(0, selectedIndex),
    //             selected.slice(selectedIndex + 1),
    //         );
    //     }

    //     setSelected(newSelected);
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const handleChangeDense = (event) => {
    //     setDense(event.target.checked);
    // };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <>
            <div className="row justify-content-center mx-0 my-5">
                <div className="col-xl-auto p-3">
                    <div id="no-more-tables">
                        <div className="text-center border-bottom">
                            <h3>Carrier AB Reports</h3>
                        </div>
                        {/*
                        <table className="table table-striped table-hover" >
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Αρ. Γνωστοποίησης</th>
                                    <th scope="col">Αρ. Μητρώου</th>
                                    <th scope="col">Κατάστημα</th>
                                    <th scope="col">Περιγραφή Βλάβης</th>
                                    <th scope="col">Ημ/νία</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {carrierABReports.map((report, i) => {
                                    return (
                                        <>
                                            {report.status === "Invoiced" &&
                                                <tr key={report.id}>
                                                    <th data-title="ID" scope="row">{report.id}</th>
                                                    <td data-title="Αρ. Γνωστοποίησης">{report.arGnostopoihshs}</td>
                                                    <td data-title="Αρ. Μητρώου">{report.arMitroou}</td>
                                                    <td data-title="Κατάστημα">{report.katastima}</td>
                                                    <td data-title="Περιγραφή Βλάβης">{report.sinolikoKostosProFpa}</td>
                                                    <td data-title="Ημ/νία">{moment(report.date).format('DD-MM-YYYY')}</td>
                                                    <td data-title="Status">{report.status}</td>
                                                    <td data-title="Action"><button className="btn bg_primary" onClick={() => { history.push(`/readCarrierABReport/${report.id}`); }}><i className="fas fa-edit"></i></button></td>
                                                </tr>
                                            }
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                        */}

                        <div className={classes.root}>
                            <Paper className={classes.paper}>
                                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            //onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.name);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            //onClick={(event) => handleClick(event, row.name)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                        >
                                                            {/* <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                /> 
                                                            </TableCell>*/}
                                                            <TableCell data-title="ID" component="th" id={labelId} scope="row" align="right" padding="none">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell data-title="Αρ. Γνωστοποίησης" align="right">{row.arGnostopoihshs}</TableCell>
                                                            <TableCell data-title="Αρ. Μητρώου" align="right">{row.arMitroou}</TableCell>
                                                            <TableCell data-title="Τεχνικός" align="right">{row.ergasia1}</TableCell>
                                                            <TableCell data-title="Κατάστημα" align="right">{row.katastima}</TableCell>
                                                            <TableCell data-title="Κόστος" align="right">{`${row.sinolikoKostosProFpa} €`}</TableCell>
                                                            <TableCell data-title="Ημ/νία" align="right">{moment(row.date).format('DD-MM-YYYY')}</TableCell>
                                                            <TableCell data-title="Status" align="right">{row.status}</TableCell>
                                                            <TableCell data-title="Action" align="right">{row.action}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                            {/* <FormControlLabel
                                control={<Switch checked={dense} onChange={handleChangeDense} />}
                                //label="Dense padding"
                                label="Smaller"
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoicedCarrierABReports;