//Imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';

//Import Actions
import { readStores } from '../actions/stores';
import { readUsers } from '../actions/users';
import { createCarrierABReport } from '../actions/reports';

//Import Compontents
import Header from '../components/admin/Header';
import Footer from '../components/admin/Footer';
import SideBarMenu from '../components/admin/SideBarMenu';
import InnerHeader from '../components/admin/InnerHeader';
import FormsHeader from '../components/admin/FormsHeader';
import CarrierABForm from '../components/Forms/CarrierABForm';

const CreateReportCarrierAB = () => {

    const { auth } = useSelector((state) => ({ ...state }));
    const { token } = auth;
    const history = useHistory();

    const [values, setValues] = useState({
        katastima: "",
        arGnostopoihshs: "",
        arMitroou: "",
        entoliAgoras: "",
        perigrafiVlavis: "",
        iliko1: "",
        iliko2: "",
        iliko3: "",
        kostosMonadas1: 0,
        kostosMonadas2: 0,
        kostosMonadas3: 0,
        epimetrisi1: 0,
        epimetrisi2: 0,
        epimetrisi3: 0,
        kostos1: 0,
        kostos2: 0,
        kostos3: 0,
        sunolikoKostosIlikon: 0,
        ergasia1: "",
        ergasia2: "",
        ergasia3: "",
        kostosAnaOra1: 0,
        kostosAnaOra2: 0,
        kostosAnaOra3: 0,
        oresErgasias1: 0,
        oresErgasias2: 0,
        oresErgasias3: 0,
        kostosErgasias1: 0,
        kostosErgasias2: 0,
        kostosErgasias3: 0,
        sunolikoKostosErgasias: 0,
        alles1: "",
        alles2: "",
        alles3: "",
        allesKostos1: 0,
        allesKostos2: 0,
        allesKostos3: 0,
        allesSinolikoKostos: 0,
        sinolikoKostosProFpa: 0,
        apo: "",
        eos: "",
        date: "",
        status: "",
        ypografi1: "",
        ypografi2: "",
        ypografi3: "",
    });

    const [users, setUsers] = useState([]);
    const [stores, setStores] = useState([]);

    const { katastima, arGnostopoihshs, arMitroou, entoliAgoras, perigrafiVlavis, iliko1, iliko2, iliko3, kostosMonadas1, kostosMonadas2, kostosMonadas3, epimetrisi1, epimetrisi2, epimetrisi3,kostos1, kostos2, kostos3, sunolikoKostosIlikon, ergasia1, ergasia2, ergasia3, kostosAnaOra1, kostosAnaOra2, kostosAnaOra3, oresErgasias1, oresErgasias2, oresErgasias3, kostosErgasias1, kostosErgasias2, kostosErgasias3, sunolikoKostosErgasias, alles1, alles2, alles3, allesKostos1, allesKostos2, allesKostos3, allesSinolikoKostos, sinolikoKostosProFpa, apo, eos, date, status, ypografi1, ypografi2, ypografi3 } = values;

    useEffect(() => {

        const loadAllUsers = async () => {
            let res = await readUsers(token);
            setUsers(res.data);
        };

        const loadAllStores = async () => {
            let res = await readStores(token);
            setStores(res.data);
        };


        loadAllUsers();
        loadAllStores();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            let temp;
            if(!(status==="Completed") && !(status==="Invoiced")){
                temp = 'Active';
            }else{
                temp = status;
            }

            const data = {
                katastima: katastima,
                arGnostopoihshs: arGnostopoihshs,
                arMitroou: arMitroou,
                entoliAgoras: entoliAgoras,
                perigrafiVlavis: perigrafiVlavis,
                iliko1: iliko1,
                iliko2: iliko2,
                iliko3: iliko3,
                kostosMonadas1: kostosMonadas1,
                kostosMonadas2: kostosMonadas2,
                kostosMonadas3: kostosMonadas3,
                epimetrisi1: epimetrisi1,
                epimetrisi2: epimetrisi2,
                epimetrisi3: epimetrisi3,
                kostos1: kostos1,
                kostos2: kostos2,
                kostos3: kostos3,
                sunolikoKostosIlikon: sunolikoKostosIlikon,
                ergasia1: ergasia1,
                ergasia2: ergasia2,
                ergasia3: ergasia3,
                kostosAnaOra1: kostosAnaOra1,
                kostosAnaOra2: kostosAnaOra2,
                kostosAnaOra3: kostosAnaOra3,
                oresErgasias1: oresErgasias1,
                oresErgasias2: oresErgasias2,
                oresErgasias3: oresErgasias3,
                kostosErgasias1: kostosErgasias1,
                kostosErgasias2: kostosErgasias2,
                kostosErgasias3: kostosErgasias3,
                sunolikoKostosErgasias: sunolikoKostosErgasias,
                alles1: alles1,
                alles2: alles2,
                alles3: alles3,
                allesKostos1: allesKostos1,
                allesKostos2: allesKostos2,
                allesKostos3: allesKostos3,
                allesSinolikoKostos: allesSinolikoKostos,
                sinolikoKostosProFpa: sinolikoKostosProFpa,
                apo: apo,
                eos: eos,
                date: date,
                status: temp,
                ypografi1: ypografi1,
                ypografi2: ypografi2,
                ypografi3: ypografi3,
            }

            const res = await createCarrierABReport(token, data);
            console.log("CarrierAB Report Created", res);

            setTimeout(() => {
                history.push('/dashboard');
                //window.location.reload();
            }, 500);

        } catch (err) {
            console.log(err);
        }
    }

    const handleDate = (dates, dateStrings) => {
        setValues({ ...values, date: dateStrings });
    }
    const handleApo = (time, timeString) => {
        setValues({ ...values, apo: timeString });
    }
    const handleEos = (time, timeString) => {
        setValues({ ...values, eos: timeString });
    }
    const handleSelect = (value) => {
        setValues({ ...values, status: value });
    }
    const handleSelectKatastima = (value) => {
        setValues({ ...values, katastima: value });
    }
    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value});
    }

    return (
        <>
            <div className="d-flex" id="wrapper">
                <SideBarMenu />
                <div id="page-content-wrapper">
                    <main className="min-vh-100">
                        <Header />
                        <InnerHeader title="Create Report" />
                        <FormsHeader />
                        <CarrierABForm users={users} stores={stores} auth={auth} handleChange={handleChange} handleApo={handleApo} handleEos={handleEos} handleSelect={handleSelect} handleSelectKatastima={handleSelectKatastima} handleDate={handleDate} handleSubmit={handleSubmit} values={values} setValues={setValues}/>
                    </main>

                    <Footer />

                </div>
            </div>
        </>
    )
}

export default CreateReportCarrierAB;