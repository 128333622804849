import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import DelphisLogo from '../../dist/images/Delphis.png';
import delphisIMG from '../../dist/images/base64/delphis';
import checkIMG from '../../dist/images/base64/check';
import blankIMG from '../../dist/images/base64/blank';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const DelphisReport = ({ values, match, handleDelete }) => {

    const { auth } = useSelector((state) => ({ ...state }));

    const { ekgkatastash, synthrhsh, ektakth, episkeuh, katastima, arGnostopoihshs, arMitroou, entoliAgoras, perigrafiVlavis, iliko1, iliko2, iliko3, kostosMonadas1, kostosMonadas2, kostosMonadas3, epimetrisi1, epimetrisi2, epimetrisi3, kostos1, kostos2, kostos3, sunolikoKostosIlikon, ergasia1, ergasia2, ergasia3, kostosAnaOra1, kostosAnaOra2, kostosAnaOra3, oresErgasias1, oresErgasias2, oresErgasias3, kostosErgasias1, kostosErgasias2, kostosErgasias3, sunolikoKostosErgasias, alles1, alles2, alles3, allesKostos1, allesKostos2, allesKostos3, allesSinolikoKostos, sinolikoKostosProFpa, apo, eos, date, status, ypografi1, ypografi2, ypografi3 } = values;

    const history = useHistory();

    let ypo1 = '';
    let ypo2 = '';
    let ypo3 = '';
    let egkIMG='';
    let syntIMG='';
    let ektIMG='';
    let epiIMG='';

    if(ekgkatastash==='on') {
        egkIMG = checkIMG;
    }else{
        egkIMG = blankIMG;
    }

    if(synthrhsh==='on') {
        syntIMG = checkIMG;
    }else{
        syntIMG = blankIMG;
    }

    if(ektakth==='on') {
        ektIMG = checkIMG;
    }else{
        ektIMG = blankIMG;
    }

    if(episkeuh==='on') {
        epiIMG = checkIMG;
    }else{
        epiIMG = blankIMG;
    }

    if (ypografi1) {
        ypo1 = ypografi1;
    } else {
        ypo1 = blankIMG;
    }
    if (ypografi2) {
        ypo2 = ypografi2;
    } else {
        ypo2 = blankIMG;
    }
    if (ypografi3) {
        ypo3 = ypografi3;
    } else {
        ypo3 = blankIMG;
    }

    const dd = {
        content: [
            {
                image: delphisIMG,
                fit: [80, 80],
                alignment: 'center',
            },
            {
                text: '\nΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ\n',
                style: 'header',
                alignment: 'center',
            },
            {
                alignment: 'center',
                text: `\nΚατάστημα: ${katastima}\n`
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΑπό: ${apo}\n`
                    },
                    {
                        text: `\nΈως: ${eos}\n`
                    },
                    {
                        text: `\nΗμ/νία: ${moment(date).format('DD-MM-YYYY')}\n`
                    },
                ]
            },
            `\n`,
            {
                columns: [
                    {
                        alignment: 'right',
                        text: `Εγκατάσταση: `,
                    },
                    {
                        alignment: 'left',
                        image: egkIMG,
                        fit: [10, 10],
                        margin: [ 5, 0, 0, 0 ]
                    },
                    {
                        alignment: 'right',
                        text: `Συντήρηση: `,
                    },
                    {
                        alignment: 'left',
                        image: syntIMG,
                        fit: [10, 10],
                        margin: [ 5, 0, 0, 0 ]
                    },
                    {
                        alignment: 'right',
                        text: `Έκτακτη: `,
                    },
                    {
                        alignment: 'left',
                        image: ektIMG,
                        fit: [10, 10],
                        margin: [ 5, 0, 0, 0 ]
                    },
                    {
                        alignment: 'right',
                        text: `Επισκευή: `,
                    },
                    {
                        alignment: 'left',
                        image: epiIMG,
                        fit: [10, 10],
                        margin: [ 5, 0, 0, 0 ]
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΑρ. Γνωστοποίησης/Ανάθεσης: ${arGnostopoihshs}`
                    },
                    {
                        text: `\nΑρ. Μητρώου: ${arMitroou}`
                    },
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΕντολή Αγοράς: ${entoliAgoras}`
                    },
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\nΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ*: ${perigrafiVlavis}`
                    }
                ]
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        ['ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ', 'ΚΟΣΤΟΣ ΜΟΝΑΔΑΣ', 'ΕΠΙΜΕΤΡΗΣΗ', 'ΚΟΣΤΟΣ'],
                        [`${iliko1}`, `${kostosMonadas1}`, `${epimetrisi1}`, `${kostos1}`],
                        [`${iliko2}`, `${kostosMonadas2}`, `${epimetrisi2}`, `${kostos2}`],
                        [`${iliko3}`, `${kostosMonadas3}`, `${epimetrisi3}`, `${kostos3}`],
                        [``, ``, `Σύνολο:`, `${sunolikoKostosIlikon}`],
                    ]
                },
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        ['ΕΡΓΑΣΙΑ', 'ΚΟΣΤΟΣ/ΩΡΑ', 'ΩΡΕΣ ΕΡΓ.', 'ΚΟΣΤΟΣ'],
                        [`${ergasia1}`, `${kostosAnaOra1}`, `${oresErgasias1}`, `${kostosErgasias1}`],
                        [`${ergasia2}`, `${kostosAnaOra2}`, `${oresErgasias2}`, `${kostosErgasias2}`],
                        [`${ergasia3}`, `${kostosAnaOra3}`, `${oresErgasias3}`, `${kostosErgasias3}`],
                        [``, ``, `Σύνολο:`, `${sunolikoKostosErgasias}`],
                    ]
                },
            },
            `\n`,
            {
                style: '',
                table: {
                    body: [
                        ['ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ', 'ΚΟΣΤΟΣ'],
                        [`${alles1}`, `${allesKostos1}`],
                        [`${alles2}`, `${allesKostos1}`],
                        [`${alles3}`, `${allesKostos1}`],
                        [`Σύνολο:`, `${allesSinolikoKostos}`],
                    ]
                },
            },
            {
                alignment: 'left',
                text: `\nΤΕΛΙΚΟ ΣΥΝΟΛΟ ΠΡΟ Φ.Π.Α: ${sinolikoKostosProFpa}\n\n`
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: `\n\nΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ\n\n`,
                        alignment: 'center'
                    },
                    {
                        text: `\n\nΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ\n\n`,
                        alignment: 'center'
                    },
                    {
                        text: `\n\nΕΛΕΓΧΟΣ\n\n`,
                        alignment: 'center'
                    }
                ]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        image: ypo1,
                        fit: [100, 100],
                        alignment: 'center',
                    },
                    {
                        image: ypo2,
                        fit: [100, 100],
                        alignment: 'center',
                    },
                    {
                        image: ypo3,
                        fit: [100, 100],
                        alignment: 'center',
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }
    }

    const downloadPDF = () => {
        pdfMake.createPdf(dd).download();
    }

    return (
        <>
            <div>
                <div className="row g-3 mb-5">
                    <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-center text-nowrap">
                        <img className="formLogo my-3" src={DelphisLogo} alt="DelphisLogo" />
                        <h2 className="formTitle my-3">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                    </div>
                </div>

                {/* ΕΝΤΟΛΗ ΣΥΝΤΗΡΗΣΗΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-xl-5">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <label className="form-label">Κατάστημα:</label>
                            </div>
                            <div className="col-xl-8">
                                <label className="form-label">{katastima}</label>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-2">
                        <label className="form-label mx-2">Από:</label>
                        <label className="form-label">{apo}</label>
                    </div>
                    <div className="col-xl-2">
                        <label className="form-label mx-2">Έως:</label>
                        <label className="form-label">{eos}</label>
                    </div>
                    <div className="col-xl-3">
                        <label className="form-label mx-2">Ημ/νια:</label>
                        <label className="form-label">{moment(date).format('DD-MM-YYYY')}</label>
                    </div>
                </div>

                {/* Checkboxes */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-3">
                        <div className="form-check form-check-inline mx-5">
                            {ekgkatastash === 'on' &&
                                <input className="form-check-input" name="ekgkatastash" type="checkbox" checked></input>
                            }
                            <label className="form-check-label">Εγκατάσταση</label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-check form-check-inline mx-5">
                            {synthrhsh === 'on' &&
                                <input className="form-check-input" name="synthrhsh" type="checkbox" checked></input>
                            }
                            <label className="form-check-label">Συντήρηση</label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-check form-check-inline mx-5">
                            {ektakth === 'on' &&
                                <input className="form-check-input" name="ektakth" type="checkbox" checked></input>
                            }
                            <label className="form-check-label">Έκτακτη</label>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="form-check form-check-inline mx-5">
                            {episkeuh === 'on' &&
                                <input className="form-check-input" name="episkeuh" type="checkbox" checked></input>
                            }
                            <label className="form-check-label">Επισκευή</label>
                        </div>
                    </div>
                </div>

                {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-xl-6">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <label className="form-label">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                            </div>
                            <div className="col-xl-8">
                                <label className="form-label">{arGnostopoihshs}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <label className="form-label">Αρ. Μητρώου:</label>
                            </div>
                            <div className="col-xl-8">
                                <label className="form-label">{arMitroou}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="row align-items-center">
                            <div className="col-xl-4">
                                <label className="form-label">Εντολή Αγοράς:</label>
                            </div>
                            <div className="col-xl-8">
                                <label className="form-label">{entoliAgoras}</label>
                            </div>
                        </div>
                    </div>

                </div>

                {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-3">
                                <label className="form-label">ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ / ΕΡΓΑΣΙΑΣ *:</label>
                            </div>
                            <div className="col-lg-9">
                                <label className="form-label">{perigrafiVlavis}</label>
                            </div>
                        </div>

                    </div>
                </div>

                {/* ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">ΧΡΗΣΙΜΟΠΟΙΗΘΕΝΤΑ ΥΛΙΚΑ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΚΟΣΤΟΣ ΜΟΝΑΔΑΣ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΕΠΙΜΕΤΡΗΣΗ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΚΟΣΤΟΣ</label>

                        </div>
                    </div>

                    {/* Dublicate the following row for extra fields */}
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{iliko1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosMonadas1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{epimetrisi1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostos1}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{iliko2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosMonadas2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{epimetrisi2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostos2}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{iliko3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosMonadas3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{epimetrisi3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostos3}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8"></div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΣΥΝΟΛΟ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{sunolikoKostosIlikon}</label>
                        </div>
                    </div>



                </div>

                {/* ΕΡΓΑΣΙΑ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">ΕΡΓΑΣΙΑ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΚΟΣΤΟΣ/ΩΡΑ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΩΡΕΣ ΕΡΓ.</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΚΟΣΤΟΣ</label>

                        </div>
                    </div>

                    {/* Dublicate the following row for extra fields */}
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{ergasia1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosAnaOra1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{oresErgasias1}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosErgasias1}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{ergasia2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosAnaOra2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{oresErgasias2}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosErgasias2}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label className="form-label">{ergasia3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosAnaOra3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{oresErgasias3}</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{kostosErgasias3}</label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8"></div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΣΥΝΟΛΟ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{sunolikoKostosErgasias}</label>
                        </div>
                    </div>



                </div>

                {/* ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row">
                        <div className="col-lg-10">
                            <label className="form-label">ΑΛΛΕΣ ΧΡΕΩΣΕΙΣ / ΠΑΡΑΤΗΡΗΣΕΙΣ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΚΟΣΤΟΣ</label>

                        </div>
                    </div>

                    {/* Dublicate the following row for extra fields */}
                    <div className="row">
                        <div className="col-lg-10">
                            <label className="form-label">{alles1}</label>                            </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{allesKostos1}</label>                            </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10">
                            <label className="form-label">{alles2}</label>                            </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{allesKostos2}</label>                            </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-10">
                            <label className="form-label">{alles3}</label>                            </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{allesKostos3}</label>                            </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8"></div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">ΣΥΝΟΛΟ</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{allesSinolikoKostos}</label>                            </div>
                    </div>
                </div>

                {/* ΤΕΛΙΚΟ ΣΥΝΟΛΟ */}
                <div className="row formBox py-2 g-3 mb-5">
                    <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-4 text-center">
                            <label className="form-label">ΤΕΛΙΚΟ ΣΥΝΟΛΟ ΠΡΟ Φ.Π.Α</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{sinolikoKostosProFpa}</label>                            </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6"></div>
                        <div className="col-lg-4 text-center">
                            <label className="form-label">Status</label>
                        </div>
                        <div className="col-lg-2 text-center">
                            <label className="form-label">{status}</label>
                        </div>
                    </div>
                </div>

                {/* ΥΠΟΓΡΑΦΕΣ */}
                <div className="row formBox py-2 g-3 mb-5">
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ</label>
                        {ypografi1 ?
                            <img src={ypografi1} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ</label>
                        {ypografi2 ?
                            <img src={ypografi2} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΕΛΕΓΧΟΣ</label>
                        {ypografi3 ?
                            <img src={ypografi3} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                    </div>
                </div>

            </div>
            <div className="row formBox my-5 ">
                <div className="d-flex justify-content-end p-0">
                    {/* PDF GENERATOR DOWNLOAD */}
                    <button className="btn btn-warning mx-2" onClick={downloadPDF}><i className="fas fa-file-download"></i></button>

                    <button className="btn btn-primary ms-2" onClick={() => history.push(`/delphisReport/edit/${match.params.reportId}`)}>Edit</button>

                    {/* DELETE FEATURE ONLY FOR ADMIN */}
                    {auth.role === 'Admin' &&
                        <button type="button" className="btn btn-primary ms-4 btn-danger" onClick={handleDelete}>Delete</button>
                    }
                </div>
            </div>
        </>
    )
};

export default DelphisReport;