import moment from 'moment';
//antd
import { DatePicker, Input } from 'antd';

const { RangePicker } = DatePicker;
const { Search } = Input;

const FilterReports = (props) => {

    const { dateFilter, katastimaFilter, setKatastimaFilter, texnikosFilter, setTexnikosFilter } = props;
    const { handleDateFilter, handleKatastimaSearchFilter, handleTexnikosSearchFilter, handleClear } = props;

    const dateFormat = 'DD-MM-YYYY';

    return (
        <>
            <div className="row justify-content-center mx-0 my-5">
                <div className="col-xl-auto p-3">
                    <div className="filtering mb-1 border-bottom shadow p-3">
                        <h5 className="text-center">Αναζήτηση</h5>
                        <div className="row">
                            <div className="col-lg-2 my-2 d-flex align-items-center">
                                <RangePicker placeholder={['Από', 'Έως']} id="clearDate" className="w-100" format={dateFormat} value={moment(dateFilter, dateFormat)} onChange={handleDateFilter} />
                            </div>
                            <div className="col-lg-4 my-2 d-flex align-items-center">
                                <Search id="clearKatastima" placeholder="Αναζήτηση Καταστήματος" value={katastimaFilter} onChange={(e) => { setKatastimaFilter(e.target.value) }} onSearch={handleKatastimaSearchFilter} enterButton />
                            </div>
                            <div className="col-lg-4 my-2 d-flex align-items-center">
                                <Search id="clearTexniko" placeholder="Αναζήτηση Τεχνικού" value={texnikosFilter} onChange={(e) => { setTexnikosFilter(e.target.value) }} onSearch={handleTexnikosSearchFilter} enterButton />
                            </div>
                            <div className="col-lg-2 my-2 d-flex align-items-center justify-content-end">
                                <button className="btn btn-danger" onClick={handleClear}>Καθάρισμα</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterReports