//Imports
import { TimePicker, DatePicker, Select } from 'antd';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Popup from 'reactjs-popup';

//import moment from 'moment';
import carrierLogo from '../../dist/images/carrier.png';

const format = 'HH:mm';
const { Option } = Select;

const EditCarrierForm = (props) => {

    const { users, stores, values, setValues, match, auth } = props;
    const { handleSubmit, handleDate, handleSelect, handleSelectKatastima, handleChange, handleWraKlhshs, handleWraMetavashs, handleWraAfikshs, handleDiarkeiaErgasias, handleWraAnaxwrhshs, handleWraEpistrofhs } = props;
    const { pelatis, diefthinsi, thl, kwdikosKatastimatos, entoliPelati, onomatepwnymo, thlefwno, ypokatastima, kentriko, proforiki, thlefwnikh, grapth, arGnostopoihshs, arMitroou, entoliAgoras, kmMetavashs, kmEpistrofhs, ergasia1, ergasia2, ergasia3, eggyhsh, xrewsh, aneuXrewshs, periodikoService, symvolaio, egkatastash, egkPwlhsh, anakainish, loipesErgasies, perigrafiVlavis, astoxiaSwlhna, xalarosSyndesmos, diatrhtosEksatmisths, diatrhtosSymphknwths, astoxiaSyggolhshs, kakhSterewsh, vraxykyklwmenhAntistash, vraxykyklwmenosAnemisthras, vlavhH_Arruthmistos, kamenesAsfaleies, VraxykyklwmenoRele, thermostathsXwrou, hlektronikosThermostaths, magnhtikhValvida, ektonwtikhValvida, thermostathsAsfaleias, klixonPortas, epidrashApoKlimatismo, epidrashApoReuma, apoxeteushKleisth, antistashApoxeteushs, pesmenhH_Xalasmenh, apwleiaFashs, ptwshH_Ypervash, thermomagnhtikos, vraxykyklwmenoRele2, hl_Prostasia, piessostaths, piessostaths2, antliaLadiou, elleipshLadiou, valvidaAforths, akatharisto, condenser, vraxykyklSymp, antikatastSymp, provlhmaDiktyo, provlhmaPLC, piesYpshlhs, piesXamhlhs, elleipshFreon3, elleipshLadiou3, akathElaio, akathCondenser, vraxyAnem, dysmeneis, allo0, perigrafiErgasion, status, ypografi1, ypografi2, ypografi3 } = values;

    const history = useHistory();

    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const save1 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        console.log(temp);
        setValues({ ...values, ypografi1: temp });
    }

    const save2 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setValues({ ...values, ypografi2: temp });
    }

    const save3 = () => {
        let temp = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setValues({ ...values, ypografi3: temp });
    }

    if (ypokatastima === 'on') document.getElementById("ypokatastima").checked = true;
    if (kentriko === 'on') document.getElementById("kentriko").checked = true;
    if (proforiki === 'on') document.getElementById("proforiki").checked = true;
    if (thlefwnikh === 'on') document.getElementById("thlefwnikh").checked = true;
    if (grapth === 'on') document.getElementById("grapth").checked = true;

    if (eggyhsh === 'on') document.getElementById("eggyhsh").checked = true;
    if (xrewsh === 'on') document.getElementById("xrewsh").checked = true;
    if (aneuXrewshs === 'on') document.getElementById("aneuXrewshs").checked = true;
    if (periodikoService === 'on') document.getElementById("periodikoService").checked = true;
    if (symvolaio === 'on') document.getElementById("symvolaio").checked = true;
    if (egkatastash === 'on') document.getElementById("egkatastash").checked = true;
    if (egkPwlhsh === 'on') document.getElementById("egkPwlhsh").checked = true;
    if (anakainish === 'on') document.getElementById("anakainish").checked = true;
    if (loipesErgasies === 'on') document.getElementById("loipesErgasies").checked = true;


    if (astoxiaSwlhna === 'on') document.getElementById("astoxiaSwlhna").checked = true;
    if (xalarosSyndesmos === 'on') document.getElementById("xalarosSyndesmos").checked = true;
    if (diatrhtosEksatmisths === 'on') document.getElementById("diatrhtosEksatmisths").checked = true;
    if (diatrhtosSymphknwths === 'on') document.getElementById("diatrhtosSymphknwths").checked = true;
    if (astoxiaSyggolhshs === 'on') document.getElementById("astoxiaSyggolhshs").checked = true;
    if (kakhSterewsh === 'on') document.getElementById("kakhSterewsh").checked = true;
    if (vraxykyklwmenhAntistash === 'on') document.getElementById("vraxykyklwmenhAntistash").checked = true;
    if (vraxykyklwmenosAnemisthras === 'on') document.getElementById("vraxykyklwmenosAnemisthras").checked = true;
    if (vlavhH_Arruthmistos === 'on') document.getElementById("vlavhH_Arruthmistos").checked = true;
    if (kamenesAsfaleies === 'on') document.getElementById("kamenesAsfaleies").checked = true;
    if (VraxykyklwmenoRele === 'on') document.getElementById("VraxykyklwmenoRele").checked = true;
    if (thermostathsXwrou === 'on') document.getElementById("thermostathsXwrou").checked = true;
    if (hlektronikosThermostaths === 'on') document.getElementById("hlektronikosThermostaths").checked = true;
    if (magnhtikhValvida === 'on') document.getElementById("magnhtikhValvida").checked = true;
    if (ektonwtikhValvida === 'on') document.getElementById("ektonwtikhValvida").checked = true;
    if (thermostathsAsfaleias === 'on') document.getElementById("thermostathsAsfaleias").checked = true;
    if (klixonPortas === 'on') document.getElementById("klixonPortas").checked = true;
    if (epidrashApoKlimatismo === 'on') document.getElementById("epidrashApoKlimatismo").checked = true;
    if (epidrashApoReuma === 'on') document.getElementById("epidrashApoReuma").checked = true;
    if (apoxeteushKleisth === 'on') document.getElementById("apoxeteushKleisth").checked = true;
    if (antistashApoxeteushs === 'on') document.getElementById("antistashApoxeteushs").checked = true;
    if (pesmenhH_Xalasmenh === 'on') document.getElementById("pesmenhH_Xalasmenh").checked = true;
    if (apwleiaFashs === 'on') document.getElementById("apwleiaFashs").checked = true;
    if (ptwshH_Ypervash === 'on') document.getElementById("ptwshH_Ypervash").checked = true;
    if (thermomagnhtikos === 'on') document.getElementById("thermomagnhtikos").checked = true;
    if (vraxykyklwmenoRele2 === 'on') document.getElementById("vraxykyklwmenoRele2").checked = true;
    if (hl_Prostasia === 'on') document.getElementById("hl_Prostasia").checked = true;
    if (piessostaths === 'on') document.getElementById("piessostaths").checked = true;
    if (piessostaths2 === 'on') document.getElementById("piessostaths2").checked = true;
    if (antliaLadiou === 'on') document.getElementById("antliaLadiou").checked = true;
    if (elleipshLadiou === 'on') document.getElementById("elleipshLadiou").checked = true;
    if (valvidaAforths === 'on') document.getElementById("valvidaAforths").checked = true;
    if (akatharisto === 'on') document.getElementById("akatharisto").checked = true;
    if (condenser === 'on') document.getElementById("condenser").checked = true;
    if (vraxykyklSymp === 'on') document.getElementById("vraxykyklSymp").checked = true;
    if (antikatastSymp === 'on') document.getElementById("antikatastSymp").checked = true;
    if (provlhmaDiktyo === 'on') document.getElementById("provlhmaDiktyo").checked = true;
    if (provlhmaPLC === 'on') document.getElementById("provlhmaPLC").checked = true;
    if (piesYpshlhs === 'on') document.getElementById("piesYpshlhs").checked = true;
    if (piesXamhlhs === 'on') document.getElementById("piesXamhlhs").checked = true;
    if (elleipshFreon3 === 'on') document.getElementById("elleipshFreon3").checked = true;
    if (elleipshLadiou3 === 'on') document.getElementById("elleipshLadiou3").checked = true;
    if (akathElaio === 'on') document.getElementById("akathElaio").checked = true;
    if (akathCondenser === 'on') document.getElementById("akathCondenser").checked = true;
    if (vraxyAnem === 'on') document.getElementById("vraxyAnem").checked = true;
    if (dysmeneis === 'on') document.getElementById("dysmeneis").checked = true;
    if (allo0 === 'on') document.getElementById("allo0").checked = true;

    const handleC = (e) => {
        if (e.target.value === 'on') {
            document.getElementById(`${e.target.id}`).unchecked = true;
            setValues({ ...values, [e.target.name]: 'off' });
        } else {
            document.getElementById(`${e.target.id}`).checked = true;
            setValues({ ...values, [e.target.name]: 'on' });
        }

    }

    return (
        <>
            <form onSubmit={handleSubmit} className="mt-3 p-3">

                <div className="row g-3 mb-5">
                    <div className="col-lg-6 m-auto d-flex flex-column align-items-center justify-content-center text-nowrap">
                        <img className="formLogo" src={carrierLogo} alt="CarrierLogo" />
                        <h2 className="formTitle">ΔΕΛΤΙΟ ΤΕΧΝΙΚΗΣ ΕΞΥΠΗΡΕΤΗΣΗΣ</h2>
                    </div>
                </div>

                {/* ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="row py-2 text-center">
                        <h3>ΣΤΟΙΧΕΙΑ ΕΝΤΟΛΗΣ</h3>
                    </div>
                    <div className="row py-2">
                        <div className="col-xl-5">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΠΕΛΑΤΗΣ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <Select placeholder="Κατάστημα" style={{ width: '100%' }} value={pelatis} onChange={handleSelectKatastima}>
                                        {stores.map(store => {
                                            return (
                                                <Option key={store.id} value={`${store.name}`}>{store.name}</Option>
                                            )
                                        })}
                                    </Select>
                                    {/* <input name="pelatis" type="text" className="form-control" placeholder="" value={pelatis} onChange={handleChange} /> */}
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΔΙΕΥΘΗΝΣΗ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="diefthinsi" type="text" className="form-control" placeholder="" value={diefthinsi} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΤΗΛ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="thl" type="text" className="form-control" placeholder="" value={thl} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΚΩΔ ΚΑΤΑΣΤΗΜΑΤΟΣ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="kwdikosKatastimatos" type="text" className="form-control" placeholder="" value={kwdikosKatastimatos} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΕΝΤΟΛΗ ΠΕΛΑΤΗ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="entoliPelati" type="text" className="form-control" placeholder="" value={entoliPelati} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΟΝΟΜΑΤΕΠΩΝΥΜΟ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="onomatepwnymo" type="text" className="form-control" placeholder="" value={onomatepwnymo} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-5">
                                    <label className="form-label">ΤΗΛΕΦΩΝΟ:</label>
                                </div>
                                <div className="col-lg-7">
                                    <input name="thlefwno" type="text" className="form-control" placeholder="" value={thlefwno} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col-lg-12">
                                    <div className="form-check form-check-inline mx-5">
                                        <input className="form-check-input" name="ypokatastima" id="ypokatastima" type="checkbox" value={ypokatastima} onChange={handleC}></input>
                                        <label className="form-check-label" >ΥΠΟΚΑΤΑΣΤΗΜΑ</label>
                                    </div>
                                    <div className="form-check form-check-inline mx-5">
                                        <input className="form-check-input" name="kentriko" id="kentriko" type="checkbox" value={kentriko} onChange={handleC}></input>
                                        <label className="form-check-label" >ΚΕΝΤΡΙΚΟ</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 my-1">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="proforiki" id="proforiki" type="checkbox" value={proforiki} onChange={handleC}></input>
                                        <label className="form-check-label" >ΠΡΟΦΟΡΙΚΗ</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="thlefwnikh" id="thlefwnikh" type="checkbox" value={thlefwnikh} onChange={handleC}></input>
                                        <label className="form-check-label" >ΤΗΛΕΦΩΝΙΚΗ</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" name="grapth" id="grapth" type="checkbox" value={grapth} onChange={handleC}></input>
                                        <label className="form-check-label" >ΓΡΑΠΤΗ</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ΑΡΙΘΜΟΣ ΓΝΩΣΤΟΠΟΙΗΣΗΣ / ΑΝΑΘΕΣΗΣ */}
                    <div className="row pt-2 pb-2 mb-5">
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Αρ. Γνωστοποίησης/Ανάθεσης:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="arGnostopoihshs" type="text" className="form-control" placeholder="" value={arGnostopoihshs} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-3">
                                    <label className="form-label">Αρ. Μητρώου:</label>
                                </div>
                                <div className="col-xl-9">
                                    <input name="arMitroou" type="text" className="form-control" placeholder="" value={arMitroou} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row align-items-center">
                                <div className="col-xl-4">
                                    <label className="form-label">Εντολή Αγοράς:</label>
                                </div>
                                <div className="col-xl-8">
                                    <input name="entoliAgoras" type="text" className="form-control" placeholder="" value={entoliAgoras} onChange={handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΩΡΑ ΚΛΗΣΗΣ:</label>
                            <TimePicker name="wraKlhshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraKlhshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΩΡΑ ΜΕΤΑΒΑΣΗΣ:</label>
                            <TimePicker name="wraMetavashs" placeholder="Επιλέξτε Ώρα" onChange={handleWraMetavashs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΩΡΑ ΑΦΙΞΗΣ:</label>
                            <TimePicker name="wraAfikshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraAfikshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΔΙΑΡΚΕΙΑ ΕΡΓΑΣΙΑΣ:</label>
                            <TimePicker name="diarkeiaErgasias" placeholder="Επιλέξτε Ώρα" onChange={handleDiarkeiaErgasias} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΩΡΑ ΑΝΑΧΩΡΗΣΗΣ:</label>
                            <TimePicker name="wraAnaxwrhshs" placeholder="Επιλέξτε Ώρα" onChange={handleWraAnaxwrhshs} format={format} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">ΩΡΑ ΕΠΙΣΤΡΟΦΗΣ:</label>
                            <TimePicker name="wraEpistrofhs" placeholder="Επιλέξτε Ώρα" onChange={handleWraEpistrofhs} format={format} />
                        </div>
                        <div className="col-xxl-2 m-auto">
                            <label className="form-label m-3">Ημ/νια:</label>
                            <DatePicker name="date" placeholder="Επιλέξτε Ημ/νία" onChange={handleDate} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Km ΜΕΤΑΒΑΣΗΣ:</label>
                            <input name="kmMetavashs" type="text" className="form-control mb-2" placeholder="Km" value={kmMetavashs} onChange={handleChange} />
                        </div>
                        <div className="col-xxl-1 m-auto">
                            <label className="form-label m-3">Km ΕΠΙΣΤΡΟΦΗΣ:</label>
                            <input name="kmEpistrofhs" type="text" className="form-control mb-2" placeholder="Km" value={kmEpistrofhs} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="row py-2">
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 1:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia1} onChange={(value) => { setValues({ ...values, ergasia1: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 2:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia2} onChange={(value) => { setValues({ ...values, ergasia2: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                        <div className="col-lg-4 my-2">
                            <label className="form-label my-2">Τεχνικός 3:</label>
                            <Select placeholder="Όνομα Τεχνικού" allowClear className="w-100" value={ergasia3} onChange={(value) => { setValues({ ...values, ergasia3: value }) }}>
                                {users
                                    .filter(user => user.role === "Technician")
                                    .map(user => {
                                        return (
                                            <Option value={user.name}>{user.name}</Option>
                                        )
                                    })}
                            </Select>
                        </div>
                    </div>


                    {/* CHECKBOXES FEATURE ONLY FOR ADMIN */}
                    {auth.role === 'Admin' &&
                        <div className="row my-2">
                            <div className="col-lg-12 text-center">
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="eggyhsh" id="eggyhsh" type="checkbox" value={eggyhsh} onChange={handleC} ></input>
                                    <label className="form-check-label" >Εγγύηση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="xrewsh" id="xrewsh" type="checkbox" value={xrewsh} onChange={handleC} ></input>
                                    <label className="form-check-label" >Χρέωση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="aneuXrewshs" id="aneuXrewshs" type="checkbox" value={aneuXrewshs} onChange={handleC} ></input>
                                    <label className="form-check-label" >Άνευ Χρέωσης</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="periodikoService" id="periodikoService" type="checkbox" value={periodikoService} onChange={handleC} ></input>
                                    <label className="form-check-label" >Περιοδικό Service</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="symvolaio" id="symvolaio" type="checkbox" value={symvolaio} onChange={handleC} ></input>
                                    <label className="form-check-label" >Συμβόλαιο</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="egkatastash" id="egkatastash" type="checkbox" value={egkatastash} onChange={handleC} ></input>
                                    <label className="form-check-label" >Εγκατάσταση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="egkPwlhsh" id="egkPwlhsh" type="checkbox" value={egkPwlhsh} onChange={handleC} ></input>
                                    <label className="form-check-label" >Εγκ./Πώληση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="anakainish" id="anakainish" type="checkbox" value={anakainish} onChange={handleC} ></input>
                                    <label className="form-check-label" >Ανακαίνιση</label>
                                </div>
                                <div className="form-check form-check-inline mx-2">
                                    <input className="form-check-input" name="loipesErgasies" id="loipesErgasies" type="checkbox" value={loipesErgasies} onChange={handleC} ></input>
                                    <label className="form-check-label" >Λοιπές Εργασίες</label>
                                </div>
                            </div>
                        </div>
                    }

                    {/* ΤΥΠΟΣ ΨΥΓΕΙΟΥ 
    <div className="row my-2 m-auto p-2 border border-1 border-dark">
        <div className="col-lg-2 text-center">
            <label className="form-check-label" >Τύπος Ψυγείου:</label>
        </div>
        <div className="col-lg-10 text-center">
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" name="zeta" ></input>
                <label className="form-check-label" >ZETA</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ARES</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ZEUS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MESIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MONAXIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ARF</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ARM</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MAXIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >AIB</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >AIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >NACRE</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >THETIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MILAS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >WISALO</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >IRIOS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >TOPAZE</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ATR</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >VANTIS</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >VELANDO</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΚΡΕΟΠ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΠΑΡΑΣΚ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΣΥΝΤ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΚΑΤΑΨ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >OPTIMER</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΦΡΟΥΤ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >Θ. ΑΝΟΙΚΤ.</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ΠΑΓΚΟΣ</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ΠΑΓΚΟΣ ΦΕΤΑ</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ΕΛΙΕΡΑ</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MULTI (-)</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MULTI (+)</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >MULTI SAT</label>
            </div>
            <div className="form-check form-check-inline mx-2">
                <input className="form-check-input" id="xxxxxx" type="checkbox" ></input>
                <label className="form-check-label" >ΛΟΙΠΑ:</label>
            </div>
        </div>
    </div>
    */}

                    {/* ΠΕΡΙΓΡΑΦΗ ΒΛΑΒΗΣ */}
                    <div className="row py-2 my-3">
                        <div className="col-xl-2 m-auto">
                            <label className="form-label">Περιγραφή Βλάβης:</label>
                        </div>
                        <div className="col-xl-10 m-auto">
                            <textarea name="perigrafiVlavis" type="text" className="form-control mb-2" placeholder="" value={perigrafiVlavis} onChange={handleChange} />
                        </div>
                    </div>
                </div>

                {/* ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5">
                    <div className="row p-0 m-auto">
                        <div className="col-md-8 border border-1 border-dark text-center">
                            <label className="form-label">ΑΝΑΛΥΣΗ ΕΡΓΑΣΙΩΝ</label>
                        </div>
                        <div className="col-md-4 border border-1 border-dark text-left">
                            <label className="form-label">Δ. Αποστολής</label>
                        </div>
                    </div>

                    {/* 10 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">10</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΕΛΛΕΙΨΗ ΦΡΕΟΝ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="astoxiaSwlhna" id="astoxiaSwlhna" type="checkbox" value={astoxiaSwlhna} onChange={handleC} ></input>
                            <label className="form-label">Αστοχία σωλήνα <br></br> ή εξαρτήματος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="xalarosSyndesmos" id="xalarosSyndesmos" type="checkbox" value={xalarosSyndesmos} onChange={handleC} ></input>
                            <label className="form-label">Χαλαρός σύνδεσμος <br></br> ή εξαρτήματα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="diatrhtosEksatmisths" id="diatrhtosEksatmisths" type="checkbox" value={diatrhtosEksatmisths} onChange={handleC} ></input>
                            <label className="form-label">Διάτρητος εξατμιστής</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="diatrhtosSymphknwths" id="diatrhtosSymphknwths" type="checkbox" value={diatrhtosSymphknwths} onChange={handleC} ></input>
                            <label className="form-label">Διάτρητος συμπυκνωτής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="astoxiaSyggolhshs" id="astoxiaSyggolhshs" type="checkbox" value={astoxiaSyggolhshs} onChange={handleC} ></input>
                            <label className="form-label">Αστοχία συγκόλλησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="kakhSterewsh" id="kakhSterewsh" type="checkbox" value={kakhSterewsh} onChange={handleC} ></input>
                            <label className="form-label">Κακή στερέωση δικτύου <br></br> ή εξαρτήματος</label>
                        </div>
                    </div>

                    {/* 20 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">20</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΠΑΓΩΜΕΝΟΣ ΕΞΑΤΜΙΣΤΗΣ ή ΕΞΑΤΜΙΣΤΗΣ ΧΩΡΙΣ ΨΥΞΗ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenhAntistash" id="vraxykyklwmenhAntistash" type="checkbox" value={vraxykyklwmenhAntistash} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένη αντίσταση</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenosAnemisthras" id="vraxykyklwmenosAnemisthras" type="checkbox" value={vraxykyklwmenosAnemisthras} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένος <br></br> ανεμιστήρας εξατμιστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vlavhH_Arruthmistos" id="vlavhH_Arruthmistos" type="checkbox" value={vlavhH_Arruthmistos} onChange={handleC} ></input>
                            <label className="form-label">Βλάβη ή αρρύθμιστος <br></br> χρονοδιακόπτης</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="kamenesAsfaleies" id="kamenesAsfaleies" type="checkbox" value={kamenesAsfaleies} onChange={handleC} ></input>
                            <label className="form-label">Καμένες ασφάλειες <br></br> αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="VraxykyklwmenoRele" id="VraxykyklwmenoRele" type="checkbox" value={VraxykyklwmenoRele} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένο <br></br> ρελέ αντιστάσεων</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermostathsXwrou" id="thermostathsXwrou" type="checkbox" value={thermostathsXwrou} onChange={handleC} ></input>
                            <label className="form-label">Θερμοστάτης χώρου <br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="hlektronikosThermostaths" id="hlektronikosThermostaths" type="checkbox" value={hlektronikosThermostaths} onChange={handleC} ></input>
                            <label className="form-label">Ηλεκτρονικός Θερμοστάτης <br></br> χώρου χαλασμένος</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="magnhtikhValvida" id="magnhtikhValvida" type="checkbox" value={magnhtikhValvida} onChange={handleC} ></input>
                            <label className="form-label">Μαγνητική βαλβίδα <br></br> μόνιμα ανοικτή ή κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="ektonwtikhValvida" id="ektonwtikhValvida" type="checkbox" value={ektonwtikhValvida} onChange={handleC} ></input>
                            <label className="form-label">Εκτονωτική βαλβίδα <br></br> αρρύθμιστη ή βλάβη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermostathsAsfaleias" id="thermostathsAsfaleias" type="checkbox" value={thermostathsAsfaleias} onChange={handleC} ></input>
                            <label className="form-label">Θερμοστάτης ασφαλείας <br></br> ή κλίξον κόβουν νωρίς</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="klixonPortas" id="klixonPortas" type="checkbox" value={klixonPortas} onChange={handleC} ></input>
                            <label className="form-label">Κλίξον πόρτας θαλάμου<br></br> χαλασμένο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="epidrashApoKlimatismo" id="epidrashApoKlimatismo" type="checkbox" value={epidrashApoKlimatismo} onChange={handleC} ></input>
                            <label className="form-label">Επίδραση απο κλιματισμό</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="epidrashApoReuma" id="epidrashApoReuma" type="checkbox" value={epidrashApoReuma} onChange={handleC} ></input>
                            <label className="form-label">Επίδραση από ρεύμα αέρα</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="apoxeteushKleisth" id="apoxeteushKleisth" type="checkbox" value={apoxeteushKleisth} onChange={handleC} ></input>
                            <label className="form-label">Αποχέτευση κλειστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antistashApoxeteushs" id="antistashApoxeteushs" type="checkbox" value={antistashApoxeteushs} onChange={handleC} ></input>
                            <label className="form-label">Αντίσταση αποχέτευσης <br></br> βραχ.</label>
                        </div>
                    </div>

                    {/* 30 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">30</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">ΣΥΜΠΙΕΣΤΗΣ ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="pesmenhH_Xalasmenh" id="pesmenhH_Xalasmenh" type="checkbox" value={pesmenhH_Xalasmenh} onChange={handleC} ></input>
                            <label className="form-label">Πεσμένη ή χαλασμένη ασφάλεια βοηθ. ή κύριου κυκλώμ.</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="apwleiaFashs" id="apwleiaFashs" type="checkbox" value={apwleiaFashs} onChange={handleC} ></input>
                            <label className="form-label">Απώλεια φάσης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="ptwshH_Ypervash" id="ptwshH_Ypervash" type="checkbox" value={ptwshH_Ypervash} onChange={handleC} ></input>
                            <label className="form-label">Πτώση ή υπέρβαση ρεύματος</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="thermomagnhtikos" id="thermomagnhtikos" type="checkbox" value={thermomagnhtikos} onChange={handleC} ></input>
                            <label className="form-label">Θερμομαγνητικός διακόπτης<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklwmenoRele2" id="vraxykyklwmenoRele2" type="checkbox" value={vraxykyklwmenoRele2} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένο ρελέ<br></br> συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="hl_Prostasia" id="hl_Prostasia" type="checkbox" value={hl_Prostasia} onChange={handleC} ></input>
                            <label className="form-label">Ηλ. προστασία (kriwan)<br></br> εκτός λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piessostaths" id="piessostaths" type="checkbox" value={piessostaths} onChange={handleC} ></input>
                            <label className="form-label">Πιεσσοστάτης υψηλής-χαμηλής<br></br> εκτός λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piessostaths2" id="piessostaths2" type="checkbox" value={piessostaths2} onChange={handleC} ></input>
                            <label className="form-label">Πιεσσοστάτης λαδιού εκτός <br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antliaLadiou" id="antliaLadiou" type="checkbox" value={antliaLadiou} onChange={handleC} ></input>
                            <label className="form-label">Αντλία λαδιού χαλασμένη</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshLadiou" id="elleipshLadiou" type="checkbox" value={elleipshLadiou} onChange={handleC} ></input>
                            <label className="form-label">Έλλειψη λαδιού στο κάρτερ<br></br> του συμπιεστή</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="valvidaAforths" id="valvidaAforths" type="checkbox" value={valvidaAforths} onChange={handleC} ></input>
                            <label className="form-label">Βαλβίδα άφορτης κλείστη</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akatharisto" id="akatharisto" type="checkbox" value={akatharisto} onChange={handleC} ></input>
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">13</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="condenser" id="condenser" type="checkbox" value={condenser} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένος ανεμιστήρας condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">14</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxykyklSymp" id="vraxykyklSymp" type="checkbox" value={vraxykyklSymp} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένος συμπιεστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">15</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="antikatastSymp" id="antikatastSymp" type="checkbox" value={antikatastSymp} onChange={handleC} ></input>
                            <label className="form-label">Αντικατάσταση Συμπιεστή</label>
                        </div>
                    </div>

                    {/* 40 */}
                    <div className="row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center bg-dark text-white">
                            <label className="form-label">40</label>
                        </div>
                        <div className="col-md-11 border border-1 border-dark bg-light">
                            <label className="form-label text_bold">MULTI ΕΚΤΟΣ ΛΕΙΤΟΥΡΓΙΑΣ</label>
                        </div>
                    </div>

                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">01</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="provlhmaDiktyo" id="provlhmaDiktyo" type="checkbox" value={provlhmaDiktyo} onChange={handleC} ></input>
                            <label className="form-label">Πρόβλημα στο δίκτυο<br></br> ηλεκτροδότησης</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">02</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="provlhmaPLC" id="provlhmaPLC" type="checkbox" value={provlhmaPLC} onChange={handleC} ></input>
                            <label className="form-label">Πρόβλημα στο PLC</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">03</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piesYpshlhs" id="piesYpshlhs" type="checkbox" value={piesYpshlhs} onChange={handleC} ></input>
                            <label className="form-label">Πιεσσοστάτης υψηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">04</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="piesXamhlhs" id="piesXamhlhs" type="checkbox" value={piesXamhlhs} onChange={handleC} ></input>
                            <label className="form-label">Πιεσσοστάτης χαμηλής εκτός<br></br> λειτουργίας</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">05</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshFreon3" id="elleipshFreon3" type="checkbox" value={elleipshFreon3} onChange={handleC} ></input>
                            <label className="form-label">Έλλειψη φρέον</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">06</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="elleipshLadiou3" id="elleipshLadiou3" type="checkbox" value={elleipshLadiou3} onChange={handleC} ></input>
                            <label className="form-label">Έλλειψη λαδιού στο κεντρικό<br></br> κάρτερ</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">07</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akathElaio" id="akathElaio" type="checkbox" value={akathElaio} onChange={handleC} ></input>
                            <label className="form-label">Ακαθάριστος<br></br> ελαιοδιαχωριστής</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">08</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="akathCondenser" id="akathCondenser" type="checkbox" value={akathCondenser} onChange={handleC} ></input>
                            <label className="form-label">Ακαθάριστο condenser</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">09</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="vraxyAnem" id="vraxyAnem" type="checkbox" value={vraxyAnem} onChange={handleC} ></input>
                            <label className="form-label">Βραχυκυκλωμένος<br></br> ανεμιστήρας</label>
                        </div>
                    </div>
                    <div className="col-md-12 row p-0 m-auto">
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">10</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="dysmeneis" id="dysmeneis" type="checkbox" value={dysmeneis} onChange={handleC} ></input>
                            <label className="form-label">Δυσμενείς καιρικές συνθήκες</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">11</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <input className="form-check-input" name="allo0" id="allo0" type="checkbox" value={allo0} onChange={handleC} ></input>
                            <label className="form-label">Άλλο</label>
                        </div>
                        <div className="col-md-1 border border-1 border-dark text-center text-dark">
                            <label className="form-label text_bold">12</label>
                        </div>
                        <div className="col-md-3 border border-1 border-dark text-left">
                            <label className="form-label"></label>
                        </div>
                    </div>


                </div>


                {/* ΠΕΡΙΓΡΑΦΗ ΕΡΓΑΣΙΩΝ - ΠΑΡΑΤΗΡΗΣΕΙΣ */}
                <div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
                    <div className="col-lg-2">
                        <label className="form-label">Περιγραφή Εργασιών - Παρατηρήσεις:</label>
                    </div>
                    <div className="col-lg-10">
                        <textarea name="perigrafiErgasion" type="text" className="form-control" placeholder="" value={perigrafiErgasion} onChange={handleChange} />
                    </div>
                </div>

                {/* ΕΙΣΠΡΑΞΗ ΜΕΤΡΗΤΑ ΣΗΜΕΡΑ ΕΥΡΩ
<div className="row formBox pt-2 pb-2 g-3 mb-5 border border-1 border-dark">
    <div className="col-lg-6">
        <label className="form-check-label" >Είσπραξη Μετρητά Σήμερα Ευρώ:</label>
    </div>
    <div className="col-lg-6">
        <div className="form-check form-check-inline mx-2">
            <label className="form-check-label" >Μετρητά</label>
            <input className="form-check-input" type="checkbox" ></input>
        </div>
        <div className="form-check form-check-inline mx-2">
            <label className="form-check-label" >Επι Πίστωσει</label>
            <input className="form-check-input" type="checkbox" ></input>
        </div>
    </div>
</div>
*/}

                <div className="row formBox my-4">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-4 text-center">
                        <label className="form-label">Status</label>
                    </div>
                    <div className="col-lg-2">
                        <Select placeholder="Select Status" style={{ width: 120 }} value={status} onChange={handleSelect}>
                            <Option value="Active">Active</Option>
                            {auth.role === 'Admin' &&
                                <Option value="Completed">Completed</Option>
                            }
                            {auth.role === 'Admin' &&
                                <Option value="Invoiced">Invoiced</Option>
                            }
                        </Select>
                    </div>
                </div>

                {/* ΥΠΟΓΡΑΦΕΣ */}
                <div className="row formBox py-2 g-3 my-5">
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΟΝ ΣΥΝΕΡΓΑΤΗ</label>
                        {ypografi1 ?
                            <img src={ypografi1} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                        <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <>
                                    <SignatureCanvas penColor='black' ref={sigCanvas}
                                        canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                    <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={() => { save1(); close(); }}>Αποθήκευση</button>
                                </>
                            )}
                        </Popup>
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΓΙΑ ΤΗΝ ΕΤΑΙΡΕΙΑ</label>
                        {ypografi2 ?
                            <img src={ypografi2} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                        <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <>
                                    <SignatureCanvas penColor='black' ref={sigCanvas}
                                        canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                    <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={() => { save2(); close(); }}>Αποθήκευση</button>
                                </>
                            )}
                        </Popup>
                    </div>
                    <div className="col-md-3 m-auto my-2 text-center d-flex flex-column align-items-center">
                        <label className="form-label mb-5">ΕΛΕΓΧΟΣ</label>
                        {ypografi3 ?
                            <img src={ypografi3} alt="Υπογραφή Συνεργάτη" className="sigImage" />
                            : <div className="sigImage"></div>
                        }
                        <Popup modal trigger={<button type="button" className="btn btn-info mt-5">Υπέγραψε</button>}
                            closeOnDocumentClick={false}
                        >
                            {close => (
                                <>
                                    <SignatureCanvas penColor='black' ref={sigCanvas}
                                        canvasProps={{ className: 'sigCanvas border border-1 border-dark' }} />
                                    <button type="button" className="btn btn-info me-2" onClick={close}>Άκυρο</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={clear}>Καθαρισμός</button>
                                    <button type="button" className="btn btn-info mx-2" onClick={() => { save3(); close(); }}>Αποθήκευση</button>
                                </>
                            )}
                        </Popup>
                    </div>
                </div>


                <div className="row my-2">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-4">
                    </div>
                    <div className="col-lg-2">
                        <button type="submit" className="btn btn-primary my-2 me-3">Save</button>
                        <button type="button" className="btn btn-secondary my-2" onClick={() => { history.push(`/readCarrierReport/${match.params.reportId}`) }}>Cancel</button>
                    </div>
                </div>

            </form>
        </>
    )
}

export default EditCarrierForm;