//Import Libraries
import { BrowserRouter, Switch} from 'react-router-dom';
//import { Route} from 'react-router-dom';

//Import Components
import Login from './auth/Login';
import Dashboard from './auth/Dashboard';
import CreateReportDelphis from './auth/CreateReportDelphis';
import CreateReportCarrier from './auth/CreateReportCarrier';
import CreateReportCarrierAB from './auth/CreateReportCarrierAB';
import CreateReportReco from './auth/CreateReportReco';
import ActiveReports from './auth/ActiveReports';
import CompletedReports from './auth/CompletedReports';
import InvoicedReports from './auth/InvoicedReports';
import Technicians from './auth/Technicians';
import CreateTechnician from './auth/CreateTechnician';
import CreateStore from './auth/CreateStore';
import Stores from './auth/Stores';
import UpdateTechnician from './auth/UpdateTechnician';
//import Settings from './auth/Settings';

//Single Reports
import SingleRecoReport from './auth/SingleRecoReport';
import SingleCarrierABReport from './auth/SingleCarrierABReport';
import SingleCarrierReport from './auth/SingleCarrierReport';
import SingleDelphisReport from './auth/SingleDelphisReport';

//Updates
import UpdateReportReco from './auth/UpdateReportReco';
import UpdateReportCarrierAB from './auth/UpdateReportCarrierAB';
import UpdateReportCarrier from './auth/UpdateReportCarrier';
import UpdateReportDelphis from './auth/UpdateReportDelphis';

//Import Private Routing
import PrivateRoute from './components/Private/PrivateRoute';
import PrivateLogin from './components/Private/PrivateLogin';
import PrivateAdmin from './components/Private/PrivateAdmin';

//Import CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'
import 'reactjs-popup/dist/index.css';

//Import JS
import 'bootstrap/dist/js/bootstrap.bundle';
import 'jquery/dist/jquery.js';
import 'popper.js/dist/popper.js';

import './App.css';


function App() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateLogin exact path="/" component={Login} />

          {/* Technician Routes */}
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/createreport/delphis" component={CreateReportDelphis} />
          <PrivateRoute exact path="/createreport/carrier" component={CreateReportCarrier} />
          <PrivateRoute exact path="/createreport/carrierab" component={CreateReportCarrierAB} />
          <PrivateRoute exact path="/createreport/reco" component={CreateReportReco} />
          <PrivateRoute exact path="/activereports" component={ActiveReports} />
          <PrivateRoute exact path="/readRecoReport/:reportId" component={SingleRecoReport} />
          <PrivateRoute exact path="/readCarrierABReport/:reportId" component={SingleCarrierABReport} />
          <PrivateRoute exact path="/readCarrierReport/:reportId" component={SingleCarrierReport} />
          <PrivateRoute exact path="/readDelphisReport/:reportId" component={SingleDelphisReport} />
          <PrivateRoute exact path="/recoReport/edit/:reportId" component={UpdateReportReco} />
          <PrivateRoute exact path="/carrierABReport/edit/:reportId" component={UpdateReportCarrierAB} />
          <PrivateRoute exact path="/carrierReport/edit/:reportId" component={UpdateReportCarrier} />
          <PrivateRoute exact path="/delphisReport/edit/:reportId" component={UpdateReportDelphis} />
          {/* <Route exact path="/settings" component={Settings} /> */}

          {/* Admin Routes */}
          <PrivateAdmin exact path="/completedreports" component={CompletedReports} />
          <PrivateAdmin exact path="/invoicedreports" component={InvoicedReports} />
          <PrivateAdmin exact path="/createstore" component={CreateStore} />
          <PrivateAdmin exact path="/stores" component={Stores} />
          <PrivateAdmin exact path="/createtechnician" component={CreateTechnician} />
          <PrivateAdmin exact path="/technician/:userId" component={UpdateTechnician} />
          <PrivateAdmin exact path="/technicians" component={Technicians} />

        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
