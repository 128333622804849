//Imports
import axios from 'axios';

//CREATE
export const createUser = async (token, user) =>
    await axios.post(`${process.env.REACT_APP_API}/register`, user, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//READ ALL
export const readUsers = async (token) =>
    await axios.get(`${process.env.REACT_APP_API}/readusers`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//READ 1
export const readUser = async (token, userId) =>
    await axios.get(`${process.env.REACT_APP_API}/readuser/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//UPDATE
export const updateUser = async (token, userId, user) =>
    await axios.post(`${process.env.REACT_APP_API}/user/edit/${userId}`, user, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });


//DELETE User
export const deleteUser = async (token, userId) =>
    await axios.delete(`${process.env.REACT_APP_API}/deleteuser/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

//Login Check
export const login = async (user) =>
    await axios.post(`${process.env.REACT_APP_API}/login`, user);